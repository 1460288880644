<template>
  <div class="Modals" v-if="modals.length > 0">
    <div v-for="(modal, index) in modals" class="Modal" :key="'modal'+index">
      <component
      v-bind:is="modal.component"
      :modal="modal"
      class="ModalComponent"
      :class="{'ModalWindowHeight': modalWindowHeight(modal)}">
      </component>
    </div>
  </div>
</template>

<script>
import ModalAccount from './ModalAccount.vue';
import ModalBlockingElements from './ModalBlockingElements.vue';
import ModalConfirm from './ModalConfirm.vue';
import ModalCopyGame from './ModalCopyGame.vue';
import ModalElemLocks from './ModalElemLocks.vue';
import ModalEventExplodeElement from './ModalEventExplodeElement.vue';
import ModalGamesBrowse from './ModalGamesBrowse.vue';
import ModalGroupEditor from './ModalGroupEditor.vue';
import ModalIconSelector from './ModalIconSelector.vue';
import ModalLevelEditor from './ModalLevelEditor.vue';
import ModalLevelResult from './ModalLevelResult.vue';
import ModalMessage from './ModalMessage.vue';
import ModalNewGame from './ModalNewGame.vue';
import ModalNewLevels from './ModalNewLevels.vue';
import ModalTextInput from './ModalTextInput.vue';
import ModalTriggerConditions from './ModalTriggerConditions.vue';
import {mapGetters} from 'vuex';

export default {
  components: {
    ModalAccount,
    ModalBlockingElements,
    ModalConfirm,
    ModalCopyGame,
    ModalElemLocks,
    ModalEventExplodeElement,
    ModalGamesBrowse,
    ModalGroupEditor,
    ModalIconSelector,
    ModalLevelEditor,
    ModalLevelResult,
    ModalMessage,
    ModalNewGame,
    ModalNewLevels,
    ModalTextInput,
    ModalTriggerConditions,
  },
  computed: {
    ...mapGetters([
      'internals',
    ]),
    modals() {
      return this.internals.modals;
    },
  },
  methods: {
    modalWindowHeight(modal) {
      if (modal.hasOwnProperty('limitHeight')) {
        return modal.limitHeight;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.Modals {
  z-index: 999;
  display: flex;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100vw;
  height:100vh;
  overflow: hidden;
}
.Modal {
  background-color: rgba(0, 0, 0, 0.6);
  overflow: auto;
  top:0;
  left:0;
  right:0;
  bottom:0;
  position: absolute;
  display: flex;
}
.ModalWindowHeight{
  height: calc(100vh - 30px);
  overflow: hidden;
}
</style>
