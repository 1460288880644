<template>
    <div>
        <v-btn
        v-if="canGenerate && info.id == 0"
        color="#45AE95"
        @click="generateClick"
        :disabled="disabled"
        >
          <v-icon left>play_arrow</v-icon> {{generateText}}</v-btn>
        <div v-else>
          <div :style="{'visibility': info.icon ? 'visible' : 'hidden'}">
            <v-chip label :color="info.color" v-if="!hasClick(info)">
            <img v-if="isGif(info.icon)" :src="'/images/'+info.icon" class="v-icon--left">
            <v-icon left v-else>{{info.icon}}</v-icon>
            {{info.descr}}</v-chip>
            <v-chip label :color="info.color" v-else @click.stop="itemClick(info)" class="has-pointer">
            <v-icon left>{{info.icon}}</v-icon>{{info.descr}}</v-chip>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    info: Object,
    buttontype: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    canGenerate: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    generateText: {
      type: String,
      default: function() {
        return 'Generate level';
      },
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
    resultLink: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      mystatus: '',
      noresultchip: true,
    };
  },
  methods: {
    isGif(icon) {
      return icon.indexOf('.gif') > -1;
    },
    hasClick(info) {
      return this.resultLink && (info.id == 3 || info.id == 5);
    },
    itemClick(info) {
      if (info.id == 3 || info.id == 5) {
        this.$emit('openResult');
      }
    },
    generateClick() {
      this.$emit('generate');
    },
  },
};
</script>

<style>
.v-chip.has-pointer .v-chip__content{
  cursor: pointer;
}
</style>
