<template>
  <v-container grid-list-md fluid>
    <v-layout>
    </v-layout>
    <v-layout v-if="loading">
      Loading...
    </v-layout>
    <v-layout row wrap v-else>
      <v-flex class="ItemCard" @click="addItem" :class="{'Locked':lockedIn}">
        <v-card class="EmptyCard">
          <v-icon dark large>add_circle</v-icon>
        </v-card>
      </v-flex>
      <v-flex
        v-for="item in foregroundCombinations"
        :key="'foregroundcombo'+item.id"
        class="ItemCard"
      >
        <v-card color="card">
          <v-card-title class="title">
            <div
            :class="{'Editable': item.locked < 2, 'Locked': item.locked >= 2 || lockedIn}"
            @click="editTitle(item)"
            >{{item.name}}</div>
          </v-card-title>
          <v-card-text>
            <div><v-select
            :items="foregroundsList"
            label="Foreground 1"
            v-model="item.fg1"
            @change="updateItem(item)"
            :disabled="editdisabled(item)"
            ></v-select></div>
            <div><v-select
            :items="foregroundsList"
            label="Foreground 2"
            v-model="item.fg2"
            @change="updateItem(item)"
            :disabled="editdisabled(item)"
            ></v-select></div>
            <div><v-select
            :items="foregroundsList"
            label="Target"
            v-model="item.fgTarget"
            @change="updateItem(item)"
            :disabled="editdisabled(item)"
            ></v-select></div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat @click="removeItem(item)" :disabled="editdisabled(item)">
              <v-icon small class="mr-2">delete</v-icon>DELETE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import bus from '../bus';
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      loading: true,
      foregroundCombinations: [],
      busEvents: [
        'saveTitle',
      ],
    };
  },
  created() {
    // Bus events shortcode FGC
    this.busEvents.forEach((element) => {
      bus.$on('FGC-'+element, eval('this.'+element));
    });
  },
  mounted() {
    this.getLibrary();
    const me = this;
    setTimeout(function() {
      me.foregroundCombinations = me.globalGetProp(me.library, 'foregroundCombinations', []);
      me.loading = false;
    }, 400);
  },
  computed: {
    ...mapGetters(['serverData']),
    library() {
      return this.serverData.getGame.gameLibrary;
    },
    foregroundsList() {
      const list = [];
      for (let i = 0; i < this.library.foregrounds.length; i++) {
        const el = this.library.foregrounds[i];
        list.push({value: el.id, text: el.name});
      }
      return list;
    },
    nextID() {
      let i = 0;
      if (this.library.hasOwnProperty('foregroundCombinations')) {
        this.library.foregroundCombinations.forEach((element) => {
          if (element.id > i) {
            i = element.id;
          }
        });
      }
      return i + 1;
    },
    lockedIn() {
      const accountLockedLib = this.serverData.getAccountInfo.accountInfo.internals.lockedGameLibrary;
      return accountLockedLib;
    },
  },
  methods: {
    ...mapActions({
      spawnModal: 'spawnModal',
      showMessage: 'showMessage',
      saveData: 'saveData',
      getLibrary: 'getLibrary',
    }),
    editdisabled(item) {
      return item.locked > 1 || this.lockedIn;
    },
    saveTitle(modal) {
      modal.subject.name = modal.value;
      this.updateItem(modal.subject);
    },
    editTitle(item) {
      this.spawnModal({
        component: 'ModalTextInput',
        title: 'Foreground combination title',
        label: 'Title',
        text: item.name,
        busevent: 'FGC-saveTitle',
        subject: item,
      });
    },
    removeItem(item) {
      this.foregroundCombinations = this.foregroundCombinations.filter((el) => el.id != item.id);
      this.library.foregroundCombinations = JSON.parse(JSON.stringify(this.foregroundCombinations));
      this.saveData({type: 'game-library'});
    },
    updateItem(item) {
      this.foregroundCombinations.forEach((el) => {
        if (el.id == item.id) {
          el = JSON.parse(JSON.stringify(item));
        }
      });
      this.library.foregroundCombinations = JSON.parse(JSON.stringify(this.foregroundCombinations));
      this.saveData({type: 'game-library'});
    },
    addItem() {
      if (this.foregroundsList.length > 0) {
        this.foregroundCombinations.unshift({
          id: this.nextID,
          name: 'Foreground combination ' + this.nextID,
          fg1: 1,
          fg2: 1,
          fgTarget: 0,
        });
        this.library.foregroundCombinations = JSON.parse(JSON.stringify(this.foregroundCombinations));
        this.saveData({type: 'game-library'});
        this.showMessage({type: 'success', text: 'New foreground combination created.'});
      } else {
        this.showMessage({
          type: 'warning',
          text: 'You have not created any foregrounds yet.',
        });
      }
    },
  },
  destroyed() {
    this.busEvents.forEach((element) => {
      bus.$off('FGC-'+element);
    });
  },
};
</script>

<style scoped>
.ItemCard {
  flex: 0 0 300px;
}
.ItemCard.Locked{
  display: none;
}
</style>
