<template>
  <v-container style="width:100%;max-width:400px">
    <v-layout align-center justify-center>
      <v-flex>
        <v-card>
          <v-toolbar dark color="secondary">
            <v-toolbar-title>
             {{modal.title}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeModal(modal.id)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-layout row>
              <v-flex pa-2 style="flex:0 0 320px;">
                <v-input
                  :messages="info.userName"
                  prepend-icon="account_circle"
                  >
                  User
                </v-input>
                <div class="pa-2"></div>
                <v-input
                  :messages="info.accountTypeName"
                  prepend-icon="grade"
                  >
                  Account type
                </v-input>
                <div class="pa-2"></div>
                <v-input
                  :messages="info.validUntil"
                  prepend-icon="calendar_today"
                  >
                  Valid until
                </v-input>
                <div class="pa-2"></div>
                <v-input
                  :messages="info.active ? 'Active' : 'Inactive'"
                  prepend-icon="verified_user"
                  :error="!info.active"
                  :success="info.active"
                  >
                  Account status
                </v-input>
                <div class="pa-2"></div>
                <v-input
                  prepend-icon="offline_bolt"
                  >
                  Generates left
                  <v-progress-linear :value="generatesValue" height="22">
                    <div class='pl-2'>{{info.generatesLeft}} of {{info.maxGenerates}}</div>
                  </v-progress-linear>
                </v-input>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  props: {
    modal: {
      type: Object,
    },
  },
  data() {
    return {
    };
  },
  created() {
    this.getAccountInfo();
  },
  computed: {
    ...mapGetters([
      'serverData',
    ]),
    info() {
      return this.serverData.getAccountInfo.accountInfo.externals;
    },
    generatesValue() {
      return Math.round((this.info.generatesLeft / this.info.maxGenerates) * 100);
    },
  },
  methods: {
    ...mapActions({
      getAccountInfo: 'getAccountInfo',
      closeModal: 'closeModal',
    }),
  },
};
</script>

<style scoped>
</style>
