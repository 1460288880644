<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex style="max-width:640px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{modal.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <div class="SpriteContainer">
              <div class="SpriteGrid">
                <div class="SpriteGridRow" v-for="r in 10" :key="'row-'+r">
                  <div
                    v-for="c in 10"
                    class="SpriteGridBlock"
                    :class="{Selected: selectedIcon===((r-1)*10)+(c-1)}"
                    :key="'row-'+r+'-col-'+c"
                  >
                    <GridItem
                      :items="[{type:modal.subject.type, icon:((r-1)*10)+(c-1)}]"
                      @click="selectIcon(((r-1)*10)+(c-1))"
                    ></GridItem>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn flat @click="closeModal(modal.id)">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="save">Select</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex';
import bus from '../bus';
import GridItem from './GridItem.vue';

export default {
  components: {
    GridItem,
  },
  props: {
    modal: {
      type: Object,
    },
  },
  data() {
    return {
      selectedIcon: 0,
    };
  },
  mounted() {
    this.selectedIcon = this.modal.subject.item.icon;
  },
  methods: {
    ...mapActions(
        {closeModal: 'closeModal'},
    ),
    selectIcon(id) {
      this.selectedIcon = id;
    },
    save() {
      bus.$emit(this.modal.busevent, {
        modalid: this.modal.id,
        value: this.selectedIcon,
        subject: this.modal.subject.item,
      });
      this.closeModal(this.modal.id);
    },
  },
};
</script>

<style scoped>
.v-card {
  width: 640px;
}
.SpriteContainer {
  width: 600px;
  height: 600px;
  position: relative;
}
.SpriteGrid {
  width: 100%;
  height: 100%;
  position: absolute;
}
.SpriteGridRow {
  display: flex;
}
.SpriteGridBlock {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.SpriteGridBlock:hover {
  background-color: var(--secondary-color);
}
.SpriteGridBlock.Selected {
  background-color: var(--primary-color);
}
.Sprite {
  width: 600px;
  height: 600px;
  background-position: 0 -60px;
  position: absolute;
  pointer-events: none;
  filter: grayscale(1) brightness(0) invert(1);
}
.Sprite.BackgroundType {
  background-position: -600px -60px;
}
</style>
