<template>
  <v-container grid-list-md fluid>
    <v-layout v-if="loading">
      Loading...
    </v-layout>
    <v-layout row wrap v-else>
      <div style="width:100%"><b>Gameplay score</b></div>
      <v-flex v-for="(item, index) in gamePlayItemsList"
        :key="'gamePlayScore'+index"
        class="ItemCard">
        <v-card color="card">
          <v-card-title>
            {{item.name}}
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Score"
              :placeholder="placeHolder(item)"
              mask="#####"
              type="number"
              :min="item.min"
              :max="item.max"
              color="white"
              v-model.number="item.score"
              @change="updateItem"
              :disabled="lockedIn"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-flex>
      <div style="width:100%" class="pt-4"><b>Library item score</b></div>
      <v-flex v-for="(item, index) in libraryItemsList"
        :key="'libraryItemScore'+index"
        class="ItemCard">
        <v-card color="card">
          <v-card-title>
            {{item.name}}
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Score"
              :placeholder="placeHolder(item)"
              mask="#####"
              type="number"
              color="white"
              v-model.number="item.score"
              :min="item.min"
              :max="item.max"
              @change="updateItem"
              :disabled="lockedIn"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-flex>
      <div style="width:100%" class="pt-4"><b>Match type score</b></div>
      <v-flex v-for="(item, index) in matchTypesList"
        :key="'matchTypeScore'+index"
        class="ItemCard">
        <v-card color="card">
          <v-card-title>
            {{item.name}}
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Score"
              placeholder="1-1000"
              mask="####"
              type="text"
              min="1"
              max="1000"
              color="white"
              v-model.number="item.score"
              @change="updateItem"
              :disabled="lockedIn"
            ></v-text-field>
            <div class="Shape">
              <div
                class="Block"
                v-for="(block, index) in item.setup.split('')"
                :key="'score-block'+index"
              >
                <div class="Fill" v-if="block == 1"></div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {mapActions} from 'vuex';

export default {
  data() {
    return {
      loading: true,
      matchTypesList: [],
      libraryItemsList: [],
      gamePlayItemsList: [],
      items: [
        {
          id: 0,
          name: 'T 5x3',
          setup: '1111100100001000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 1,
          name: 'T 5x2',
          setup: '1111100100000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 2,
          name: 'Horizontal 5',
          setup: '1111100000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 3,
          name: 'Vertical 5',
          setup: '1000010000100001000010000',
          active: 0,
          reward: 0,
        },
        {
          id: 4,
          name: 'L 3x3',
          setup: '1110010000100000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 5,
          name: 'T 3x3',
          setup: '0111000100001000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 6,
          name: 'Horizontal 4',
          setup: '1111000000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 7,
          name: 'Vertical 4',
          setup: '1000010000100001000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 8,
          name: 'Parallel 4x2',
          setup: '1111011000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 9,
          name: 'Parallel 3x2',
          setup: '1110011000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 10,
          name: 'Parallel 2x2',
          setup: '1100011000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 11,
          name: 'Horizontal 3',
          setup: '1110000000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 12,
          name: 'Vertical 3',
          setup: '1000010000100000000000000',
          active: 0,
          reward: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'serverData',
    ]),
    lockedIn() {
      const accountLockedLib = this.serverData.getAccountInfo.accountInfo.internals.lockedGameLibrary;
      return accountLockedLib;
    },
    library() {
      return this.serverData.getGame.gameLibrary;
    },
  },
  mounted() {
    this.getLibrary();
    const me = this;
    setTimeout(function() {
      me.populate();
    }, 400);
  },
  methods: {
    ...mapActions({
      saveData: 'saveData',
      getLibrary: 'getLibrary',
    }),
    populate() {
      // game play score
      this.gamePlayItemsList.push({
        name: 'Cascade multiplier',
        property: 'cascadeMultiplier',
        score: this.library.score.cascadeMultiplier,
        min: 1,
        max: 5});
      this.gamePlayItemsList.push({
        name: 'Drop',
        property: 'dropScore',
        score: this.library.score.dropScore,
        min: 0,
        max: 10000});
      this.gamePlayItemsList.push({
        name: 'Excess move',
        property: 'excessMoveScore',
        score: this.library.score.excessMoveScore,
        min: 0,
        max: 10000});
      // library item score
      this.libraryItemsList.push({
        name: 'Has timer',
        property: 'exploderScore',
        score: this.library.score.exploderScore,
        min: 0,
        max: 10000});
      this.libraryItemsList.push({
        name: 'Switcher',
        property: 'switcherScore',
        score: this.library.score.switcherScore,
        min: 0,
        max: 10000});
      this.libraryItemsList.push({
        name: 'Foreground',
        property: 'foregroundScore',
        score: this.library.score.foregroundScore,
        min: 0,
        max: 1000});
      this.libraryItemsList.push({
        name: 'Foreground in trigger',
        property: 'foregroundInTriggerScore',
        score: this.library.score.foregroundInTriggerScore,
        min: 0,
        max: 1000});
      this.libraryItemsList.push({
        name: 'Background',
        property: 'backgroundScore',
        score: this.library.score.backgroundScore,
        min: 0,
        max: 1000});
      this.libraryItemsList.push({
        name: 'Background in trigger',
        property: 'backgroundInTriggerScore',
        score: this.library.score.backgroundInTriggerScore,
        min: 0,
        max: 1000});
      // match type score
      for (let i = 0; i < this.library.matchTypes.length; i++) {
        const matchTypeIndex = this.library.matchTypes[i].fixedMatchTypeIndex;
        const el = this.items.find((el) => el.id == matchTypeIndex);
        el.score = this.library.score.matchTypeScore[matchTypeIndex];
        this.matchTypesList.push(el);
      };
      this.loading = false;
    },
    placeHolder(item) {
      return item.min + '-' + item.max;
    },
    updateItem() {
      // gameplay items data
      this.gamePlayItemsList.forEach((item) => {
        item.score = Math.min(item.max, Math.max(item.min, item.score));
        this.library.score[item.property] = item.score;
      });
      // library items data
      this.libraryItemsList.forEach((item) => {
        item.score = Math.min(item.max, Math.max(item.min, item.score));
        this.library.score[item.property] = item.score;
      });
      // get matchtypes data
      this.library.score.matchTypeScore = [];
      this.items.forEach((item) => {
        const mType = this.matchTypesList.find((mtype) => item.id == mtype.id);
        let score = 0;
        if (mType) {
          mType.score = Math.min(1000, Math.max(0, mType.score));
          score = mType.score;
        }
        this.library.score.matchTypeScore.push(score);
      });
      this.saveData({type: 'game-library'});
    },
  },
};
</script>

<style scoped>
.Shape {
  line-height: 0;
  width: 175px;
}
.Block {
  border: dotted 1px rgba(0, 0, 0, 0.2);
  width: 35px;
  height: 35px;
  display: inline-block;
  border-right: none;
  border-bottom: none;
}
.Block:nth-child(5n) {
  border-right: dotted 1px rgba(0, 0, 0, 0.2);
}
.Block:nth-child(n + 20) {
  border-bottom: dotted 1px rgba(0, 0, 0, 0.2);
}
.Block .Fill {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
}
.ItemCard {
  flex: 0 0 232px;
}
</style>
