import Vue from 'vue';
import VueX from 'vuex';
import axios from 'axios';
import router from './router';
import Cookies from 'js-cookie';

Vue.use(VueX);

export default new VueX.Store({
  state: {
    serverData: {
      getAccountInfo: null,
      getAuthToken: {authenticationToken: null},
      getFailedMessage: null,
      getGame: null,
      getGamesSummary: null,
      getGenerateStatus: {generateSummary: []},
      getGroupsSummary: null,
      getLevel: null,
      getResult: null,
      getResultData: null,
    },
    constants: {
      colorSwitch: [
        {value: 0, text: 'No color switch'},
        {value: 1, text: 'Fixed color order'},
        {value: 2, text: 'Random color order per game'},
        {value: 3, text: 'Random color order per tile'},
      ],
      consequences: ['win game', 'lose game'],
      conditiontypes: [
        'blowup foreground',
        'blowup background',
        'clear droppers',
        'turns',
        'score',
        'lay carpet',
      ],
      endGameExplosionsList: [
        {value: 0, text: 'explode once'},
        {value: 1, text: 'no spawns'},
        {value: 2, text: 'increase color'},
      ],
      explodeMethods: [
        {value: 0, text: 'Cannot explode'},
        {value: 1, text: 'Explodes if hit'},
        {value: 2, text: 'Explodes if adjacent'},
      ],
      explosions: [
        {value: 0, text: 'Self only'},
        {value: 1, text: 'Vertical line'},
        {value: 2, text: 'Horizontal line'},
        {value: 3, text: '3 Horizontal lines'},
        {value: 4, text: '3 Vertical lines'},
        {value: 5, text: '3x3'},
        {value: 6, text: '5x5'},
        {value: 21, text: '7x7'},
        {value: 7, text: 'Fish'},
        {value: 8, text: 'All tiles once'},
        {value: 9, text: 'Empty all tiles'},
        {value: 10, text: 'All of 1 color'},
        {value: 11, text: 'Change color'},
        {value: 12, text: 'Change type'},
        {value: 13, text: 'All tiles become 1 color'},
        {value: 14, text: 'Radius 1'},
        {value: 15, text: 'Radius 2'},
        {value: 16, text: 'Radius 3'},
        {value: 17, text: 'Radius 4'},
        {value: 18, text: 'Radius 5'},
        {value: 19, text: 'Circle 1'},
        {value: 20, text: 'Circle 2'},
      ],
      fgColors: ['none', 'red', 'blue', 'gold', 'green', 'purple', 'lightblue', 'orange', 'pink', 'brown'],
      generateStates: [
        {id: -1, icon: 'lock', color: 'primary',
          descr: 'upgrade your account to allow the 3 AI engines to generate more balanced custom levels.'},
        {id: 0, icon: '', color: '', descr: 'no result yet'},
        {id: 1, icon: 'schedule', color: '#FFB427', descr: 'scheduled'},
        {id: 2, icon: 'icon-generating.gif', color: 'orange', descr: 'generating'},
        {id: 3, icon: 'check_circle', color: 'cyan', descr: 'result available'},
        {id: 4, icon: 'warning', color: 'red', descr: 'generate failed'},
        {id: 5, icon: 'new_releases', color: 'green', descr: 'new result available'},
      ],
      hitTypes: [
        {value: 0, text: 'direct'},
        {value: 1, text: 'collateral'},
        {value: 2, text: 'adjacent match'},
        {value: 3, text: 'adjacent non match'},
        {value: 4, text: 'stop explosion'},
        {value: 5, text: 'by event'},
        {value: 6, text: 'adjacent indirect'},
      ],
      hitTypeColors: [
        {value: 0, text: 'all'},
        {value: 1, text: 'first random'},
        {value: 2, text: 'first color or colorless'},
      ],
      matchExplosionsList: [
        {value: 0, text: 'only matches'},
        {value: 1, text: 'include overlapping minimum matches'},
        {value: 2, text: 'include all adjacent'},
      ],
      matchTypes: [
        {
          id: 0,
          name: 'T 5x3',
          setup: '1111100100001000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 1,
          name: 'T 5x2',
          setup: '1111100100000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 2,
          name: 'Horizontal 5',
          setup: '1111100000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 3,
          name: 'Vertical 5',
          setup: '1000010000100001000010000',
          active: 0,
          reward: 0,
        },
        {
          id: 4,
          name: 'L 3x3',
          setup: '1110010000100000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 5,
          name: 'T 3x3',
          setup: '0111000100001000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 6,
          name: 'Horizontal 4',
          setup: '1111000000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 7,
          name: 'Vertical 4',
          setup: '1000010000100001000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 8,
          name: 'Parallel 4x2',
          setup: '1111011000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 9,
          name: 'Parallel 3x2',
          setup: '1110011000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 10,
          name: 'Parallel 2x2',
          setup: '1100011000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 11,
          name: 'Horizontal 3',
          setup: '1110000000000000000000000',
          active: 0,
          reward: 0,
        },
        {
          id: 12,
          name: 'Vertical 3',
          setup: '1000010000100000000000000',
          active: 0,
          reward: 0,
        },
      ],
      onEventItems: [
        {value: 0, text: 'Explosion'},
        {value: 1, text: 'Removal'},
        {value: 2, text: 'Spawn'},
      ],
      sidewaysGravityList: [
        {value: 0, text: 'random'},
        {value: 1, text: '1st random'},
        {value: 2, text: 'always left'},
        {value: 3, text: 'always right'},
      ],
      starsBasedOnList: [
        {value: 0, string: 'no stars'},
        {value: 1, string: 'score'},
      ],
      triggerReplace: [
        {value: 0, text: 'No trigger'},
        {value: 1, text: 'Simultaneous'},
        {value: 2, text: 'Consecutively'},
      ],
    },
    internals: {
      JSONqueueId: 0,
      JSONqueue: [],
      console: [],
      consoleCommand: '_',
      consoleCommandHistory: [],
      consoleHistoryIndex: -1,
      currentGameId: null,
      currentGroupId: 0,
      clipBoard: {
        levelData: null,
        groupData: null,
      },
      intervals: [],
      loader: false,
      modals: [],
      snackbar: {
        show: false,
        color: '',
        text: '',
      },
      levelFilters: {
        generates: [],
        groups: [],
      },
      levelImages: [],
      userIntent: '',
      selectedItems: [],
      copyFromGroupId: null,
      statusDialog: {
        message: '',
        cancel: '',
        ok: '',
        okText: '',
        okDisabled: true,
      },
    },
  },
  actions: {
    // network
    postDataPromise(context, data) {
      return new Promise((resolve, reject) => {
        // add authtoken
        if (data.command !== 'getAuthToken') {
          data.authToken = context.state.serverData.getAuthToken.authenticationToken;
        }
        // do not send game id for these commands
        const noGameId = [
          'createGame',
          'getAccountInfo',
          'getAuthToken',
          'getGamesSummary',
          'logout',
          'removeGame',
        ];
        // add game id
        if (noGameId.indexOf(data.command) == -1) {
          data.gameId = context.state.internals.currentGameId;
        }
        // set environment
        let env = 'test';
        if (location.hostname.indexOf('alpha') === 0) {
          env = 'alpha';
        }
        const JSONid = 'JSON'+context.state.internals.JSONqueueId++;
        context.state.internals.JSONqueue.push({id: JSONid, json: JSON.stringify(data)});
        const interfaceURL = 'http://'+env+'.pyzomath.com:8080/pyzomath-m3m-servlet/servlet';
        axios
            .post(interfaceURL, data)
            .then(function(response) {
              // handle server exceptions
              if (response.data.hasOwnProperty('exception')) {
                const givenCommand = response.data.exception.givenCommand;
                // mute getResult exceptions
                const muteException = ['getResult'].indexOf(givenCommand) >= 0;
                if (!muteException) {
                  console.warn('SERVER EXCEPTION->', response.data.exception.exceptionMessage, data);
                  context.dispatch('showMessage', {
                    type: 'error',
                    text: response.data.exception.exceptionMessage,
                  });
                  reject(response);
                }
                // kick out user if suddenly unauthorized
                if (givenCommand != 'getAuthToken' ) {
                  if (response.data.exception.exceptionType === 'unauthorizedAccess') {
                    context.dispatch('closeAllModals');
                    context.dispatch('clearUser');
                  }
                }
              } else {
                context.state.internals.JSONqueue = context.state.internals.JSONqueue.filter((el) => el.id != JSONid);
                resolve(response);
              }
            })
            .catch(function(response) {
              console.warn('CATCH->', data, response);
              context.dispatch('showMessage', {
                type: 'error',
                text: response,
              });
              reject(response);
            });
      });
    },
    saveData(context, data) {
      const type = data.type;
      if (type === 'game-settings') {
        if (!context.state.serverData.getAccountInfo.accountInfo.internals.lockedGameSettings) {
          context.dispatch('postDataPromise', {
            command: 'setGameSettings',
            gameSettings: context.state.serverData.getGame.gameSettings,
          });
        }
      }
      if (!context.state.serverData.getAccountInfo.accountInfo.internals.lockedGameLibrary) {
        if (type === 'game-library') {
          context.dispatch('postDataPromise', {
            command: 'setGameLibrary',
            gameLibrary: context.state.serverData.getGame.gameLibrary,
          });
        }
      }
    },
    pollUpdates(context, params) {
      const intervals = {
        'idle': 60000,
        'active': 5000,
      };
      const newInterval = intervals[params.interval];
      const existing = context.state.internals.intervals.find((el) => el.command == params.command);
      if (existing) {
        if (existing.interval != newInterval) {
          clearInterval(existing.id);
        } else {
          return;
        }
      }
      const entry = {command: params.command, interval: newInterval};
      const id = setInterval(function() {
        context.dispatch(params.command);
      }, entry.interval);
      entry.id = id;
      entry.emptycount = 0;
      if (existing) {
        existing.id = id;
        existing.interval = entry.interval;
        existing.emptycount = 0;
      } else {
        context.state.internals.intervals.push(entry);
      }
    },
    consoleOutput(context, params) {
      const pad = function(num) {
        num = num.toString();
        while (num.length < 2) num = '0' + num;
        return num;
      };
      const now = new Date();
      const currentTime = pad(now.getHours()) + ':' + pad(now.getMinutes()) + ':' + pad(now.getSeconds());
      context.state.internals.console.push(currentTime+' '+params);
      document.getElementById('console').scrollTop = document.getElementById('console').scrollHeight + 50;
    },
    // serverData getters
    editOriginal(context, params) {
      params.command = 'editOriginal';
      params.groupId = context.state.internals.currentGroupId;
      return context.dispatch('postDataPromise', params).then(function(response) {
        context.state.serverData.getLevel = response.data;
      });
    },
    editResult(context, params) {
      params.command = 'editResult';
      params.groupId = context.state.internals.currentGroupId;
      return context.dispatch('postDataPromise', params).then(function(response) {
        context.state.serverData.getLevel = response.data;
      });
    },
    getAuthToken(context, params) {
      params.command = 'getAuthToken';
      return context.dispatch('postDataPromise', params).then(function(response) {
        context.state.serverData.getAuthToken = response.data;
      });
    },
    getAccountInfo(context) {
      return context.dispatch('postDataPromise', {
        command: 'getAccountInfo',
      }).then(function(response) {
        context.state.serverData.getAccountInfo = response.data;
      });
    },
    getFailedMessage(context, params) {
      params.command = 'getFailedMessage';
      params.groupId = context.state.internals.currentGroupId;
      return context.dispatch('postDataPromise', params).then(function(response) {
        context.state.serverData.getFailedMessage = response.data;
      });
    },
    getGamesSummary(context) {
      return context.dispatch('postDataPromise', {
        command: 'getGamesSummary',
      }).then(function(response) {
        context.state.serverData.getGamesSummary = response.data;
      });
    },
    getGameSettings(context) {
      return context.dispatch('postDataPromise', {
        command: 'getGameSettings',
      }).then(function(response) {
        context.state.serverData.getGame.gameSettings = response.data.gameSettings;
      });
    },
    getLibrary(context) {
      return context.dispatch('postDataPromise', {
        command: 'getGameLibrary',
      }).then(function(response) {
        context.state.serverData.getGame.gameLibrary = response.data.gameLibrary;
      });
    },
    getGenerateStatus(context, keepwatch) {
      if (context.state.internals.currentGameId != null) {
        return context.dispatch('postDataPromise', {
          command: 'getGenerateStatus',
        }).then(function(response) {
          context.state.serverData.getGenerateStatus = response.data;
          const existing = context.state.internals.intervals.find((el) => el.command == 'getGenerateStatus');
          if (response.data.generateSummary.filter((el) => el == 1 || el == 2).length > 0 || keepwatch) {
            context.dispatch('pollUpdates', {command: 'getGenerateStatus', interval: 'active'});
          } else {
            if (existing) {
              existing.emptycount += 1;
              if (existing.emptycount == 3) {
                context.dispatch('pollUpdates', {command: 'getGenerateStatus', interval: 'idle'});
              }
            } else {
              context.dispatch('pollUpdates', {command: 'getGenerateStatus', interval: 'idle'});
            }
          }
        });
      }
    },
    getGroupsSummary(context) {
      return context.dispatch('postDataPromise', {
        command: 'getGroupsSummary',
      }).then(function(response) {
        context.state.serverData.getGroupsSummary = response.data;
      });
    },
    getGroup(context, params) {
      params.command = 'getGroup';
      return context.dispatch('postDataPromise', params).then(function(response) {
        if (!response.data.levelSettings.hasOwnProperty('minBlockingElements')) {
          response.data.levelSettings.minBlockingElements = 0;
          response.data.levelSettings.maxBlockingElements = 4;
          response.data.levelSettings.blockingElements = [];
        }
        context.state.serverData.getGroup = response.data;
      });
    },
    getLevel(context, params) {
      params.command = 'getLevel';
      params.groupId = context.state.internals.currentGroupId;
      return context.dispatch('postDataPromise', params).then(function(response) {
        context.state.serverData.getLevel = response.data;
      });
    },
    getResult(context, params) {
      params.command = 'getResult';
      params.groupId = context.state.internals.currentGroupId;
      return context.dispatch('postDataPromise', params).then(function(response) {
        context.state.serverData.getResult = response.data;
      });
    },
    getResultData(context, params) {
      params.command = 'getResultData';
      params.groupId = context.state.internals.currentGroupId;
      return context.dispatch('postDataPromise', params).then(function(response) {
        context.state.serverData.getResultData = response.data;
      });
    },
    // games
    clearCurrentGame(context) {
      context.state.internals.currentGameId = null;
      context.state.serverData.getGame = null;
    },
    // user
    logout(context) {
      const me = context;
      context.dispatch('postDataPromise', {
        command: 'logout',
      }).then(function() {
        me.dispatch('clearUser');
      });
    },
    clearUser(context) {
      context.dispatch('clearCurrentGame');
      context.state.serverData.getAuthToken.authenticationToken = '';
      Cookies.remove('authenticationToken');
      router.push({
        path: '/Login',
      });
      context.dispatch('showMessage', {
        type: 'success',
        text: 'You have been logged out',
      });
      context.dispatch('closeAllModals');
    },
    // generate
    generateLevel(context, obj) {
      const me = context;
      context.dispatch('postDataPromise', {
        command: 'generate',
        gameId: context.state.internals.currentGameId,
        groupId: context.state.internals.currentGroupId,
        levelId: obj.levelId,
      }).then(function(response) {
        if (response.status === 'OK') {
          me.dispatch('showMessage', {
            type: 'success',
            text: 'Added to queue',
          });
        }
      });
    },
    generateForwardTest(context, obj) {
      const me = context;
      context.dispatch('postDataPromise', {
        command: 'generate',
        forwardTest: true,
        gameId: context.state.internals.currentGameId,
        groupId: context.state.internals.currentGroupId,
        levelId: obj.levelId,
      }).then(function(response) {
        if (response.status === 'OK') {
          me.dispatch('showMessage', {
            type: 'success',
            text: 'Added to queue',
          });
        }
      });
    },
    // modals
    spawnModal(context, obj) {
      obj.id = 'modal-' +Math.random().toString(36).substr(2, 16);
      context.state.internals.modals.push(obj);
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    },
    closeModal(context, id) {
      const indexById = context.state.internals.modals.findIndex((elm) => {
        return elm.id == id;
      });
      const indexByComponent = context.state.internals.modals.findIndex((elm) => {
        return elm.component == id;
      });
      const index = Math.max(indexById, indexByComponent);
      if (index > -1) {
        context.state.internals.modals.splice(index, 1);
        if (context.state.internals.modals.length == 0) {
          document.getElementsByTagName('html')[0].style.overflow = 'auto';
        }
      }
    },
    closeAllModals(context) {
      context.state.internals.modals = [];
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    },
    showMessage(context, obj) {
      context.state.internals.snackbar = {
        show: true,
        color: obj.type,
        text: obj.text,
      };
    },
    showLoader(context) {
      context.state.internals.loader = true;
    },
    hideLoader(context) {
      context.state.internals.loader = false;
    },
    cancelStatusDialog(context) {
      context.state.internals.userIntent = '';
      context.state.internals.statusDialog.message = '';
      context.state.internals.statusDialog.ok = '';
      context.state.internals.statusDialog.okText = '';
      context.state.internals.statusDialog.okDisabled = true;
      context.state.internals.selectedItems = [];
    },
  },
  getters: {
    getSiteVersion() {
      return 'version: '+process.env.VUE_APP_VERSION+' ('+process.env.VUE_APP_DEPLOYMENT_ENV+')';
    },
    user(state) {
      return state.user;
    },
    serverData(state) {
      return state.serverData;
    },
    network(state) {
      return state.network;
    },
    constants(state) {
      return state.constants;
    },
    internals(state) {
      return state.internals;
    },
  },
});
