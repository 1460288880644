<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-card>
        <v-toolbar dark color="warn">
          <v-toolbar-title>Add levels</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pa-4">
         {{modal.prompt}}<br>
          <br>
          <v-text-field
            v-model="amount"
            color="white"
            :min="modal.minlevels"
            :max="modal.maxlevels"
            :mask="mask"
            :hint="'you can add a maximum of '+modal.maxlevels"
            persistent-hint
            type="number"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn flat @click="close">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="add" color="warn" :disabled="disabled">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import bus from '../bus';
import {mapActions} from 'vuex';

export default {
  props: {
    modal: {
      type: Object,
      minlevels: Number,
      maxlevels: Number,
      afterId: Number,
      prompt: String,
    },
  },
  data() {
    return {
      amount: this.modal.minlevels,
    };
  },
  computed: {
    mask() {
      let s = '';
      while (s.length < String(this.modal.maxlevels).length) {
        s += '#';
      }
      return s;
    },
    disabled() {
      return this.amount > this.modal.maxlevels;
    },
  },
  methods: {
    ...mapActions({
      closeModal: 'closeModal',
    }),
    close() {
      this.closeModal(this.modal.id);
    },
    add() {
      if (this.amount <= this.modal.maxlevels) {
        bus.$emit(this.modal.busevent, {'amount': Number(this.amount), 'afterId': this.modal.afterId});
        this.close();
      }
    },
  },
};
</script>
