<template>
  <v-flex>
    <v-card color="card">
      <v-card-title class="title">
        <div
          :class="{
            Editable: item.locked < 2,
            Locked: item.locked >= 2 || lockedIn,
          }"
          @click="editTitle"
        >
          {{ item.name }}
        </div>
      </v-card-title>
      <v-card-text>
        <div
          class="IconHolder"
          :class="{ Locked: item.locked >= 2 || lockedIn }"
          @click="browseIcon"
        >
          <GridItem :items="gridItemsArray" />
        </div>
        <div>
          <v-switch
            label="Regrow target "
            v-model="item.regrowTarget"
            @change="updateItem"
            :disabled="editDisabledRegrowTarget"
          ></v-switch>
        </div>
        <div>
          <v-switch
            label="Has color"
            v-model="item.hasColor"
            @change="updateItem"
            :disabled="editDisabled"
          ></v-switch>
        </div>
        <div>
          <v-switch
            label="Swappable"
            v-model="item.swappable"
            @change="updateItem"
            :disabled="editDisabled"
          ></v-switch>
        </div>
        <div>
          <v-switch
            label="Tappable"
            v-model="item.tappable"
            @change="updateItem"
            :disabled="editDisabled"
          ></v-switch>
        </div>
        <div>
          <v-switch
            label="Trigger explosion"
            v-model="item.triggerExplodes"
            @change="updateItem"
            :disabled="editDisabled"
          ></v-switch>
        </div>
        <div>
          <v-switch
            label="Allow endgame turns morph"
            v-model="item.allowEndGameTurnsMorph"
            @change="updateItem"
            :disabled="editDisabled"
          ></v-switch>
        </div>
        <div>
          <v-switch
            label="Has timer"
            v-model="item.hasTimer"
            @change="updateItem"
            :disabled="editDisabled"
          ></v-switch>
        </div>
        <v-combobox
            v-model="hitType"
            :items="constants.hitTypes"
            label="Hit type"
            multiple
            chips
            @change="updateItem"
            :disabled="editDisabled"
        ></v-combobox>
        <div>
          <v-select
            :items="constants.colorSwitch"
            item-text="text"
            item-value="value"
            label="Color switch"
            v-model="item.colorSwitch"
            @change="updateItem"
            :disabled="editDisabled || !item.hasColor"
          ></v-select>
        </div>
        <div>
          <v-select
            :items="morphIntoExplosion"
            item-text="text"
            item-value="value"
            label="Morph into when struck by same"
            v-model="item.morphIntoIdWhenStruckBySame"
            @change="updateItem"
            :disabled="editDisabled"
          ></v-select>
        </div>
        <div>
          <v-select
            :items="triggerreplace"
            item-text="text"
            item-value="value"
            label="Trigger replace"
            v-model="item.triggerReplaceUnit"
            @change="updateItem"
            :disabled="editDisabled || item.indestructible"
          ></v-select>
        </div>
        <div>
          <v-select
            :items="replaceForegroundWith"
            item-text="text"
            item-value="value"
            label="Replace with"
            v-model="item.replaceId"
            @change="updateItem"
            :disabled="item.locked > 1 || item.indestructible || lockedIn"
          ></v-select>
        </div>
        <div>
          <v-select
            :items="explosions"
            item-text="text"
            item-value="value"
            label="Explosion"
            v-model="item.explosion"
            @change="updateItem"
            :disabled="editDisabled"
          ></v-select>
        </div>
        <div>
          <v-switch
          label="Use event trigger"
          v-model="hasEventTrigger"
          @change="toggleEventTrigger"
          :disabled="editDisabled">
          </v-switch>
          <div v-if="hasEventTrigger">
            <v-select
              :items="constants.onEventItems"
              item-text="text"
              item-value="value"
              label="On"
              v-model="item.eventTrigger.onEvent"
              @change="updateItem"
              :disabled="editDisabled"
            ></v-select>
            Explode:<br>
            <v-btn block flat @click="editOnEvenExplode">{{onEventExplodeElementName}}</v-btn>
            <v-switch
            label="Respect Indestructible"
            v-model="item.eventTrigger.respectIndestructible"
            @change="updateItem"
            :disabled="editDisabled"
            ></v-switch>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          flat
          @click="showElemLocks"
          v-if="item.elemLocks.length > 0"
          icon
        >
          <v-icon small color="accent">warning</v-icon>
        </v-btn>
        <v-btn
          flat
          @click="askRemoveForeground"
          :disabled="deleteDisabled"
        >
          <v-icon small class="mr-2">delete</v-icon>DELETE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import GridItem from './GridItem.vue';
import bus from '../bus';

export default {
  props: {
    itemParams: Object,
  },
  components: {
    GridItem,
  },
  data() {
    return {
      item: this.globalJSONCopy(this.itemParams),
      hasEventTrigger: false,
      onEventExplodeElementName: 'SELECT',
      hitType: [],
    };
  },
  created() {
    if (this.item.hasOwnProperty('eventTrigger')) {
      this.hasEventTrigger = true;
      this.onEventExplodeElementName = this.elementName(this.item.eventTrigger.element);
    }
    if (this.item.hasOwnProperty('hitType')) {
      for (let i=0; i<this.item.hitType.length; i++) {
        if (this.item.hitType[i] == 1) {
          this.hitType.push(this.constants.hitTypes[i]);
        }
      }
    }
  },
  computed: {
    ...mapGetters(['serverData', 'constants']),
    triggerreplace() {
      return this.constants.triggerReplace;
    },
    morphIntoExplosion() {
      const res = this.globalJSONCopy(this.constants.explosions);
      res.unshift({value: -1, text: 'Same as Explosion'});
      return res;
    },
    explosions() {
      return this.constants.explosions;
    },
    library() {
      return this.serverData.getGame.gameLibrary;
    },
    replaceForegroundWith() {
      const list = [];
      if (this.library) {
        for (let i = 0; i < this.library.foregrounds.length; i++) {
          const el = this.library.foregrounds[i];
          list.push({
            value: el.id,
            text: el.name,
          });
        }
      }
      return list;
    },
    lockedIn() {
      return this.serverData.getAccountInfo.accountInfo.internals.lockedGameLibrary;
    },
    editDisabled() {
      return this.item.locked > 1 || this.lockedIn;
    },
    editDisabledRegrowTarget() {
      return this.lockedIn;
    },
    gridItemsArray() {
      return [{icon: this.item.icon, type: 'foregound', classes: 'LibraryItem'}];
    },
    hitTypeArray() {
      const res = [];
      for (let i=0; i<this.constants.hitTypes.length; i++) {
        const found = this.hitType.find((el) => el.value == this.constants.hitTypes[i].value);
        res.push(found ? 1: 0);
      }
      return res;
    },
    deleteDisabled() {
      if (this.lockedIn) {
        return true;
      }
      if (this.item.locked > 0) {
        return true;
      }
      // check if used by other foregrounds
      for (let i = 0; i < this.library.foregrounds.length; i++) {
        const el = this.library.foregrounds[i];
        if (el.replaceId === this.item.id) {
          return true;
        }
      }
      // check foreground combinations
      const fgc = this.globalGetProp(this.library, 'foregroundCombinations', []);
      for (let i = 0; i < fgc.length; i++) {
        const el = this.library.foregroundCombinations[i];
        if (
          el.fg1 === this.item.id ||
          el.fg2 === this.item.id ||
          el.fgTarget === this.item.id
        ) {
          return true;
        }
      }
      // check matchtypes
      for (let i = 0; i < this.library.matchTypes.length; i++) {
        const el = this.library.matchTypes[i];
        if (el.reward === this.item.id) {
          return true;
        }
      }
      // check elemLocks
      if (this.item.elemLocks.length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      spawnModal: 'spawnModal',
      showMessage: 'showMessage',
    }),
    browseIcon() {
      if (this.item.locked < 2) {
        this.spawnModal({
          component: 'ModalIconSelector',
          title: 'Select foreground icon',
          busevent: 'FGS-saveIcon',
          subject: {item: this.item, type: 'foreground'},
        });
      }
    },
    backgroundPosition(id) {
      const x = 0 - ((id * 60) % 600);
      const y = -660 - Math.floor(id / 10) * 60;
      return x + 'px ' + y + 'px';
    },
    updateItem() {
      // check colorSwitch
      if (!this.item.hasColor) {
        this.item.colorSwitch = 0;
      }
      const itemCopy = this.globalJSONCopy(this.item);
      itemCopy.hitType = this.hitTypeArray;
      if (itemCopy.hasOwnProperty('eventTrigger')) {
        if (itemCopy.eventTrigger.element == null) {
          delete itemCopy.eventTrigger;
        }
      }
      this.$emit('onUpdate', itemCopy);
    },
    askRemoveForeground() {
      this.spawnModal({
        component: 'ModalConfirm',
        title: 'Delete foreground',
        busevent: 'FGS-removeForeground',
        text: 'Are you sure you want to delete '+this.item.name+' ?',
        subject: this.item,
      });
    },
    editTitle() {
      this.spawnModal({
        component: 'ModalTextInput',
        title: 'Foreground title',
        label: 'Title',
        text: this.item.name,
        busevent: 'FGS-saveTitle',
        subject: this.item,
      });
    },
    showElemLocks() {
      this.spawnModal({
        component: 'ModalElemLocks',
        title: 'Element used in following levels',
        subject: {item: this.item},
      });
    },
    editOnEvenExplode() {
      const elementData = this.globalJSONCopy(this.item.eventTrigger.element);
      const modalParams = {
        component: 'ModalEventExplodeElement',
        title: 'Edit element',
        elementData: {element: elementData},
        saveEvent: 'FGS-saveExplodeElement',
      };
      bus.$on('FGS-saveExplodeElement', this.saveExplodeElement);
      this.spawnModal(modalParams);
    },
    saveExplodeElement(obj) {
      this.item.eventTrigger.element = obj.data.element;
      this.updateItem();
      this.$forceUpdate();
      bus.$off('FGS-saveExplodeElement', this.saveExplodeElement);
      this.onEventExplodeElementName = this.elementName(this.item.eventTrigger.element);
    },
    elementName(element) {
      let el;
      if (element) {
        if (element.hasOwnProperty('foreground')) {
          el = this.library.foregrounds.find((el) => el.id == element.foreground);
        }
        if (element.hasOwnProperty('background')) {
          el = this.library.backgrounds.find((el) => el.id == element.background);
        }
      }
      if (el) {
        return el.name;
      } else {
        return 'SELECT';
      }
    },
    toggleEventTrigger(bool) {
      // this.hasEventTrigger = bool ? true : false;
      if (bool) {
        if (!this.item.hasOwnProperty('eventTrigger')) {
          this.item.eventTrigger = {
            action: 0,
            element: null,
            onEvent: 0,
            respectIndestructible: false,
          };
          this.onEventExplodeElementName = this.elementName(this.item.eventTrigger.element);
        }
      } else {
        delete this.item.eventTrigger;
        this.onEventExplodeElementName = 'SELECT';
      }
      this.updateItem();
    },
    toggleSwitch(prop) {
      const propParts = prop.split('.');
      let el = this.item[propParts[0]];
      for (let i=1; i<propParts.length; i++) {
        el = el[propParts[i]];
      }
      el = !el;
      this.updateItem();
    },
  },
};
</script>

<style scoped>
.IconHolder {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
  padding: 10px;
}
</style>
