<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs4>
        <v-card width="100%">
          <v-toolbar color="primary">
            <v-toolbar-title>{{ modal.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout row wrap :key="refresh">
                  <v-flex xs12>
                     <v-text-field
                        :value="conditionName"
                        label="Name"
                        :error="alerts.name.show"
                        :error-messages="alerts.name.message"
                        disabled
                      ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <div><v-select :items="types" label="Type" v-model=
                    "conditiondata.conditionType"></v-select></div>
                  </v-flex>
                  <v-flex xs12 v-if="showElements">
                    Element
                    <div
                      class="GridItems"
                    >
                      <div
                        v-for="(item, index) in items"
                        :key="'griditem-' + index"
                        class="SpriteGridBlock"
                        :class="{Selected: isElementActive(item)}"
                      >
                        <GridItem
                          :items="gridItemData(item)"
                          @click="setElementActive(item)"
                        />
                        <div class="ToolTip">{{item.name}}</div>
                      </div>
                      <div v-if="items.length == 0">No elements found in library.</div>
                    </div>
                    <v-alert type="error" :value="alerts.items.show" outline>{{alerts.items.message}}</v-alert>
                  </v-flex>
                  <v-flex xs12 v-if="conditiondata.conditionType == 1">
                    <v-slider
                      :label="`Layers: ${backgroundLayers}`"
                      v-model="backgroundLayers"
                      color="white"
                      :min="1"
                      :max="maxLayers"
                      :disabled="layersDisabled"
                      :hint="`max ${maxLayers} layers`"
                      persistent-hint
                    ></v-slider>
                  </v-flex>
                  <v-flex xs12 v-if="conditiondata.conditionType == 2">
                    <v-slider
                      :label="`Amount: ${maxSetting}`"
                      v-model="maxSetting"
                      color="white"
                      :min="1"
                      :max="12"
                    ></v-slider>
                  </v-flex>
                  <v-flex xs12 v-if="showColors">
                    Color {{colorsLocked ? '(locked)':''}}
                    <div class="GridItems Colorz" :class="{Locked: colorsLocked}">
                      <div
                        v-for="(color, i) in availableColors"
                        :key="'color-'+i"
                        class="ConditionColor"
                        @click="setColorActive(color)"
                        :class="{Selected: isColorActive(color)}"
                        >
                          <GridItem
                            :items="[{color: colorToId(color), icon: 63, type: 'foreground'}]"
                          />
                          <div class="ToolTip">{{color}}</div>
                        </div>
                    </div>
                  </v-flex>
                  <v-flex xs12 v-if="conditiondata.conditionType != 3">
                    <v-switch
                      label="Always use"
                      v-model="conditiondata.alwaysUse"
                      ></v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          <v-card-actions>
            <v-btn flat color="primary" @click="askDelete" :disabled="deleteDisabled">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn flat @click="closeModal(modal.id)">Cancel</v-btn>
            <v-btn color="primary" @click="save" :disabled="isSaveDisabled">
              Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import bus from '../bus';
import GridItem from './GridItem.vue';
import {mapGetters, mapActions} from 'vuex';

export default {
  components: {
    GridItem,
  },
  props: {
    modal: {
      type: Object,
    },
  },
  data() {
    return {
      conditiondata: this.globalJSONCopy(this.modal.conditiondata),
      conditionName: '',
      backgroundLayers: 1,
      maxSetting: 1,
      maxLayers: 1,
      refresh: 0,
      showColors: false,
      layersDisabled: true,
      alerts: {
        name: {
          show: false,
          message: '',
        },
        items: {
          show: false,
          message: '',
        },
      },
    };
  },
  created() {
    bus.$on('MTC-deleteTrigger', this.deleteTrigger);
    this.setName();
    this.showColors = this.colorEnabled();
    this.maxSetting = this.globalGetProp(this.conditiondata, 'maxSetting', 12);
    if (this.conditiondata.elements && this.conditiondata.elements.length > 0) {
      this.backgroundLayers = this.globalGetProp(this.conditiondata.elements[0], 'layer', 1);
    }
  },
  destroyed() {
    bus.$off('MTC-deleteTrigger');
  },
  watch: {
    conditionType() {
      this.conditiondata.elements = [];
      this.clearAlerts();
      this.setName();
      this.showColors = this.colorEnabled();
      this.refresh++;
    },
    maxSetting(val) {
      this.conditiondata.maxSetting = val;
    },
    backgroundLayers(val) {
      if (this.conditiondata.elements && this.conditiondata.elements.length > 0) {
        this.conditiondata.elements[0].layer = val;
      }
    },
  },
  computed: {
    ...mapGetters([
      'constants',
      'serverData',
    ]),
    isSaveDisabled() {
      if (this.conditiondata.hasOwnProperty('elements')) {
        const hasEl = this.conditiondata.elements.length > 0;
        const hasFG = hasEl && this.conditiondata.elements[0].hasOwnProperty('foreground');
        const hasBG = hasEl && this.conditiondata.elements[0].hasOwnProperty('background') &&
        this.conditiondata.elements[0].background > 1;
        const isScore = this.conditiondata.conditionType == 4;
        if (isScore) {
          return false;
        }
        return !hasFG && !hasBG;
      }
      return false;
    },
    showElements() {
      return this.conditiondata.conditionType < 3 || this.conditiondata.conditionType == 5;
    },
    types() {
      const arr = [];
      for (let i=0; i<this.constants.conditiontypes.length; i++) {
        const name = this.constants.conditiontypes[i];
        if (name != 'turns') {
          arr.push({value: i, text: name});
        }
      }
      return arr;
    },
    library() {
      return this.serverData.getGame.gameLibrary;
    },
    availableColors() {
      const myColors = this.constants.fgColors.slice(0, 5);
      myColors.shift();
      myColors.unshift('random');
      return myColors;
    },
    colorsLocked() {
      if (this.conditiondata.conditionType == 0) {
        const conditionElements = this.conditiondata.elements;
        if (conditionElements.length > 0) {
          if ('foreground' in conditionElements[0]) {
            const libItem = this.items.find((el) => el.id === conditionElements[0].foreground);
            return libItem.hasColor && libItem.colorSwitch > 0;
          }
        }
        return false;
      }
      return false;
    },
    deleteDisabled() {
      return this.modal.deleteDisabled;
    },
    conditionType() {
      return this.conditiondata.conditionType;
    },
    items() {
      let payload = [];
      // clear foregrounds or clear droppers
      if (this.conditiondata.conditionType == 0 || this.conditiondata.conditionType == 2) {
        payload = this.globalJSONCopy(this.library.foregrounds);
        payload.map((el) => el.type = 'foreground');
        if (this.conditiondata.conditionType == 2) {
          // droppers
          payload = payload.filter((el) => this.isIndestructable(el));
        } else {
          payload = payload.filter((el) => !this.isIndestructable(el));
        }
      }
      // clear backgrounds
      if (this.conditiondata.conditionType == 1) {
        payload = this.globalJSONCopy(this.library.backgrounds);
        payload.map((el) => el.type = 'background');
        payload = payload.filter((el) => el.id != 1);
        payload = payload.filter((el) => {
          if (el.hasOwnProperty('carpet') && el.carpet == false) {
            return true;
          } else {
            return false;
          }
        });
      }
      // carpet
      if (this.conditiondata.conditionType == 5) {
        payload = this.globalJSONCopy(this.library.backgrounds);
        payload.map((el) => el.type = 'background');
        payload = payload.filter((el) => el.carpet);
      }
      if (payload) {
        payload = payload.filter((el) => {
          const isEmpty = el.id == 0;
          const isInaccesible = el.type == 'background' && el.id == 1;
          return !isEmpty && !isInaccesible;
        });
      }
      return payload;
    },
  },
  methods: {
    ...mapActions({
      closeModal: 'closeModal',
      spawnModal: 'spawnModal',
    }),
    colorEnabled() {
      if (this.conditiondata.conditionType == 0) {
        const conditionElements = this.conditiondata.elements;
        if (conditionElements.length > 0) {
          if ('foreground' in conditionElements[0]) {
            const libItem = this.items.find((el) => el.id === conditionElements[0].foreground);
            return libItem.hasColor;
          }
        }
        return false;
      }
      return false;
    },
    isIndestructable(el) {
      if (
        el.hitType[0] == false &&
        el.hitType[1] == false &&
        el.hitType[2] == false &&
        el.hitType[3] == false &&
        el.hitType[4] == false
      ) {
        return true;
      } else {
        return false;
      }
    },
    setName() {
      let elementName = '';
      const conditionElements = this.conditiondata.elements;
      if (conditionElements) {
        if (conditionElements.length > 0) {
          if ('foreground' in conditionElements[0]) {
            elementName = ' '+this.items.find((el) => el.id === conditionElements[0].foreground).name;
          }
          if ('background' in conditionElements[0]) {
            elementName = ' '+this.items.find((el) => el.id === conditionElements[0].background).name;
          }
        }
      }
      if (this.conditiondata.conditionType == 4) {
        elementName = 'score';
      }
      this.conditionName = elementName;
    },
    clearAlerts() {
      this.alerts.name.show = false;
      this.alerts.name.message = '';
      this.alerts.items.show = false;
      this.alerts.items.message = '';
    },
    save() {
      // clear alerts
      this.clearAlerts();
      // check if elements selected
      if (this.conditiondata.conditionType != 4 && this.globalGetProp(this.conditiondata, 'elements', []).length == 0) {
        this.alerts.items.show = true;
        this.alerts.items.message = 'No element selected.';
      }
      if (!this.alerts.name.show && !this.alerts.items.show) {
        // all checks passed
        this.conditiondata.name = this.conditionName;
        // check of layer property is present
        if (this.conditiondata.conditionType == 1) {
          if (!this.conditiondata.elements[0].hasOwnProperty('layer')) {
            this.conditiondata.elements[0].layer = 1;
          }
        }
        if (this.globalGetProp(this.conditiondata, 'elements', []).length == 0) {
          delete this.conditiondata.elements;
        }
        const conditionData = this.globalJSONCopy(this.conditiondata);
        bus.$emit(this.modal.saveEvent, {
          index: this.modal.index,
          data: conditionData,
        });
        this.closeModal(this.modal.id);
      }
    },
    askDelete() {
      this.spawnModal({
        component: 'ModalConfirm',
        title: 'Delete win condition',
        busevent: 'MTC-deleteTrigger',
        text: 'Are you sure you want to delete this win condition?',
        subject: this.modal.triggerId,
      });
    },
    deleteTrigger() {
      bus.$emit(this.modal.deleteEvent, {
        index: this.modal.index,
      });
      this.closeModal(this.modal.id);
    },
    setElementActive(item) {
      const data = {};
      if (this.conditiondata.conditionType == 0 || this.conditiondata.conditionType == 2) {
        data.foreground = item.id;
        data.color = 0;
      }
      if (this.conditiondata.conditionType == 1 || this.conditiondata.conditionType == 5) {
        data.background = item.id;
        data.layer = 1;
      }
      this.conditiondata.elements = [data];
      this.setName();
      this.showColors = this.colorEnabled();
      this.layersDisabled = !(this.conditiondata.elements && this.conditiondata.elements.length > 0);
      this.maxLayers = 1;
      if (this.conditiondata.elements.length > 0) {
        const libItem = this.library.backgrounds.find((el) => el.id == this.conditiondata.elements[0].background);
        this.maxLayers = libItem.maxLayers;
      }
      this.refresh++;
    },
    isElementActive(item) {
      return this.conditiondata.elements.some((el) => {
        if ('foreground' in el) {
          return el.foreground == item.id;
        }
        if ('background' in el) {
          return el.background == item.id;
        }
      });
    },
    setColorActive(color) {
      const conditionElements = this.conditiondata.elements;
      if (conditionElements.length > 0) {
        if ('foreground' in conditionElements[0]) {
          conditionElements[0].color = this.constants.fgColors.findIndex((el) => el === color);
          if (conditionElements[0].color == 0) {
            delete conditionElements[0].color;
          }
        }
        this.refresh++;
      }
    },
    colorToId(color) {
      if (color == 'random') {
        return -1;
      }
      return this.constants.fgColors.findIndex((el) => el === color);
    },
    isColorActive(color) {
      const colorIndex = this.constants.fgColors.findIndex((el) => el === color);
      // show no color property as 'random'
      if (this.conditiondata.elements.length > 0) {
        if (this.conditiondata.elements[0].color == undefined) {
          if (color == 'random') {
            return true;
          }
          return false;
        }
        return this.conditiondata.elements[0].color == colorIndex;
      } else {
        return false;
      }
    },
    gridItemData(item) {
      return [
        {
          icon: item.icon,
          type: item.type,
          id: item.id,
        },
      ];
    },
  },
};
</script>

<style scoped>
.v-card {
  width: 640px;
}
.GridItems {
  display: flex;
  flex-wrap: wrap;
}
.SpriteGridBlock,
.ConditionColor {
  border: solid 1px gray;
  width: 60px;
  height: 60px;
  margin: 3px;
  cursor: pointer;
  flex: 0 0 60px;
  position: relative;
}
.SpriteGridBlock:hover,
.ConditionColor:hover {
  background-color: var(--secondary-color);
}
.SpriteGridBlock.Selected,
.ConditionColor.Selected {
  background-color: var(--primary-color);
}
.ToolTip {
  position: absolute;
  top: -27px;
  left: -1px;
  white-space: nowrap;
  background-color: #393939;
  padding: 2px 5px;
  border: solid 1px rgba(255,255,255,.3);
  display: none;
}
.SpriteGridBlock:hover .ToolTip,
.ConditionColor:hover .ToolTip
{
  display: block;
}
.ConditionColor .Color{
  width:50px;
  height:50px;
  border-radius: 50%;
  margin:5px;
  border:solid 2px white;
}

.Colorz.Locked{
  opacity: .5;
  pointer-events: none;
}
</style>
