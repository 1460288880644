<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md3>
        <v-card>
          <v-toolbar color="primary">
            <v-toolbar-title>{{modal.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="txt"
                :rules="txtRules"
                :label="modal.label"
                required
                @keypress.enter="save"
              ></v-text-field>
              <v-switch
                label="Also copy game levels."
                v-model="copyLevels"
              >
              </v-switch>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn flat @click="closeModal(modal.id)">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn
            color="primary"
            @click="save"
            :disabled="!valid"
            >Load</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import bus from '../bus';
import {mapActions} from 'vuex';

export default {
  props: {
    modal: {
      type: Object,
    },
  },
  data() {
    return {
      txt: this.modal.text,
      valid: false,
      txtRules: [(v) => !!v || 'Required field'],
      copyLevels: false,
    };
  },
  methods: {
    ...mapActions({
      closeModal: 'closeModal',
    }),
    save() {
      if (this.$refs.form.validate()) {
        bus.$emit(this.modal.busevent, {
          modalid: this.modal.id,
          value: this.txt,
          copyLevels: this.copyLevels,
          subject: this.modal.subject,
        });
        this.closeModal(this.modal.id);
      }
    },
  },
};
</script>
