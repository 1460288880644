<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-card max-width="500">
        <v-toolbar dark color="warn">
          <v-toolbar-title>{{modal.title}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
            Cannot delete item because it is still used in the following levels:<br>
            <span v-for="(item, i) in modal.subject.item.elemLocks" :key="i">
              {{game.gameSettings.levelNumberingStartsAt + item}}
              <span v-if="i < modal.subject.item.elemLocks.length-1">, </span>
            </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="close" color="warn">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  props: {
    modal: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters([
      'serverData',
    ]),
    game() {
      return this.serverData.getGame;
    },
  },
  methods: {
    ...mapActions(
        {closeModal: 'closeModal'},
    ),
    close() {
      this.closeModal(this.modal.id);
    },
  },
};
</script>
