<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex style="flex:0 0 390px">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{modal.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-progress-circular
              class="centerIcon"
              indeterminate
              color="primary"
              v-if="availableGames.length == 0"
            ></v-progress-circular>
            <v-list two-line v-if="availableGames.length > 0" style="overflow-y:auto;max-height:70vh">
              <template v-for="(item, index) in availableGames">
                <v-list-tile :key="item.id" :class="{'Disabled': item.disabled}">
                  <v-list-tile-action>
                    <v-icon>{{item.icon}}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <span class="itemtitle" @click="selectGame(item.id)">
                        {{item.title}}</span>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>
                      {{item.subtitle}}</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-icon
                      class="Delete"
                      v-if="item.id!='newGame'"
                      @click="askRemove(item.id)"
                    >delete</v-icon>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider v-if="index + 1 < availableGames.length"
                           :key="`divider-${item.id}`"></v-divider>
              </template>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn flat @click="closeModal(modal.id)" v-if="modal.closebutton">
              Cancel</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import bus from '../bus';

export default {
  props: {
    modal: {
      type: Object,
    },
  },
  data() {
    return {
      gamesList: [],
      deletedGame: null,
      busEvents: [
        'removeGame',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'internals',
      'network',
      'serverData',
    ]),
    availableGames(state) {
      const list = this.gamesList.slice();
      list.sort((a, b) => {
        const adate = new Date(a.subtitle);
        const bdate = new Date(b.subtitle);
        if (adate > bdate) {
          return -1;
        }
        if (adate < bdate) {
          return 1;
        }
        return 0;
      });
      return list;
    },
  },
  watch: {
    'serverData.getAccountInfo': function(val) {
      this.getGames();
    },
  },
  created() {
    // Bus events shortcode MGB
    this.busEvents.forEach((element) => {
      bus.$on('MGB-'+element, eval('this.'+element));
    });
    this.getAccountInfo();
  },
  mounted() {
    if (this.serverData.getAccountInfo) {
      this.getGames();
    }
  },
  methods: {
    ...mapActions({
      postDataPromise: 'postDataPromise',
      getAccountInfo: 'getAccountInfo',
      closeModal: 'closeModal',
      showMessage: 'showMessage',
      closeAllModals: 'closeAllModals',
      spawnModal: 'spawnModal',
      clearCurrentGame: 'clearCurrentGame',
      getGenerateStatus: 'getGenerateStatus',
      getGamesSummary: 'getGamesSummary',
    }),
    getGames() {
      this.getGamesSummary().then(this.parseGames);
    },
    parseGames() {
      const data = this.serverData.getGamesSummary;
      this.gamesList = [];
      if (this.serverData.getAccountInfo.accountInfo.internals.maxGames <= data.gamesSummary.length) {
        this.gamesList.push({
          id: 'newGame',
          icon: 'add',
          title: 'New game',
          disabled: true,
          subtitle: 'upgrade your account to create multiple games',
        });
      } else {
        this.gamesList.push({
          id: 'newGame',
          icon: 'add',
          title: 'New game',
          disabled: false,
          subtitle: 'Start fresh',
        });
      }
      if (data.gamesSummary.length > 0) {
        for (let i = 0; i < data.gamesSummary.length; i++) {
          const game = data.gamesSummary[i];
          const obj = {};
          obj.id = game.gameId;
          obj.title = game.name;
          obj.subtitle = game.lastModified;
          obj.icon = 'description';
          this.gamesList.push(obj);
        }
      }
    },
    askRemove(id) {
      this.spawnModal({
        component: 'ModalConfirm',
        title: 'Delete game',
        text: 'Are you sure you want to delete this game and all it\'s data?',
        subject: id,
        busevent: 'MGB-removeGame',
        closebutton: false,
      });
    },
    removeGame(id) {
      this.deletedGame = id;
      this.postDataPromise({
        command: 'removeGame',
        gameId: id,
      }).then(this.afterRemoveGame);
    },
    afterRemoveGame(response) {
      this.showMessage({type: 'success', text: 'Game deleted.'});
      if (this.deletedGame == this.internals.currentGameId) {
        this.clearCurrentGame();
        this.closeAllModals();
        this.spawnModal({
          component: 'ModalGamesBrowse',
          title: 'Select game to load',
          closebutton: true,
        });
        this.$router.push('/');
      }
      this.serverData.getGamesSummary = response.data;
      this.parseGames();
    },
    selectGame(id) {
      if (id === 'newGame') {
        this.spawnModal({
          component: 'ModalNewGame',
          title: 'Select game template to load',
          closebutton: true,
        });
      }
      if (typeof id === 'number') {
        const obj = {
          command: 'getGame',
          gameId: id,
        };
        this.internals.currentGameId = id;
        this.postDataPromise(obj).then(this.afterGetGame);
      }
    },
    afterGetGame(response) {
      this.serverData.getGame = response.data;
      this.closeAllModals();
      this.$router.push('/settings');
      this.getGenerateStatus();
    },
  },
  destroyed() {
    this.busEvents.forEach((element) => {
      bus.$off('MGB-'+element);
    });
  },
};
</script>

<style scoped>
.Delete {
  display: none;
}
.v-list > div:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.v-list > div:hover .Delete {
  display: inline;
}
.itemtitle:hover {
  text-decoration: underline;
  cursor: pointer;
}
.centerIcon {
  display: block;
  margin: 0 auto;
}
*[role='listitem'].Disabled{
  opacity: .3;
  pointer-events: none;
}
</style>
