<template>
  <v-container grid-list-xl fluid>
    <v-layout align-center justify-center>
      <v-flex center>
        <v-card style="width:100%">
          <div style="display:flex; padding: 20px" v-if="game.gameSettings.gameFlow.length > 0">
            <div class="LegendBlock">
              <div style="background-color: #AE4592" class="LegendColor"></div>
              <div>Target difficulty</div>
            </div>
            <div class="LegendBlock">
              <div style="background-color: #4CAF50" class="LegendColor"></div>
              <div>Calculated difficulty</div>
            </div>
            <div class="LegendBlock">
              <div style="background-color: orange" class="LegendColor"></div>
              <div>Turn increase per level</div>
            </div>
          </div>
          <div class="pa-4" style="overflow-x:auto;width:100%;" v-if="game.gameSettings.gameFlow.length > 0">
            <b>Gameflow difficulty</b>
            <div :style="{'position':'relative','width':chartWidth+'px','height':'350px'}">
              <canvas id="myChart" :width="chartWidth" height="350"></canvas>
            </div>
          </div>
          <v-card class="pa-4">
            <div style="display:flex">
              <div style="margin:auto"></div>
              <div class="pa-2">
                <div v-if="accountLockedSettings">
                  <v-chip color="primary">
                    upgrade your account to create your ideal game flow and modify game settings
                    <v-icon right>lock</v-icon>
                  </v-chip>
                </div>
              </div>
            </div>
            <v-layout>
              <div style="max-width:400px">
                <v-card color="card" class="ma-2">
                <v-card-title class="title">Game settings</v-card-title>
                <v-card-text>
                  <v-switch
                  label="Random colors"
                  v-model="game.gameSettings.randomColors"
                  @change="updateValue"
                  :disabled="lockedIn"
                  ></v-switch>
                  <v-switch
                  label="Only count background layer 1"
                  v-model="game.gameSettings.onlyCountBackgroundLayer1"
                  @change="updateValue"
                  :disabled="lockedIn"
                  ></v-switch>
                  <v-switch
                  label="Allow level designer to use grid elements"
                  v-model="game.gameSettings.allowDesignerToUseGridElements"
                  @change="updateValue"
                  :disabled="lockedIn"
                  ></v-switch>
                  <v-radio-group
                    v-model="game.gameSettings.primaryGravityToInaccessibleIsExit"
                    @change="updateValue">
                    <v-radio
                      key="pgti1"
                      label="all cells with primary gravity directly leading
                      to an inaccessible cell are considered exits"
                      :value="true"
                      :disabled="lockedIn"
                    ></v-radio>
                    <v-radio
                      key="pgti2"
                      label="cells with gravity leading to no other cell are considered exits"
                      :value="false"
                      :disabled="lockedIn"
                    ></v-radio>
                  </v-radio-group>
                  <v-select
                  :items="sidewaysGravityList"
                  item-text="text"
                  item-value="value"
                  label="Sideways gravity"
                  color="white"
                  v-model="game.gameSettings.sidewaysGravityOrder"
                  @change="updateValue"
                  :disabled="lockedIn"
                  ></v-select>
                </v-card-text>
                </v-card>
                <v-card color="card" class="ma-2">
                  <v-card-title class="title">Stars</v-card-title>
                  <v-card-text>
                    <v-select
                    :items="starsBasedOnList"
                    label="Stars based on"
                    color="white"
                    v-model="game.gameSettings.starsBasedOn"
                    item-text="string"
                    item-value="value"
                    @change="updateValue"
                    :disabled="lockedIn"
                    ></v-select>
                    <br>
                    <div v-if="game.gameSettings.starsBasedOn > 0">
                      <b>Amount of stars</b>
                      <div style="display:flex">
                      <v-rating
                      color="white"
                      background-color="white"
                      length="1"
                      readonly
                      :value="1"
                      x-large
                      ></v-rating>
                      <v-rating
                      v-model="stars.amountOfStars"
                      color="white"
                      background-color="white"
                      length="3"
                      x-large
                      ></v-rating></div>
                      <br>
                      <b>Difficulty increase per star</b>
                      <v-text-field
                        label="From 1 to 2 stars"
                        placeholder="0-90"
                        color="white"
                        v-model.number="difficultyIncreasePerStar[0]"
                        @change="updateValue"
                      ></v-text-field>
                      <v-text-field
                        label="From 2 to 3 stars"
                        placeholder="0-90"
                        color="white"
                        v-if="stars.amountOfStars > 1"
                        v-model.number="difficultyIncreasePerStar[1]"
                        @change="updateValue"
                      ></v-text-field>
                      <v-text-field
                        label="From 3 to 4 stars"
                        placeholder="0-90"
                        color="white"
                        v-if="stars.amountOfStars > 2"
                        v-model.number="difficultyIncreasePerStar[2]"
                        @change="updateValue"
                      ></v-text-field>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <v-card color="card" class="ma-2" width="350">
                <v-card-title class="title">Extra settings</v-card-title>
                <v-card-text>
                  <v-switch
                  label="Grid background layers is maximum"
                  v-model="game.gameSettings.gridBackgroundLayersIsMaximum"
                  @change="updateValue"
                  :disabled="lockedIn"
                  ></v-switch>
                  <br>
                  <v-text-field
                    label="Level numbering starts at:"
                    color="white"
                    v-model.number="game.gameSettings.levelNumberingStartsAt"
                    @change="updateValue"
                    :disabled="lockedIn"
                  ></v-text-field>
                  <v-select
                  :items="endGameExplosionsList"
                  item-text="text"
                  item-value="value"
                  label="Endgame explosions"
                  color="white"
                  v-model="game.gameSettings.endGameExplosions"
                  @change="updateValue"
                  :disabled="lockedIn"
                  ></v-select>
                  <div v-if="game.gameSettings.endGameExplosions == 2">
                    <b>Endgame color increase after cascades</b>
                    <div class="d-flex" style="align-items:center">
                      <v-text-field
                        color="white"
                        v-model.number="game.gameSettings.endGameColorIncreaseAfterCascades[0]"
                        @change="updateValue"
                        :disabled="lockedIn"
                      ></v-text-field>
                      <div style="padding:0 5px">,</div>
                      <v-text-field
                        color="white"
                        v-model.number="game.gameSettings.endGameColorIncreaseAfterCascades[1]"
                        @change="updateValue"
                        :disabled="lockedIn"
                      ></v-text-field>
                      <div style="padding:0 5px">,</div>
                      <v-text-field
                        color="white"
                        v-model.number="game.gameSettings.endGameColorIncreaseAfterCascades[2]"
                        @change="updateValue"
                        :disabled="lockedIn"
                      ></v-text-field>
                      <div style="padding:0 5px">,</div>
                      <v-text-field
                        color="white"
                        v-model.number="game.gameSettings.endGameColorIncreaseAfterCascades[3]"
                        @change="updateValue"
                        :disabled="lockedIn"
                      ></v-text-field>
                      <div style="padding:0 5px">,</div>
                      <v-text-field
                        color="white"
                        v-model.number="game.gameSettings.endGameColorIncreaseAfterCascades[4]"
                        @change="updateValue"
                        :disabled="lockedIn"
                      ></v-text-field>
                    </div>
                    <br>
                    <b>Endgame max color increase</b>
                    <div class="d-flex"><v-slider
                    v-model="game.gameSettings.endGameMaxColorIncrease"
                    class="ma-0 pa-0"
                    color="white"
                    :min="4"
                    :max="9"
                    thumb-color="orange"
                    :disabled="lockedIn"
                    @change="updateValue"
                    ></v-slider>
                    <div class="ml-2 mt-1 subheading">{{game.gameSettings.endGameMaxColorIncrease}}</div></div>
                  </div>
                  <v-select
                  :items="matchExplosionsList"
                  item-text="text"
                  item-value="value"
                  label="Match explosions"
                  color="white"
                  v-model="game.gameSettings.matchExplosions"
                  @change="updateValue"
                  :disabled="lockedIn"
                  ></v-select>
                  <b>Suboptimal AI moves</b>
                  <div class="d-flex" style="align-items:center">
                    <v-text-field
                      label="Best"
                      color="white"
                      v-model.number="game.gameSettings.suboptimalAIMoves[0]"
                      @change="updateValue"
                      :disabled="lockedIn"
                    ></v-text-field>
                    <div style="padding:0 5px">,</div>
                      <v-text-field
                      label="2nd best"
                      color="white"
                      v-model.number="game.gameSettings.suboptimalAIMoves[1]"
                      @change="updateValue"
                      :disabled="lockedIn"
                    ></v-text-field>
                    <div style="padding:0 5px">,</div>
                      <v-text-field
                      label="3rd best"
                      color="white"
                      v-model.number="game.gameSettings.suboptimalAIMoves[2]"
                      @change="updateValue"
                      :disabled="lockedIn"
                    ></v-text-field>
                    <div style="padding:0 5px">,</div>
                      <v-text-field
                      label="4th best"
                      color="white"
                      v-model.number="game.gameSettings.suboptimalAIMoves[3]"
                      @change="updateValue"
                      :disabled="lockedIn"
                    ></v-text-field>
                    <div style="padding:0 5px">,</div>
                      <v-text-field
                      label="5th best"
                      color="white"
                      v-model.number="game.gameSettings.suboptimalAIMoves[4]"
                      @change="updateValue"
                      :disabled="lockedIn"
                    ></v-text-field>
                    <div style="padding:0 5px">,</div>
                  </div>
                  <div class="d-flex" style="align-items:center">
                    <v-text-field
                    label="6th best"
                    color="white"
                    v-model.number="game.gameSettings.suboptimalAIMoves[5]"
                    @change="updateValue"
                    :disabled="lockedIn"
                  ></v-text-field>
                  <div style="padding:0 5px">,</div>
                    <v-text-field
                    label="7th best"
                    color="white"
                    v-model.number="game.gameSettings.suboptimalAIMoves[6]"
                    @change="updateValue"
                    :disabled="lockedIn"
                  ></v-text-field>
                  <div style="padding:0 5px">,</div>
                    <v-text-field
                    label="8th best"
                    color="white"
                    v-model.number="game.gameSettings.suboptimalAIMoves[7]"
                    @change="updateValue"
                    :disabled="lockedIn"
                  ></v-text-field>
                  <div style="padding:0 5px">,</div>
                    <v-text-field
                    label="9th best"
                    color="white"
                    v-model.number="game.gameSettings.suboptimalAIMoves[8]"
                    @change="updateValue"
                    :disabled="lockedIn"
                  ></v-text-field>
                  <div style="padding:0 5px">,</div>
                    <v-text-field
                    label="10th best"
                    color="white"
                    v-model.number="game.gameSettings.suboptimalAIMoves[9]"
                    @change="updateValue"
                    :disabled="lockedIn"
                  ></v-text-field>
                  </div>
                  <br>
                  <b>Base symmetry chances</b>
                  <div class="d-flex" style="align-items:center">
                    <v-text-field
                      label="None"
                      color="white"
                      v-model.number="game.gameSettings.baseSymmetryChances[0]"
                      @change="updateValue"
                      :disabled="lockedIn"
                    ></v-text-field>
                    <div style="padding:0 5px">,</div>
                      <v-text-field
                      label="Flip X"
                      color="white"
                      v-model.number="game.gameSettings.baseSymmetryChances[1]"
                      @change="updateValue"
                      :disabled="lockedIn"
                    ></v-text-field>
                    <div style="padding:0 5px">,</div>
                      <v-text-field
                      label="Flip Y"
                      color="white"
                      v-model.number="game.gameSettings.baseSymmetryChances[2]"
                      @change="updateValue"
                      :disabled="lockedIn"
                    ></v-text-field>
                    <div style="padding:0 5px">,</div>
                      <v-text-field
                      label="Flip X Y"
                      color="white"
                      v-model.number="game.gameSettings.baseSymmetryChances[3]"
                      @change="updateValue"
                      :disabled="lockedIn"
                    ></v-text-field>
                    <div style="padding:0 5px">,</div>
                  </div>
                  <div class="d-flex" style="align-items:center">
                    <v-text-field
                    color="white"
                    label="Flip all"
                    v-model.number="game.gameSettings.baseSymmetryChances[4]"
                    @change="updateValue"
                    :disabled="lockedIn"
                  ></v-text-field>
                  <div style="padding:0 5px">,</div>
                    <v-text-field
                    label="Swap X Y"
                    color="white"
                    v-model.number="game.gameSettings.baseSymmetryChances[5]"
                    @change="updateValue"
                    :disabled="lockedIn"
                  ></v-text-field>
                  <div style="padding:0 5px">,</div>
                    <v-text-field
                    label="Swap X Y inv"
                    color="white"
                    v-model.number="game.gameSettings.baseSymmetryChances[6]"
                    @change="updateValue"
                    :disabled="lockedIn"
                  ></v-text-field>
                  <div style="padding:0 5px">,</div>
                    <v-text-field
                    label="Swap all"
                    color="white"
                    v-model.number="game.gameSettings.baseSymmetryChances[7]"
                    @change="updateValue"
                    :disabled="lockedIn"
                  ></v-text-field>
                  </div>
                  <br>
                  <b>maxDropperSpawnsPerTurn</b>
                  <div class="d-flex"><v-slider
                  v-model="game.gameSettings.maxDropperSpawnsPerTurn"
                  class="ma-0 pa-0"
                  color="white"
                  :min="1"
                  :max="12"
                  thumb-color="orange"
                  :disabled="lockedIn"
                  @change="updateValue"
                  ></v-slider>
                  <div class="ml-2 mt-1 subheading">{{game.gameSettings.maxDropperSpawnsPerTurn}}</div></div>
                  <v-switch
                  label="Dropper may spawn again in same turn as they fall"
                  v-model="game.gameSettings.dropperMaySpawnAgainInSameTurnAsTheyFall"
                  @change="updateValue"
                  :disabled="lockedIn"
                  ></v-switch>
                </v-card-text>
              </v-card>
            </v-layout>
          </v-card>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import 'chartjs-plugin-dragdata';
import {mapGetters, mapActions} from 'vuex';
import Chart from 'chart.js';

export default {
  data() {
    return {
      chartFlow: {},
      accountLockedSettings: false,
      lockedIn: false,
      stars: {
        amountOfStars: 2,
      },
      loaded: false,
    };
  },
  computed: {
    ...mapGetters([
      'serverData',
      'constants',
    ]),
    chartWidth() {
      return 100 + (this.game.gameSettings.gameFlow.length*20);
    },
    matchExplosionsList() {
      return this.constants.matchExplosionsList;
    },
    sidewaysGravityList() {
      return this.constants.sidewaysGravityList;
    },
    endGameExplosionsList() {
      return this.constants.endGameExplosionsList;
    },
    starsBasedOnList() {
      return this.constants.starsBasedOnList;
    },
    game() {
      return this.serverData.getGame;
    },
    levelsList() {
      const arr = [];
      for (let i = 1; i <= this.game.gameSettings.gameFlow.length; i++) {
        arr.push({text: i, value: i-1});
      }
      return arr;
    },
    chartLabels() {
      const arr = [];
      for (let i = 1; i <= this.game.gameSettings.gameFlow.length; i++) {
        arr.push(i);
      }
      return arr;
    },
    accountInfo() {
      return this.serverData.getAccountInfo.accountInfo;
    },
    difficultyIncreasePerStar: {
      get() {
        return this.globalGetProp(this.game.gameSettings, 'difficultyIncreasePerStar', [10, 10, 10]);
      },
      set(val) {
        this.game.gameSettings.difficultyIncreasePerStar = val;
      },
    },
  },
  watch: {
    'stars.amountOfStars'(val) {
      this.game.gameSettings.amountOfStars = val+1;
      this.updateValue();
    },
  },
  created() {
    this.accountLockedSettings = this.accountInfo.internals.lockedGameSettings;
    this.lockedIn = this.accountLockedSettings;
  },
  mounted() {
    const me = this;
    this.getGameSettings().then(() => {
      me.$nextTick(() => {
        me.initChart();
        me.updateSettings();
        me.loaded = true;
      });
    });
  },
  methods: {
    ...mapActions({
      saveData: 'saveData',
      spawnModal: 'spawnModal',
      getGameSettings: 'getGameSettings',
    }),
    initChart() {
      if (this.game.gameSettings.gameFlow.length > 0) {
        const ctx = document.getElementById('myChart').getContext('2d');
        const config = {
          type: 'line',
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            dragData: false,
            dragX: false,
            dragDataRound: 0,
            legend: {
              display: false,
            },
            responsive: false,
            title: {
              display: false,
              text: '',
            },
            tooltips: {
              enabled: false,
            },
            hover: {
              mode: 'nearest',
              intersect: true,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    display: true,
                    color: 'rgba(255,255,255,0.1)',
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Level',
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    display: true,
                    color: 'rgba(255,255,255,0.1)',
                    zeroLineColor: 'rgba(255,255,255,0.1)',
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Difficulty',
                  },
                  ticks: {
                    max: 100,
                    min: 0,
                  },
                },
              ],
            },
          },
        };
        this.chartFlow = new Chart(ctx, config);
      }
    },
    updateValue() {
      if (!this.loaded) return;
      // clean up arrays
      this.game.gameSettings.endGameColorIncreaseAfterCascades =
        this.cleanArray(this.game.gameSettings.endGameColorIncreaseAfterCascades);
      this.game.gameSettings.suboptimalAIMoves = this.cleanArray(this.game.gameSettings.suboptimalAIMoves);
      this.game.gameSettings.baseSymmetryChances = this.cleanArray(this.game.gameSettings.baseSymmetryChances);
      // save data
      this.saveData({type: 'game-settings'});
      const me = this;
      setTimeout(function() {
        me.getGameSettings().then(function() {
          me.updateSettings();
        });
      }, 1000);
    },
    cleanArray(source) {
      const result = [];
      source.forEach((el) => {
        if (el !== '' && !isNaN(el)) {
          result.push(el);
        }
      });
      return result;
    },
    updateSettings() {
      if (this.game.gameSettings.gameFlow.length > 0 ) {
        this.chartFlow.data.labels = this.chartLabels;
        this.chartFlow.data.datasets =[{
          label: 'Difficulty',
          borderColor: '#AE4592',
          data: this.game.gameSettings.gameFlow,
          fill: false,
        },
        {
          label: 'Calculated',
          borderColor: '#4CAF50',
          data: this.game.gameSettings.resultFlow,
          fill: false,
        },
        {
          label: 'Turn increase',
          borderColor: 'orange',
          data: this.game.gameSettings.turnIncreasePerLevel,
          fill: false,
        }],
        this.chartFlow.update(false);
      }
      this.stars.amountOfStars = this.globalGetProp(this.game.gameSettings, 'amountOfStars', 2) - 1;
      this.$forceUpdate();
    },
  },
};
</script>

<style>
.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
}
.ct-label {
  color: rgba(255, 255, 255, 0.4);
  fill: rgba(255, 255, 255, 0.4);
}
.LegendBlock{
    display: flex;
    margin-right: 20px;
  }
.LegendColor{
  width: 30px;
  height: 20px;
  margin-right: 10px;
}
</style>
