<template>
  <v-app dark :style="cssProps">
    <img
    src="/images/sprites.png"
    alt=""
    id="sprite"
    style="visibility:hidden;position:absolute;left:0;top:0;width:0;height:0">
    <v-navigation-drawer
      clipped
      permanent
      app
      v-if="game != null && userLoggedIn"
      width=200
    >
      <v-list>
        <v-list-tile
          v-for="(item, i) in leftMenu"
          :key="i"
          @click="menuAction(item.action)"
          :class="{'Active':(activeLeftMenuItem == item.action)}"
          class="LeftMenuItem"
        >
          <v-list-tile-action>
              <v-icon slot="activator">
                {{item.icon}}
              </v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <div v-if="itemCounter(item.counter)" class="Counter">{{itemCounter(item.counter)}} new</div>
            <v-list-tile-title v-text="item.title"></v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar app style="z-index:999">
      <v-toolbar-title>
        <div @click="editTitle" style="cursor:pointer" class="Editable">
          {{gameName}}</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="rightDrawer = !rightDrawer" v-if="userLoggedIn">
        <v-icon>menu</v-icon>
      </v-btn>
    </v-toolbar>
    <v-content>
      <v-container fluid>
        <v-slide-y-transition mode="out-in">
          <v-layout column align-center>
            <router-view></router-view>
          </v-layout>
        </v-slide-y-transition>
      </v-container>
    </v-content>
    <v-navigation-drawer
        temporary
        :right="true"
        v-model="rightDrawer"
        fixed style="z-index:999">
      <v-list two-line>
        <v-list-tile
          v-for="(item, i) in computedRightMenu"
          :disabled="item.disabled"
          :key="i"
          @click="menuAction(item.action)"
        >
          <v-list-tile-action>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{item.title}}</v-list-tile-title>
            <v-list-tile-sub-title>{{item.subtitle}}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-footer class="pa-3">
      <v-spacer></v-spacer>
      <div>{{ getSiteVersion }} &copy; {{ new Date().getFullYear()
        }}</div>
    </v-footer>
    <ModalManager></ModalManager>
    <v-bottom-sheet v-model="showConsole">
      <div class="px-3 pt-3 pb-5 ConsoleDrawer" id="console">
        <div v-html="consoleData"></div>
        <div v-html="consoleCommand"></div>
      </div>
    </v-bottom-sheet>
    <v-snackbar
    v-model="snackbar.show"
    :color="snackbar.color"
    :timeout="snackbar.text.length*100"
    top>
      {{ snackbar.text }}
      <v-btn dark flat @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import bus from './bus';
import ModalManager from './components/ModalManager';

export default {
  components: {
    ModalManager,
  },
  data() {
    return {
      showConsole: false,
      activeLeftMenuItem: null,
      leftMenu: [
        {
          icon: 'settings',
          title: 'Settings',
          action: 'Settings',
        },
        {
          icon: 'widgets',
          title: 'Library',
          action: 'Library',
        },
        {
          icon: 'border_clear',
          title: 'Levels',
          action: 'LevelGroups',
        },
        {
          icon: 'offline_bolt',
          title: 'Generates',
          action: 'Generates',
          counter: 'newResultsCount',
        }],
      rightMenu: [
        {
          icon: 'account_circle',
          title: 'My account',
          action: 'ModalAccount',
          visible: 'always',
        },
        {
          icon: 'folder_open',
          title: 'Load / New Game',
          action: 'ModalGamesBrowse',
          visible: 'always',
        },
        {
          icon: 'cloud_download',
          title: 'Download Game',
          action: 'DownloadGame',
          visible: 'on-game-loaded',
        },
        {
          icon: 'call_to_action',
          title: 'Show console',
          action: 'ShowConsole',
          visible: 'always',
        },
        {
          icon: 'exit_to_app',
          title: 'Logout',
          action: 'Logout',
          visible: 'always',
        },
      ],
      rightDrawer: false,
      busEvents: [
        'saveGameAs',
        'saveGameTitle',
        'toggleConsole',
        'executeConsole',
        'consoleHistoryBack',
        'consoleHistoryForward',
      ],

    };
  },
  computed: {
    ...mapGetters([
      'serverData',
      'constants',
      'getSiteVersion',
      'internals',
    ]),
    userLoggedIn() {
      return this.serverData.getAuthToken.authenticationToken != null;
    },
    consoleCommand() {
      return this.internals.consoleCommand;
    },
    consoleData() {
      return this.internals.console.join('<br>');
    },
    game() {
      return this.serverData.getGame;
    },
    cssProps(state) {
      return {
        '--primary-color': this.$vuetify.theme.primary,
        '--secondary-color': this.$vuetify.theme.secondary,
        '--warn-color': this.$vuetify.theme.warn,
        '--card-color': this.$vuetify.theme.card,
      };
    },
    newResultsCount() {
      const filtered = this.serverData.getGenerateStatus.generateSummary.filter((el) => (el == 5 || el == 4));
      return filtered.length;
    },
    computedRightMenu() {
      const menuitems = [];
      const me = this;
      this.rightMenu.forEach(function(item) {
        if (me.menuItemVisible(item)) {
          item.disabled = false;
          if (item.action == 'DownloadGame' && me.lockedIn) {
            item.disabled = true;
            item.subtitle = 'upgrade your account';
          }
          menuitems.push(item);
        }
      });
      return menuitems;
    },
    gameName() {
      return this.globalGetProp(this.serverData, 'getGame.gameSettings.name', '');
    },
    snackbar() {
      return this.internals.snackbar;
    },
    lockedIn() {
      return this.serverData.getAccountInfo.accountInfo.internals.lockedGameSettings;
    },
  },
  created() {
    // Bus events shortcode APP
    this.busEvents.forEach((element) => {
      bus.$on('APP-'+element, eval('this.'+element));
    });
  },
  mounted() {
    // set first leftMenu item as active
    this.activeLeftMenuItem = this.leftMenu[0].action;
  },
  methods: {
    ...mapActions({
      logout: 'logout',
      postDataPromise: 'postDataPromise',
      saveData: 'saveData',
      showMessage: 'showMessage',
      spawnModal: 'spawnModal',
      getGenerateStatus: 'getGenerateStatus',
      pollUpdates: 'pollUpdates',
      consoleOutput: 'consoleOutput',
    }),
    itemCounter(counter) {
      return eval('this.'+counter);
    },
    menuItemVisible(item) {
      if (item.visible === 'on-game-loaded') {
        return this.game != null;
      }
      return item.visible === 'always';
    },
    menuAction(action) {
      this.rightDrawer = false;
      if (this.$route.path != '/'+action.toLowerCase()) {
        this.activeLeftMenuItem = action;
        switch (action) {
          // left menu
          case 'Settings':
            vueapp.$router.push({path: 'settings'});
            break;
          case 'Library':
            vueapp.$router.push({path: 'library'});
            break;
          case 'LevelGroups':
            vueapp.$router.push({path: 'levelgroups'});
            break;
          case 'Generates':
            vueapp.$router.push({path: 'generates'});
            break;
          // right menu
          case 'ShowConsole':
            this.toggleConsole();
            break;
          case 'ModalAccount':
            this.spawnModal({
              component: 'ModalAccount',
              title: 'My Account',
              closebutton: true,
            });
            break;
          case 'ModalGamesBrowse':
            this.spawnModal({
              component: 'ModalGamesBrowse',
              title: 'Select game to load',
              closebutton: true,
            });
            break;
          case 'ModalGameSave':
            this.spawnModal({
              component: 'ModalTextInput',
              title: 'Save current game as...',
              text: this.game.name,
              busevent: 'APP-saveGameAs',
            });
            break;
          case 'Logout':
            this.logout();
            break;
          case 'DownloadGame':
            this.postDataPromise({
              command: 'downloadGame',
              gameId: this.internals.currentGameId,
            }).then(this.offerDownloadFile);
            break;
        }
      }
    },
    editTitle() {
      this.spawnModal({
        component: 'ModalTextInput',
        title: 'Game title',
        label: 'Title',
        text: this.game.gameSettings.name,
        busevent: 'APP-saveGameTitle',
      });
    },
    // Bus event handlers
    toggleConsole() {
      this.showConsole = !this.showConsole;
    },
    executeConsole() {
      let command = this.internals.consoleCommand.split('');
      command.pop();
      command = command.join('');
      this.consoleOutput(command);
      this.internals.consoleCommand = '_';
      if (this.internals.consoleCommandHistory[this.internals.consoleCommandHistory.length-1] != command) {
        this.internals.consoleCommandHistory.unshift(command);
      }
      switch (command) {
        case 'getfailedjson':
          if (this.internals.JSONqueue.length == 0) {
            this.consoleOutput('NO FAILED JSON FOUND');
          } else {
            this.saveJSON(this.internals.JSONqueue[0].json);
          }
          break;
        case 'clearfailedjson':
          const len = this.internals.JSONqueue.length;
          if (len == 0) {
            this.consoleOutput('FAILED JSON QUEUE WAS ALREADY EMPTY');
          } else {
            this.internals.JSONqueue = [];
            this.consoleOutput('FAILED JSON QUEUE CLEARED ('+len+')');
          }
          break;
        default:
          this.consoleOutput('UNKNOWN COMMAND');
          break;
      }
    },
    saveJSON(data, filename) {
      if (!data) {
        console.error('No data');
        return;
      }
      if (!filename) filename = 'failed.json';
      if (typeof data === 'object') {
        data = JSON.stringify(data, undefined, 4);
      };
      const blob = new Blob([data], {type: 'text/json'});
      const e = document.createEvent('MouseEvents');
      const a = document.createElement('a');
      a.download = filename;
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    consoleHistoryBack() {
      this.internals.consoleHistoryIndex++;
      this.internals.consoleHistoryIndex =
        Math.min(this.internals.consoleCommandHistory.length-1, this.internals.consoleHistoryIndex);
      this.internals.consoleCommand = this.internals.consoleCommandHistory[this.internals.consoleHistoryIndex] + '_';
    },
    consoleHistoryForward() {
      this.internals.consoleHistoryIndex--;
      this.internals.consoleHistoryIndex = Math.max(0, this.internals.consoleHistoryIndex);
      this.internals.consoleCommand = this.internals.consoleCommandHistory[this.internals.consoleHistoryIndex] + '_';
    },
    offerDownloadFile(response) {
      if (response.headers.hasOwnProperty('content-disposition')) {
        const disposition = response.headers['content-disposition'];
        let filename = 'download.zip';
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;base64,' + response.data);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } else {
        this.showMessage({type: 'error', text: 'Cannot get Content-Disposition header.'});
      }
    },
    saveGameAs(params) {
      const newGameId = Math.round(Math.random() * 2147483647);
      const newGameName = params.value;
      const me = this;
      this.postDataPromise({
        command: 'copyGame',
        gameId: this.internals.currentGameId,
        newGameId: newGameId,
        name: newGameName,
      }).then(function() {
        me.showMessage({type: 'success', text: 'Game copy success.'});
      });
    },
    saveGameTitle(obj) {
      this.game.gameSettings.name = obj.value;
      this.saveData({type: 'game-settings'});
    },
  },
  destroyed() {
    this.busEvents.forEach((element) => {
      bus.$off('APP-'+element);
    });
  },
};
</script>

<style>
#app {
  user-select: none;
}
.Counter{
  position: absolute;
  background-color: #4CAF50;
  left: 33px;
  padding: 0px 4px;
  border-radius: 12px;
  font-size: 9px;
  top: 10px;
}
.Editable:hover {
  text-decoration: underline;
  cursor: pointer;
}
.ConsoleDrawer{
  background-color: black;
  color: green;
  font-family: monospace;
  overflow-y: auto;
  height: 200px;
}
.v-chip .v-chip__content {
  z-index: 0;
}
.ModalBorder{
  border:solid 1px white;
}
.CardShadow{
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.LeftMenuItem.Active{
  background-color:var(--primary-color);
}
.EmptyCard.v-card.v-sheet.theme--dark {
    border: dashed 2px white;
    height: 100%;
    display: flex;
    color: white;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
}
</style>
