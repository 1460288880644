<template>
  <v-container fluid :class="['GridSizeH'+levelData.horSize[0]+' GridSizeV'+levelData.vertSize[0]]">
    <v-layout align-center justify-center>
      <v-flex>
        <v-card>
          <v-toolbar dark color="secondary">
            <div class="text-xs-center">
              <v-menu offset-y>
                <v-btn slot="activator" icon>
                  <v-icon>menu</v-icon>
                </v-btn>
                <v-list>
                  <v-list-tile @click="clearGrid">
                    <v-list-tile-action>
                        <v-icon slot="activator">clear</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Clear grid</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile @click="copyLevel">
                    <v-list-tile-action>
                        <v-icon slot="activator">content_copy</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Copy level</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile @click="pasteLevel" :disabled="pasteDisabled">
                    <v-list-tile-action>
                        <v-icon slot="activator">content_paste</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Paste level</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile @click="askDeleteLevel">
                    <v-list-tile-action>
                        <v-icon slot="activator">delete_forever</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Delete level</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </div>
            <v-toolbar-title class="levelName">
              level {{game.gameSettings.levelNumberingStartsAt + modal.level.displayId}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
              <v-btn color="#BF5B16"
              @click="forwardtest"
              :disabled="generateLocked || itemGenerateInfo(modal.level).descr == 'generating'">Forward Test</v-btn>
              <GenerateButton
                :info="itemGenerateInfo(modal.level)"
                class="mr-5"
                :canGenerate="true"
                :resultLink="true"
                @generate="generate"
                @openResult="openResult"
              ></GenerateButton>
              <v-btn v-if="hasFailedResult" icon @click="showFailedResultMessage" color="orange">
                <v-icon color="white">warning</v-icon>
              </v-btn>
            <div class="pr-4">
              <v-btn icon v-if="levelLocked" @click="lockClick" color="red">
                <v-icon color="white">stop</v-icon>
              </v-btn>
            </div>
            <v-btn icon @click="updateAndClose">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text v-if="loaded" :class="{'editLocked': levelLocked}">
            <v-layout row class="ModalContent">
              <v-flex pa-2 class="LeftCol">
                <div class="CardShadow">
                  <div class="panelBox">
                    <v-switch
                    label="Custom difficulty"
                    v-model="enableCustomDifficulty"
                  ></v-switch>
                    <div v-if="enableCustomDifficulty">
                      Difficulty: {{levelData.customDifficulty}}
                      <v-slider
                        @change="setCustomDifficultyValue"
                        v-model="levelData.customDifficulty"
                        color="white"
                        :min="10"
                        :max="90"
                      ></v-slider>
                    </div>
                    <v-switch
                      label="No more inaccessibles"
                      v-model="levelData.noMoreInaccessibles"
                      @change="saveLevel"
                    ></v-switch>
                    <v-switch
                    label="Custom level settings"
                    v-model="levelData.customLevelSettings"
                    @change="saveLevel"
                    ></v-switch>
                  </div>
                  <div v-if="levelData.customLevelSettings">
                    <div class="panelBox">
                      <div>Grid size horizontal:
                        {{levelData.horSize[0]}} - {{levelData.horSize[1]}}</div>
                      <v-range-slider v-model="levelData.horSize" color="white" min="7" max="12"
                      @change="saveLevel">
                      </v-range-slider>
                      <div>Grid size vertical:
                        {{levelData.vertSize[0]}} - {{levelData.vertSize[1]}}</div>
                      <v-range-slider v-model="levelData.vertSize" color="white" min="7" max="12"
                      @change="saveLevel">
                      </v-range-slider>
                      <div>Colors: {{levelData.colors[0]}} - {{levelData.colors[1]}}</div>
                      <v-range-slider v-model="levelData.colors" color="white" min="4" max="9" @change="saveLevel">
                      </v-range-slider>
                    </div>
                    <div class="TriggerItems">
                      <div class="SectionName">Lose condition</div>
                      <div class="panelBox">
                        <div>Turns: {{levelData.loseTurns[0]}} - {{levelData.loseTurns[1]}}</div>
                        <v-range-slider v-model="levelData.loseTurns" color="white" min="5" max="40"
                        @change="saveLevel">
                        </v-range-slider>
                      </div>
                      <div class="SectionName">Win conditions</div>
                        <div class="panelBox">
                        <div>Win conditions: {{levelData.winConditions[0]}} - {{levelData.winConditions[1]}}</div>
                        <v-range-slider v-model="levelData.winConditions" color="white" min="1" max="4"
                        @change="saveLevel">
                        </v-range-slider>
                        <v-card
                        @click="addWinCondition"
                        class="EmptyCard AddTrigger"
                        v-if="levelData.triggerItems.length < 20">
                          <v-icon>add_circle</v-icon>
                        </v-card>
                        <v-alert value="true" type="warning" v-else>Maximum win conditions reached</v-alert>
                        <div v-for="(item, index) in levelData.triggerItems"
                        :key="'trigger-item-row'+index"
                        class="TriggerButton v-card"
                        @click="editTriggerModal(item, index)">
                          <GridItem
                          v-if="triggerIcon(item)"
                          :items="triggerIcon(item)"
                          :key="'tic-'+index"
                          :size="30"/>
                          <div class="Name">{{item.name}}</div>
                          <div class="AlwaysUse">
                            <v-icon v-if="item.alwaysUse">task_alt</v-icon>
                          </div>
                        </div>
                      </div>
                      <div class="SectionName">Blocking elements</div>
                        <div class="panelBox">
                        <div>
                          Blocking elements: {{levelData.blockingElements[0]}} - {{levelData.blockingElements[1]}}
                        </div>
                        <v-range-slider
                        v-model="levelData.blockingElements"
                        color="white"
                        min="0"
                        max="4"
                        @change="saveLevel">
                        </v-range-slider>
                        <v-card
                        @click="addBlockingElement"
                        class="EmptyCard AddTrigger"
                        v-if="levelData.blockingItems.length < 20">
                          <v-icon>add_circle</v-icon>
                        </v-card>
                        <v-alert value="true" type="warning" v-else>Maximum blocking elements reached</v-alert>
                        <div v-for="(item, index) in levelData.blockingItems"
                        :key="'trigger-item-row'+index"
                        class="TriggerButton v-card"
                        @click="editBlockingElementModal(item, index)">
                          <GridItem
                          v-if="blockerIcon(item)"
                          :items="blockerIcon(item)"
                          :key="'tic-'+index"
                          :size="30"/>
                          <div class="Name">{{blockerName(item)}}</div>
                          <div class="AlwaysUse">
                            <v-icon v-if="item.alwaysUse">task_alt</v-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-flex>
              <v-flex pa-2 style="flex:1 1 100%;overflow:hidden">
                <div class="Grid" id="the-grid">
                  <div class="Row" v-for="row in levelData.vertSize[0]" :key="'grid-row-'+row">
                    <div
                        class="Block"
                        v-for="col in levelData.horSize[0]"
                        :key="'grid-row-'+row+'-col-'+col"
                        @mousedown="setGridItem({row:row, col:col, item:activegridelement})"
                        @mouseover="hoverGridElement({row:row, col:col, item:activegridelement})"
                        @mouseup="mouseUp"
                    >
                      <GridItem
                          :classes="'SolidBorder'"
                          :items="gridItemContents(row, col)"
                          :key="'griditem-'+row+'-'+col+'-'+griditemkeys[(col-1)+((row-1)*levelData.horSize[0])]"
                          :inaccessible="isInaccessible(row, col)"
                          style="pointer-events:none"
                      />
                    </div>
                  </div>
                </div>
              </v-flex>
              <v-flex pa-2 class="RightCol">
                <v-flex style="height:100%;display:flex;flex-direction:column">
                  <div class="ItemProps">
                    <div v-if="activegridelement.type == 'foreground'">
                      <div style="display:flex">
                        <div style="display:flex;flex-direction:column; flex:0 0 41px"
                        v-if="activegridelement.hasColor">
                          <div>Color</div>
                          <div @click="setFgColor" class="fgColor" :style="bkgColorStyle(currentFgColor)"
                          ></div>
                        </div>
                        <div style="display:flex;flex-direction:column;"
                        v-if="activegridelement.hasTimer">
                          <div>Timer {{minTimer}} - {{maxTimer}}</div>
                          <v-range-slider v-model="timerValues" color="white" min="1" max="30">
                          </v-range-slider>
                        </div>
                      </div>
                    </div>
                    <div v-if="activegridelement.type == 'background'">
                      <div style="display:flex">
                        <div style="display:flex;flex-direction:column; flex:0 0 41px">
                          <div>Layers</div>
                          <div @click="setBgLayers" class="bgLayers"
                          :style="{opacity:(activegridelement.maxLayers==1?.3:1)}">{{currentBgLayers}}</div>
                        </div>
                      </div>
                      </div>
                      <div v-if="activegridelement.type != 'background' &&
                    activegridelement.type != 'foreground'"><b>Library Items</b></div>
                  </div>
                  <div class="GridItems">
                    <div v-for="(item, index) in gridItems" :key="'grid-item-row'+index+'-'+gridItemsRefresh">
                      <div v-if="item.type=='section'" class="SectionName">{{item.name}}</div>
                      <div v-else
                      :class="['GridItemRow',(activegridelement.id == item.id &&
                      activegridelement.type == item.type) ? 'Active' : '']"
                      @click="setActiveGridElement(item)"
                      >
                      <GridItem :items="[item]" :size="30" :inaccessible="item.type=='background' && item.id==1"/>
                      <div class="ItemName">{{item.name}}</div>
                    </div>
                    </div>
                  </div>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text v-else>
            <v-progress-linear indeterminate></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import bus from '../bus';
import GridItem from './GridItem.vue';
import GenerateButton from './GenerateButton.vue';
import {mapGetters, mapActions} from 'vuex';
import * as htmlToImage from 'html-to-image';

export default {
  components: {
    GridItem,
    GenerateButton,
  },
  props: {
    modal: {
      type: Object,
    },
  },
  data() {
    return {
      loaded: false,
      grid: [],
      activegridelement: false,
      griditemkeys: [],
      generateLocked: false,
      gridItemsRefresh: 0,
      currentFgColor: '',
      currentBgLayers: 1,
      generateStatusMessage: '',
      gridItems: [],
      busEvents: [
        'resetLevel',
        'cancelGenerate',
        'removeLevels',
        'unlockLevel',
        'saveTrigger',
        'saveBlockingElement',
        'deleteTrigger',
        'deleteBlockingElement',
      ],
      mouseIsDown: false,
      levelData: {
        customDifficulty: 0,
        enableCustomDifficulty: false,
        noMoreInaccessibles: false,
        customLevelSettings: false,
        horSize: [0, 0],
        vertSize: [0, 0],
        colors: [0, 0],
        loseTurns: [0, 0],
        loseConditionName: '',
        winConditions: [0, 0],
        blockingElements: [0, 0],
        triggerItems: [],
        blockingItems: [],
      },
      minTimer: 4,
      maxTimer: 15,
      resultChecker: null,
    };
  },
  created() {
    // Bus events shortcode MLE
    this.busEvents.forEach((element) => {
      bus.$on('MLE-'+element, eval('this.'+element));
    });
    this.loaded = false;
    this.getLevel({levelId: this.modal.level.levelId}).then(this.init);
    window.addEventListener('mousedown', this.mouseDown);
    window.addEventListener('mouseup', this.mouseUp);
    this.getGenerateStatus();
  },
  computed: {
    ...mapGetters([
      'constants',
      'internals',
      'serverData',
    ]),
    timerValues: {
      get() {
        return [this.minTimer, this.maxTimer];
      },
      set(val) {
        this.minTimer = val[0];
        this.maxTimer = val[1];
        this.activegridelement.minTimer = val[0];
        this.activegridelement.maxTimer = val[1];
      },
    },
    enableCustomDifficulty: {
      get() {
        return this.levelData.enableCustomDifficulty;
      },
      set(val) {
        this.setCustomDifficulty(val);
      },
    },
    highestLevelId() {
      const numGroups = this.serverData.getGroupsSummary.groupsSummary.length;
      return this.serverData.getGroupsSummary.groupsSummary[numGroups-1].highestLevelId;
    },
    groupLevelSettings() {
      return this.serverData.getGroup.levelSettings;
    },
    serverLevel() {
      return this.serverData.getLevel;
    },
    game() {
      return this.serverData.getGame;
    },
    levelLocked() {
      if (this.generateLocked) {
        return true;
      }
      if (!this.serverLevel) {
        return true;
      }
      if (this.globalGetProp(this.serverLevel.grid, 'lockResult', false)) {
        return true;
      }
      const info = this.itemGenerateInfo(this.modal.level);
      return info.id > 0;
    },
    getPendingRenders() {
      return this.serverData.getGenerateStatus.generateSummary;
    },
    library() {
      return this.serverData.getGame.gameLibrary;
    },
    hasFailedResult() {
      return this.serverData.getGenerateStatus.generateSummary[this.modal.level.displayId-1] == 4;
    },
    pasteDisabled() {
      return this.internals.clipBoard.levelData == null;
    },
  },
  methods: {
    ...mapActions({
      postDataPromise: 'postDataPromise',
      generateLevel: 'generateLevel',
      generateForwardTest: 'generateForwardTest',
      getFailedMessage: 'getFailedMessage',
      getGroup: 'getGroup',
      getGroupsSummary: 'getGroupsSummary',
      getLevel: 'getLevel',
      showMessage: 'showMessage',
      closeModal: 'closeModal',
      spawnModal: 'spawnModal',
      getResult: 'getResult',
      getGenerateStatus: 'getGenerateStatus',
    }),
    init() {
      // basic level settings
      this.levelData.enableCustomDifficulty = this.serverLevel.grid.enableCustomDifficulty;
      this.levelData.customDifficulty = this.serverLevel.grid.customDifficulty;
      this.levelData.noMoreInaccessibles = this.serverLevel.grid.noMoreInaccessibles;
      this.levelData.customLevelSettings = this.serverLevel.grid.customLevelSettings;
      // custom level settings
      this.loadLevelSettings();
      // grid item keys for refreshing
      this.griditemkeys = [];
      for (let i = 0; i < (this.levelData.horSize[0] * this.levelData.vertSize[0]); i++) {
        this.griditemkeys.push(0);
      }
      // generate gridItems list
      // section specials, tools
      let i;
      this.gridItems = [];
      this.gridItems.push({type: 'section', name: 'Specials'});
      this.gridItems.push({icon: 1, id: 1, name: 'Eraser', type: 'eraser'});
      this.gridItems.push({
        icon: 0,
        id: 2,
        name: 'Spawnpoint',
        type: 'spawnpoint',
      });
      const libraryClone = this.globalJSONCopy(this.library);
      // section backgrounds
      this.gridItems.push({type: 'section', name: 'Backgrounds'});
      let item;
      for (i = 0; i < libraryClone.backgrounds.length; i++) {
        item = libraryClone.backgrounds[i];
        item.type = 'background';
        let layers = 1;
        if (item.type == 'background' && this.activegridelement && item.id == this.activegridelement.id) {
          layers = this.currentBgLayers;
        }
        item.layers = layers;
        this.gridItems.push(item);
      }
      // section foregrounds
      this.gridItems.push({type: 'section', name: 'Foregrounds'});
      for (i = 0; i < this.serverLevel.foregroundsForGrid.length; i++) {
        const foregroundId = this.serverLevel.foregroundsForGrid[i];
        item = libraryClone.foregrounds.find((el) => el.id == foregroundId);
        item.type = 'foreground';
        item.color = item.hasColor ? 1 : 0;
        item.minTimer = 4;
        item.maxTimer = 15;
        this.gridItems.push(item);
      }
      if (this.serverLevel.grid.hasOwnProperty('startingState')) {
        this.grid = this.convertBoardServerToLocal(this.serverLevel.grid.startingState);
      }
      this.checkResult();
      // init resultchecker
      const info = this.itemGenerateInfo(this.modal.level);
      if (info.id == 1 || info.id == 2) {
        this.initResultChecker();
      }
      this.loaded = true;
      // setTimeout(this.savePNG, 1000);
    },
    loadLevelSettings() {
      const mgls = this.groupLevelSettings;
      this.levelData.horSize[0] = this.loadSetting('minHorSize', mgls.minHorSize);
      this.levelData.horSize[1] = this.loadSetting('maxHorSize', mgls.maxHorSize);
      this.levelData.vertSize[0] = this.loadSetting('minVertSize', mgls.minVertSize);
      this.levelData.vertSize[1] = this.loadSetting('maxVertSize', mgls.maxVertSize);
      this.levelData.colors[0] = this.loadSetting('minNrOfColors', mgls.minNrOfColors);
      this.levelData.colors[1] = this.loadSetting('maxNrOfColors', mgls.maxNrOfColors);
      let levelSettings = this.globalJSONCopy(this.groupLevelSettings);
      if (this.levelData.customLevelSettings && this.serverLevel.grid.hasOwnProperty('levelSettings')) {
        levelSettings = this.globalJSONCopy(this.serverLevel.grid.levelSettings);
      }
      const loseTrigger = levelSettings.triggers.find((el) => el.consequence === 1);
      this.levelData.loseTurns[0] = loseTrigger.conditions[0].minSetting;
      this.levelData.loseTurns[1] = loseTrigger.conditions[0].maxSetting;
      this.levelData.loseConditionName = loseTrigger.conditions[0].name;
      const winTrigger = levelSettings.triggers.find((el) => el.consequence === 0);
      this.levelData.winConditions[0] = winTrigger.minWinCons;
      this.levelData.winConditions[1] = winTrigger.maxWinCons;
      this.levelData.blockingElements[0] = levelSettings.minBlockingElements;
      this.levelData.blockingElements[1] = levelSettings.maxBlockingElements;
      this.levelData.triggerItems = winTrigger.conditions;
      this.levelData.blockingItems = levelSettings.blockingElements;
    },
    loadSetting(setting, altValue) {
      if (this.levelData.customLevelSettings && this.serverLevel.grid.hasOwnProperty('levelSettings')) {
        return (this.globalGetProp(this.serverLevel.grid.levelSettings, setting, altValue));
      }
      return altValue;
    },
    // BOARD
    clearGrid() {
      this.serverLevel.grid.startingState = [];
      this.grid = [];
      this.griditemkeys = [];
      for (let i = 0; i < (this.levelData.horSize[0] * this.levelData.vertSize[0]); i++) {
        this.griditemkeys.push(0);
      }
      this.saveLevel();
    },
    gridItemContents(row, col) {
      const levelgrid = this.grid;
      if (levelgrid) {
        const itemIndex = levelgrid.findIndex((elm) => {
          return elm.id === row + '-' + col;
        });
        if (itemIndex > -1) {
          return levelgrid[itemIndex].items;
        }
      }
      return [];
    },
    isInaccessible(row, col) {
      const levelgrid = this.grid;
      if (levelgrid) {
        const itemIndex = levelgrid.findIndex((elm) => {
          return elm.id === row + '-' + col;
        });
        if (itemIndex > -1) {
          return levelgrid[itemIndex].items.some((el) => el.type == 'background' && el.id == 1);
        }
      }
      return false;
    },
    backgroundPosition(item) {
      const id = item.icon;
      const type = item.type;
      let offset = 0;
      switch (type) {
        case 'entrypoint':
          offset = -1200;
          break;
        case 'background':
          offset = -600;
          break;
      }
      const x = offset - ((id * 60) % 600);
      const y = -660 - Math.floor(id / 10) * 60;
      return x + 'px ' + y + 'px';
    },
    setGridItem(data) {
      if (data.item) {
        const cloneBaby = this.globalJSONCopy(data.item);
        // increment grid block key to force vue refresh
        const i = (data.col - 1) + ((data.row - 1) * this.levelData.horSize[0]);
        const n = this.griditemkeys[i] + 1;
        this.griditemkeys.splice(i, 1, n);
        // find grid block
        const levelgrid = this.grid;
        const id = data.row + '-' + data.col;
        const blockindex = levelgrid.findIndex((elm) => {
          return elm.id === id;
        });
        if (data.item.type !== 'eraser') {
          // check if there is something there already
          if (blockindex > -1) {
            // something seems to be there already,
            // check if it's type is the same
            const itemtypeindex =
              levelgrid[blockindex].items.findIndex((elm) => {
                return elm.type === data.item.type;
              });
            // existing item is the same, remove existing
            if (itemtypeindex > -1) {
              levelgrid[blockindex].items.splice(itemtypeindex, 1);
            }
            // add me
            levelgrid[blockindex].items.push(cloneBaby);
            // re order block layers
            levelgrid[blockindex].items.sort((a, b) => {
              const sortorder = ['spawnpoint', 'background', 'foreground'];
              const ai = sortorder.indexOf(a.type);
              const bi = sortorder.indexOf(b.type);
              if (ai < bi) {
                return -1;
              }
              if (ai > bi) {
                return 1;
              }
              return 0;
            });
          } else {
            // nothing there yet, add me
            levelgrid.push({id: id, items: [cloneBaby]});
          }
        } else {
          // used eraser
          if (blockindex > -1) {
            levelgrid.splice(blockindex, 1);
          }
        }
        this.saveLevel();
      }
    },
    convertBoardLocalToServer() {
      // Deep clone current level state
      const serverGrid = [];
      for (let i = this.grid.length-1; i >= 0; i--) {
        const data = this.grid[i];
        if (!data) continue;
        // Currently expects a string "{row}-{col}"
        const parts = data.id.split('-');
        // Flip y as back-end uses bottom-left
        // while front end uses top-left as (1, 1)
        // Y is 0 based by flipping
        const y = this.levelData.vertSize[0] - parseInt(parts[0]);
        // Make x 0-based
        const x = parseInt(parts[1]) - 1;
        // Items is an array of items at the current position
        const newItem = {x, y};
        data.items.forEach((item) => {
          switch (item.type) {
            case 'background':
              newItem.setBackground = {
                background: item.id,
                layers: item.layers,
              };
              break;
            case 'foreground':
              newItem.setForeground = {
                foreground: item.id,
                color: item.color,
                minTimer: item.minTimer,
                maxTimer: item.maxTimer,
              };
              break;
            case 'spawnpoint':
              newItem.setSpawnPoint = true;
              break;
            default:
              console.error('type ' + item.type + ' is not implemented');
              break;
          }
        });
        // Add server item to board array
        serverGrid.push(newItem);
      }
      // Return board
      return serverGrid;
    },
    convertBoardServerToLocal(param) {
      const serverBoard = this.globalJSONCopy(param);
      const board = [];
      // This is currently fixed
      for (let i = serverBoard.length-1; i >= 0; i--) {
        const data = serverBoard[i];
        if (!data) continue;
        let x = data.x;
        let y = data.y;
        // Flip y
        y = this.levelData.vertSize[0] - y;
        // Make values start at 1
        x += 1;
        // y is already adjusted in the flip
        const id = `${y}-${x}`;
        // Convert data to actual local items
        let items = [];
        // Check if id is already used
        let isNewId = true;
        const existingData = board.filter((x) => x.id === id);
        if (existingData.length > 0) {
          // Save data in existing item array
          items = existingData[0].items;
          isNewId = false;
        }
        // Background value
        if ('setBackground' in data) {
          const item = {};
          // Get background from library
          const backgroundIndex = this.library.backgrounds
              .findIndex((background) => {
                return data.setBackground.background === background.id;
              });
          const libraryItem = this.library.backgrounds[backgroundIndex];
          item['type'] = 'background';
          item['layers'] = data.setBackground.layers;
          item['id'] = libraryItem.id;
          item['icon'] = libraryItem.icon;
          item['name'] = libraryItem.name;
          item['hasForeground'] = libraryItem.hasForeground;
          item['maxLayers'] = libraryItem.maxLayers;
          items.push(item);
        }
        if ('setForeground' in data) {
          // Foreground values
          const item = {};
          // Get foreground from library
          const foregroundIndex = this.library.foregrounds
              .findIndex((foreground) => {
                return data.setForeground.foreground === foreground.id;
              });
          const libraryItem = this.library.foregrounds[foregroundIndex];
          item['type'] = 'foreground';
          item['hasColor'] = libraryItem.hasColor;
          item['color'] = data.setForeground.color;
          item['hasTimer'] = libraryItem.hasTimer;
          item['minTimer'] = data.setForeground.minTimer;
          item['maxTimer'] = data.setForeground.maxTimer;
          item['id'] = libraryItem.id;
          item['icon'] = libraryItem.icon;
          item['name'] = libraryItem.name;
          items.push(item);
        }
        if ('setSpawnPoint' in data) {
          // SpawnPoint value
          const item = {};
          // Currently only support
          // for a static type of spawnPoint
          item['type'] = 'spawnpoint';
          item['name'] = 'Spawnpoint';
          item['icon'] = 0;
          item['id'] = 2;
          items.push(item);
        }
        if (isNewId) {
          board.push({id, 'items': items});
        }
      }
      // Flip all items to match original order
      board.forEach((values) => values.items.reverse());
      return board;
    },
    // RIGHT SIDE TOOLBAR
    setActiveGridElement(item) {
      this.activegridelement = item;
      if (this.activegridelement.type == 'foreground') {
        // set color
        if (item.hasColor) {
          this.currentFgColor = item.color;
        }
        if (item.hasTimer) {
          this.minTimer = this.globalGetProp(item, 'minTimer', 4);
          this.maxTimer = this.globalGetProp(item, 'maxTimer', 15);
        }
      }
      if (this.activegridelement.type == 'background') {
        // set layers
        this.currentBgLayers = item.layers;
      }
    },
    setFgColor() {
      let nextIndex = this.currentFgColor + 1;
      if (nextIndex == 0) {
        nextIndex = 1;
      }
      if (nextIndex > 4) {
        nextIndex = -1;
      }
      this.currentFgColor = nextIndex;
      this.activegridelement.color = nextIndex;
      this.gridItemsRefresh++;
    },
    bkgColorStyle(id) {
      const style = {backgroundColor: ''};
      if (id > 0) {
        style.backgroundColor = this.constants.fgColors[id];
      }
      if (id == -1) {
        let colorStr = 'linear-gradient(135deg, ';
        colorStr += 'red 0%, ';
        colorStr += 'orange 30%, ';
        colorStr += 'yellow 40%, ';
        colorStr += 'green 50%, ';
        colorStr += 'blue 60%, ';
        colorStr += 'indigo 70%, ';
        colorStr += 'violet 100%';
        colorStr += ')';
        style.background = colorStr;
      }
      return style;
    },
    setBgLayers() {
      const nextLayer = this.currentBgLayers + 1;
      const setLayer = nextLayer > this.activegridelement.maxLayers ? 1 : nextLayer;
      this.currentBgLayers = setLayer;
      this.activegridelement.layers = setLayer;
      this.gridItemsRefresh++;
    },
    // SERVER COMMUNICATION
    setCustomDifficulty(val) {
      this.levelData.enableCustomDifficulty = val;
      const postMe = {
        command: 'enableCustomDifficulty',
        gameId: this.internals.currentGameId,
        groupId: this.internals.currentGroupId,
        levelId: this.modal.level.levelId,
        enableCustomDifficulty: this.levelData.enableCustomDifficulty,
      };
      // Add then
      this.postDataPromise(postMe).then(() => {}, () => {
        // get level again if failed
        this.getLevel({levelId: this.modal.level.levelId}).then(this.init);
      });
    },
    setCustomDifficultyValue(val) {
      this.levelData.customDifficulty = val;
      const postMe = {
        command: 'setCustomDifficulty',
        gameId: this.internals.currentGameId,
        groupId: this.internals.currentGroupId,
        levelId: this.modal.level.levelId,
        customDifficulty: this.levelData.customDifficulty,
      };
      // Add then
      this.postDataPromise(postMe).then(() => {}, () => {
        // get level again if failed
        this.getLevel({levelId: this.modal.level.levelId}).then(this.init);
      });
    },
    saveLevel() {
      // this.savePNG();
      const gridData = {};
      gridData.customDifficulty = this.levelData.customDifficulty;
      gridData.noMoreInaccessibles = this.levelData.noMoreInaccessibles;
      gridData.customLevelSettings = this.levelData.customLevelSettings;
      if (this.levelData.customLevelSettings) {
        gridData.levelSettings = {
          name: '',
          lockedIn: false,
          minHorSize: this.levelData.horSize[0],
          maxHorSize: this.levelData.horSize[1],
          minVertSize: this.levelData.vertSize[0],
          maxVertSize: this.levelData.vertSize[1],
          minNrOfColors: this.levelData.colors[0],
          maxNrOfColors: this.levelData.colors[1],
          triggers: [
            {conditions: [{
              conditionType: 3,
              minSetting: this.levelData.loseTurns[0],
              maxSetting: this.levelData.loseTurns[1],
              name: this.levelData.loseConditionName,
            }],
            consequence: 1},
            {conditions: this.levelData.triggerItems,
              consequence: 0,
              minWinCons: this.levelData.winConditions[0],
              maxWinCons: this.levelData.winConditions[1],
            },
          ],
          minBlockingElements: this.levelData.blockingElements[0],
          maxBlockingElements: this.levelData.blockingElements[1],
          blockingElements: this.levelData.blockingItems,
        };
      };
      gridData.startingState = this.convertBoardLocalToServer();
      gridData.lockResult = false;
      if (gridData.startingState.length == 0) {
        delete gridData.startingState;
      }
      const postMe = {
        command: 'setLevel',
        gameId: this.internals.currentGameId,
        groupId: this.internals.currentGroupId,
        levelId: this.modal.level.levelId,
        grid: gridData,
      };
      // Add then
      this.postDataPromise(postMe).then(() => {}, () => {
        // get level again if failed
        this.getLevel({levelId: this.modal.level.levelId}).then(this.init);
      });
    },
    generate() {
      this.generateLocked = true;
      const me = this;
      setTimeout(() => me.generateLocked = false, 10000);
      this.generateLevel({
        levelId: this.modal.level.levelId,
      });
      this.getGenerateStatus(true);
      this.initResultChecker();
    },
    forwardtest() {
      this.generateLocked = true;
      const me = this;
      setTimeout(() => me.generateLocked = false, 10000);
      this.generateForwardTest({
        levelId: this.modal.level.levelId,
      });
      this.getGenerateStatus(true);
      this.initResultChecker();
    },
    initResultChecker() {
      const me = this;
      this.resultChecker = setInterval(() => {
        const info = me.itemGenerateInfo(me.modal.level);
        if (info.id == 3 || info.id == 5) {
          me.openResult();
        }
      }, 1000);
    },
    checkResult() {
      const me = this;
      this.getResult({levelId: this.modal.level.levelId}).then(function() {
        const data = me.serverData.getResult;
        if ('status' in data) {
          me.generateStatusMessage = data.message;
        }
      });
    },
    // MENU ACTIONS
    copyLevel() {
      this.internals.clipBoard.levelData = this.globalJSONCopy(this.serverData.getLevel);
      this.showMessage({
        type: 'success',
        text: 'Level copied to clipboard.',
      });
    },
    pasteLevel() {
      const originalDifficulty = this.serverData.getLevel.grid.customDifficulty;
      this.serverData.getLevel = this.globalJSONCopy(this.internals.clipBoard.levelData);
      this.serverData.getLevel.grid.customDifficulty = originalDifficulty;
      this.init();
      this.saveLevel();
      this.showMessage({
        type: 'success',
        text: 'Level pasted from clipboard.',
      });
    },
    askDeleteLevel() {
      this.spawnModal({
        component: 'ModalConfirm',
        title: 'Confirm level delete.',
        text: 'Are you sure you want to DELETE this level?',
        closebutton: true,
        busevent: 'MLE-removeLevels',
        subject: this.modal.level.levelId,
      });
    },
    // MODALS
    showFailedResultMessage() {
      this.getFailedMessage({levelId: this.modal.level.levelId}).then(() => {
        this.spawnModal({
          component: 'ModalMessage',
          title: 'Failed result',
          text: this.serverData.getFailedMessage.failedMessage,
        });
      });
    },
    removeLevels() {
      const me = this;
      this.postDataPromise({
        command: 'removeLevel',
        groupId: this.internals.currentGroupId,
        levelId: this.modal.level.levelId,
      }).then(function() {
        bus.$emit('MGE-updateGroupData');
        bus.$emit('LGS-getGroups');
        me.showMessage({
          type: 'success',
          text: 'Level deleted!',
        });
        me.closeModal(me.modal.id);
      });
    },
    openResult() {
      clearInterval(this.resultChecker);
      this.spawnModal({
        component: 'ModalLevelResult',
        title: 'Result for Level ' + (this.game.gameSettings.levelNumberingStartsAt + this.modal.level.displayId),
        closebutton: true,
        levelId: this.modal.level.levelId,
      });
    },
    // GENERAL
    itemGenerateInfo(item) {
      if (this.generateLocked) {
        return this.constants.generateStates.find((el) => el.id == 1);
      }
      let statusCode = this.serverData.getGenerateStatus.generateSummary[item.displayId-1];
      if (statusCode == 4) {
        statusCode = 0;
      }
      return this.constants.generateStates.find((el) => el.id == statusCode);
    },
    lockClick() {
      const info = this.itemGenerateInfo(this.modal.level);
      if (info.id == 1) {
        this.spawnModal({
          component: 'ModalConfirm',
          title: 'Unlock level',
          text: 'Unlocking this level will clear the result. Proceed?',
          closebutton: true,
          busevent: 'MLE-unlockLevel',
        });
      } else {
        this.spawnModal({
          component: 'ModalConfirm',
          title: 'Unlock level',
          text: 'Unlocking this level will cancel current pending generate. Proceed?',
          closebutton: true,
          busevent: 'MLE-cancelGenerate',
        });
      }
    },
    cancelGenerate() {
      this.postDataPromise({
        command: 'cancelGenerate',
        gameId: this.internals.currentGameId,
        groupId: this.internals.currentGroupId,
        levelId: this.modal.level.levelId,
      });
    },
    unlockLevel() {
      this.saveLevel();
    },
    savePNG() {
      const node = document.getElementById('the-grid');
      const me = this;
      htmlToImage.toPng(node).then(function(dataUrl) {
        fetch('https://cdn.basisam.nl/images/pyzomath/upload.php', {
          method: 'POST',
          cors: 'no-cors',
          body: 'image=' + encodeURIComponent(dataUrl),
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
        }).then(function(response) {
          return response.json();
        }).then(function(data) {
          const image = data.image;
          me.internals.levelImages[me.modal.level.displayId] = image;
        }).catch(function(error) {
          console.warn('Image upload error:', error);
        });
      }).catch(function(error) {
        console.error('image to png error: ', error);
      });
    },
    mouseDown() {
      this.mouseIsDown = true;
    },
    mouseUp() {
      this.mouseIsDown = false;
    },
    hoverGridElement(item) {
      if (this.mouseIsDown) {
        this.setGridItem(item);
      }
    },
    // TRIGGERS
    addWinCondition() {
      this.editTriggerModal({}, -1);
    },
    addBlockingElement() {
      this.editBlockingElementModal({}, -1);
    },
    editTriggerModal(trigger, index) {
      const modalParams = {
        component: 'ModalTriggerConditions',
        title: 'Edit win condition',
        index: index,
        conditiondata: trigger,
        saveEvent: 'MLE-saveTrigger',
        deleteEvent: 'MLE-deleteTrigger',
      };
      if (index == -1) {
        modalParams.title = 'New trigger';
        modalParams.deleteDisabled = true;
        modalParams.conditiondata = {
          alwaysUse: false,
          asWinCon: 0,
          conditionType: 0,
          name: 'New trigger',
          elements: [],
        };
      }
      if (this.levelData.triggerItems.length == 1) {
        modalParams.deleteDisabled = true;
      }
      this.spawnModal(modalParams);
    },
    editBlockingElementModal(blockingElement, index) {
      const modalParams = {
        component: 'ModalBlockingElements',
        title: 'Edit blocking element',
        index: index,
        elementData: blockingElement,
        saveEvent: 'MLE-saveBlockingElement',
        deleteEvent: 'MLE-deleteBlockingElement',
      };
      if (index == -1) {
        modalParams.title = 'New Blocking Element';
        modalParams.elementData = {
          alwaysUse: false,
          element: [],
        };
      }
      this.spawnModal(modalParams);
    },
    saveTrigger(obj) {
      if (obj.index > -1) {
        this.levelData.triggerItems[obj.index] = obj.data;
      } else {
        this.levelData.triggerItems.unshift(obj.data);
      }
      this.saveLevel();
      this.$forceUpdate();
    },
    saveBlockingElement(obj) {
      if (obj.index > -1) {
        this.levelData.blockingItems[obj.index] = obj.data;
      } else {
        this.levelData.blockingItems.unshift(obj.data);
      }
      this.saveLevel();
      this.$forceUpdate();
    },
    deleteTrigger(obj) {
      this.levelData.triggerItems.splice(obj.index, 1);
      this.saveLevel();
      this.$forceUpdate();
    },
    deleteBlockingElement(obj) {
      this.levelData.blockingItems.splice(obj.index, 1);
      this.saveLevel();
      this.$forceUpdate();
    },
    triggerIcon(item) {
      const obj = {};
      if ('elements' in item) {
        if (item.elements[0].hasOwnProperty('foreground')) {
          obj.type = 'foreground';
          obj.color = this.globalGetProp(item.elements[0], 'color', 0);
          const id = item.elements[0].foreground;
          const libItem = this.library.foregrounds.find((el) => el.id == id);
          obj.icon = libItem.icon;
          obj.hasColor = libItem.hasColor;
        }
        if (item.elements[0].hasOwnProperty('background')) {
          obj.type = 'background';
          const id = item.elements[0].background;
          const libItem = this.library.backgrounds.find((el) => el.id == id);
          obj.icon = libItem.icon;
        }
        return [obj];
      }
      return false;
    },
    blockerIcon(item) {
      const obj = {};
      if (item.element.hasOwnProperty('foreground')) {
        obj.type = 'foreground';
        obj.color = this.globalGetProp(item.element, 'color', 0);
        const id = item.element.foreground;
        const libItem = this.library.foregrounds.find((el) => el.id == id);
        obj.icon = libItem.icon;
        obj.hasColor = libItem.hasColor;
      }
      if (item.element.hasOwnProperty('background')) {
        obj.type = 'background';
        const id = item.element.background;
        const libItem = this.library.backgrounds.find((el) => el.id == id);
        obj.icon = libItem.icon;
      }
      return [obj];
    },
    blockerName(item) {
      if (item.element.hasOwnProperty('foreground')) {
        const id = item.element.foreground;
        const libItem = this.library.foregrounds.find((el) => el.id == id);
        return libItem.name;
      }
      if (item.element.hasOwnProperty('background')) {
        const id = item.element.background;
        const libItem = this.library.backgrounds.find((el) => el.id == id);
        return libItem.name;
      }
    },
    updateAndClose() {
      this.closeModal(this.modal.id);
    },
  },
  destroyed() {
    this.busEvents.forEach((element) => {
      bus.$off('MLE-'+element);
    });
    this.getGroup({
      groupId: this.internals.currentGroupId,
    });
    bus.$emit('MGE-updateGroupData');
    this.getGenerateStatus();
    window.removeEventListener('mousedown', this.mouseDown);
    window.removeEventListener('mouseup', this.mouseUp);
    clearInterval(this.resultChecker);
  },
};
</script>

<style scoped>
  .Modal{
    top:0;
  }
  .TileActionButton {
    display: none;
  }

  .v-list > div:hover .TileActionButton {
    display: inline;
  }

  .Row {
    display: flex;
  }

  .Block {
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
  }

  .Grid {
    width: 100%;
    overflow:hidden;
  }

  .GridItemsTitle{
     background: var(--secondary-color);
     color: white;
     padding: 10px;
     font-weight: bold;
  }

  .GridItems {
    display: flex;
    flex-wrap: wrap;
    background: #555555;
    overflow: auto;
    height: auto;
  }

  .TriggerItems {
    display: flex;
    background: #555555;
    flex-direction: column;
    flex:1 1 auto;
  }

  .GridItems>div{
    width:100%;
  }
  .GridItemRow{
    display: flex;
    background: #636161;
    margin: 4px 0;
    cursor: pointer;
  }

  .GridItemRow:hover{
    background:#777777;
  }

  .GridItemRow.Active{
    background-color: var(--primary-color);
  }
  .SectionName{
   padding: 4px 10px;
    background: #c1b8b8;
    color: black;
    font-weight: bold;
  }

  .GridItems .Item {
    border: solid 1px gray;
    width: 60px;
    height: 60px;
    margin: 3px;
    cursor: pointer;
  }

  .GridItems .ItemName{
    padding:7px 4px;
  }

  .ItemPercentages{
    padding: 11px 0;
    margin-left: auto;
    margin-right: 10px;
    flex: 0 0 50px;
    font-size: .7em;
    text-align: right;
  }

  .blink{
    animation:blink normal 1.5s infinite ease-in-out;
    transition:all 0.5s ease-in-out;
  }

  .ItemProps{
    padding:10px;
    background-color: #555555;
    height:81px;
  }
  .v-input--slider{
    margin-top:0;
    padding:0 10px;
    height:40px;
  }
  .fgColor{
    width:40px;
    height:40px;
    border-radius:50%;
    cursor: pointer;
    border: solid 1px #808080;
  }
  .bgLayers{
    cursor: pointer;
    border: dotted 1px;
    text-align: center;
    padding: 7px;
  }
  @keyframes blink {
    0% {color: rgba(255,255,255,1)}
    50% {color: rgba(255,255,255,.5)}
    100% {color: rgba(255,255,255,1)}
  }
  .Spacer{
    margin:0 auto;
  }
  .Message{
    flex: 1 1 auto;
    background-color: darkred;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    border: solid 2px red;
    position: fixed;
    bottom: 10px;
    right: 30px;
  }
  hr{
    margin: 20px 0;
    opacity: .3;
  }
  .panelBox{
    padding:10px;
    background: var(--card-color);
  }
  .editLocked{
    position: relative;
    pointer-events: none;
  }
  .editLocked::after{
    content: 'Editor locked.';
    background-color: rgba(0,0,0,.7);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-left: calc(50% - 50px);
    top: 0;
    padding-top: 300px;
  }
  .GridSizeH7{
    max-width:1138px;
  }
  .GridSizeV7 .ModalContent{
    min-height:438px;
  }
  .GridSizeH8{
    max-width:1198px;
  }
  .GridSizeV8 .ModalContent{
    min-height:498px;
  }
  .GridSizeH9{
    max-width:1258px;
  }
  .GridSizeV9 .ModalContent{
    min-height:558px;
  }
  .GridSizeH10{
    max-width:1318px;
  }
  .GridSizeV10 .ModalContent{
    min-height:618px;
  }
  .GridSizeH11{
    max-width:1378px;
  }
  .GridSizeV11 .ModalContent{
    min-height:678px;
  }
  .GridSizeH12{
    max-width:1438px;
  }
  .TriggerButton{
    display: flex;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    background-color: rgb(0,0,0,0.4);
    line-height: 2.2em;
    cursor: pointer;
  }
  .TriggerButton .Name{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width:224px;
  }
  .TriggerButton .Item{
    margin-right:10px;
  }
  .AddTrigger{
    background-color: rgb(0,0,0,0.4);
    padding: 10px !important;
  }
  .LeftCol,
  .RightCol{
    flex:0 0 310px;
    display:flex;
    flex-direction:column;
    max-width:320px;
    overflow-y:auto;
    height: calc(100vh - 155px);
  }
</style>
