<template>
  <v-container grid-list-xl fluid>
    <v-layout justify-center>
      <v-flex xs12>
        <v-sheet class="theme--dark.v-tabs__bar" style="display:flex">
          <div class="title pa-3">Library</div>
        </v-sheet>
        <v-tabs v-model="active">
          <v-tab :key="1">Foregrounds</v-tab>
          <v-tab :key="2">Backgrounds</v-tab>
          <v-tab :key="3">Foreground combinations</v-tab>
          <v-tab :key="4">Match types</v-tab>
          <v-tab :key="5">Score</v-tab>
          <v-tab-item key="tab0">
            <v-card flat>
              <v-card-text>
                <Foregrounds v-if="active == 0"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="tab1">
            <v-card flat>
              <v-card-text>
                <Backgrounds v-if="active == 1"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="tab2">
            <v-card flat>
              <v-card-text>
                <ForegroundCombinations v-if="active == 2"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="tab3">
            <v-card flat>
              <v-card-text>
                <MatchTypes v-if="active == 3"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="tab4">
            <v-card flat>
              <v-card-text>
                <Score v-if="active == 4"/>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import bus from '../bus';
import Foregrounds from './Foregrounds.vue';
import Backgrounds from './Backgrounds.vue';
import ForegroundCombinations from './ForegroundCombinations.vue';
import MatchTypes from './MatchTypes.vue';
import Score from './Score.vue';
import {mapGetters, mapActions} from 'vuex';

export default {
  components: {
    Foregrounds,
    Backgrounds,
    ForegroundCombinations,
    MatchTypes,
    Score,
  },
  data() {
    return {
      active: null,
      lockedIn: false,
      accountLockedLib: false,
      busEvents: [
        'confirmLockLibrary',
      ],
    };
  },
  created() {
    // Bus events shortcode LIB
    this.busEvents.forEach((element) => {
      bus.$on('LIB-'+element, eval('this.'+element));
    });
    this.accountLockedLib = this.serverData.getAccountInfo.accountInfo.internals.lockedGameLibrary;
    this.lockedIn = this.accountLockedLib;
  },
  computed: {
    ...mapGetters([
      'serverData',
    ]),
  },
  methods: {
    ...mapActions({
      spawnModal: 'spawnModal',
    }),
  },
  destroyed() {
    this.busEvents.forEach((element) => {
      bus.$off('LIB-'+element);
    });
  },
};
</script>

<style>
</style>
