import Vue from 'vue';

import Vuetify from 'vuetify';
Vue.use(Vuetify, {
  theme: {
    primary: '#AE4592',
    secondary: '#763067',
    accent: '#00FF00',
    warn: '#f44336',
    card: '#007AAE',
    chartline: '#AE4592',
    ok: '#00FF4B',
  },
});

import VueGtag from 'vue-gtag';
Vue.use(VueGtag, {
  config: {id: 'UA-157146807-1'},
});

import Chartist from 'chartist';
Object.defineProperty(Vue.prototype, '$chartist', {
  value: Chartist,
});

import 'vuetify/dist/vuetify.min.css';
import 'chartist/dist/chartist.min.css';

import store from './store';
import router from './router';
import App from './App';
import bus from './bus';

const globalGetProp = {
  install(Vue, options) {
    Vue.prototype.globalGetProp = (obj, property, alternative) => {
      const propParts = property.split('.');
      let testObj = obj;
      while (propParts.length > 0) {
        const prop = propParts.shift();
        if (testObj[prop]) {
          testObj = testObj[prop];
        } else {
          return alternative;
        }
      }
      return testObj;
    };
  },
};
Vue.use(globalGetProp);

const globalJSONCopy = {
  install(Vue, options) {
    Vue.prototype.globalJSONCopy = (src) => {
      return JSON.parse(JSON.stringify(src));
    };
  },
};
Vue.use(globalJSONCopy);

Vue.config.productionTip = false;

window.vueapp = new Vue({
  store,
  router,
  el: '#app',
  render: (h) => h(App),
});

document.onkeyup = function(e) {
  let key;
  if (window.event) {
    key = e.keyCode;
  } else if (e.which) {
    key = e.which;
  }
  // tilde, opens console
  if (key == 192) {
    bus.$emit('APP-toggleConsole');
  }
  // letters a to z enters commands
  if (key >= 65 && key <= 90) {
    const char = String.fromCharCode(key).toLocaleLowerCase();
    const arr = vueapp.$store.state.internals.consoleCommand.split('');
    arr.pop();
    arr.push(char);
    arr.push('_');
    vueapp.$store.state.internals.consoleCommand = arr.join('');
  }
  // backspace key removes character from command
  if (key == 8) {
    const arr = vueapp.$store.state.internals.consoleCommand.split('');
    arr.pop();
    arr.pop();
    arr.push('_');
    vueapp.$store.state.internals.consoleCommand = arr.join('');
  }
  // return key executes commands
  if (key == 13) {
    bus.$emit('APP-executeConsole');
  }
  // up key navigates though past commands
  if (key == 38) {
    bus.$emit('APP-consoleHistoryBack');
  }
  // up key navigates though past commands
  if (key == 40) {
    bus.$emit('APP-consoleHistoryForward');
  }
};
