<template>
  <v-container grid-list-md fluid>
    <v-layout v-if="loading"> Loading... </v-layout>
    <v-layout row wrap v-else>
      <v-flex class="ItemCard" @click="addItem" :class="{ Locked: lockedIn }">
        <v-card class="EmptyCard">
          <v-icon dark large v-if="!lockedIn"> add_circle</v-icon>
          <div v-else style="max-width: 80%; margin: auto; text-align: center">
            upgrade your account to create and modify the workings of each game
            element
          </div>
        </v-card>
      </v-flex>
      <BackgroundSheet
        v-for="item in backgrounds"
        :key="'background' + item.id"
        class="ItemCard"
        :itemParams="item"
        @onUpdate="updateItem"
      >
      </BackgroundSheet>
    </v-layout>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import bus from '../bus';
import BackgroundSheet from './BackgroundSheet.vue';

export default {
  components: {
    BackgroundSheet,
  },
  data() {
    return {
      loading: true,
      backgrounds: [],
      busEvents: [
        'saveTitle',
        'saveIcon',
        'removeBackground',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'serverData',
    ]),
    nextID() {
      let i = 0;
      this.library.backgrounds.forEach((element) => {
        if (element.id > i) {
          i = element.id;
        }
      });
      return i + 1;
    },
    library() {
      return this.serverData.getGame.gameLibrary;
    },
    lockedIn() {
      const accountLockedLib = this.serverData.getAccountInfo.accountInfo.internals.lockedGameLibrary;
      return accountLockedLib;
    },
  },
  created() {
    // Bus events shortcode BKG
    this.busEvents.forEach((element) => {
      bus.$on('BKG-' + element, eval('this.' + element));
    });
  },
  mounted() {
    this.getLibrary();
    const me = this;
    setTimeout(function() {
      me.backgrounds = me.library.backgrounds.filter((el) => el.locked < 3);
      me.loading = false;
    }, 400);
  },
  methods: {
    ...mapActions({
      spawnModal: 'spawnModal',
      saveData: 'saveData',
      showMessage: 'showMessage',
      getLibrary: 'getLibrary',
    }),
    saveIcon(modal) {
      modal.subject.icon = modal.value;
      this.updateItem(modal.subject);
    },
    updateItem(item) {
      for (let i = 0; i < this.backgrounds.length; i++) {
        if (this.backgrounds[i].id == item.id) {
          this.backgrounds[i] = this.globalJSONCopy(item);
        }
      };
      this.library.backgrounds = this.globalJSONCopy(this.backgrounds);
      this.saveData({type: 'game-library'});
    },
    saveTitle(modal) {
      modal.subject.name = modal.value;
      this.updateItem(modal.subject);
    },
    removeBackground(item) {
      this.backgrounds = this.backgrounds.filter((el) => el.id != item.id);
      this.library.backgrounds = this.globalJSONCopy(this.backgrounds);
      this.saveData({type: 'game-library'});
    },
    addItem() {
      this.backgrounds.unshift({
        id: this.nextID,
        name: 'Background ' + this.nextID,
        icon: this.nextID,
        backgroundSwappable: false,
        explodeMethod: 0,
        foregroundSwappable: false,
        hasForeground: false,
        maxLayers: 1,
        onlyBackgroundExplodes: false,
        locked: 0,
        elemLocks: [],
      });
      this.library.backgrounds = this.globalJSONCopy(this.backgrounds);
      this.saveData({type: 'game-library'});
      this.showMessage({type: 'success', text: 'New background created.'});
    },
  },
  destroyed() {
    this.busEvents.forEach((element) => {
      bus.$off('BKG-' + element);
    });
  },
};
</script>

<style scoped>

.ItemCard {
  flex: 0 0 300px;
}
.ItemCard.Locked {
  pointer-events: none;
}
</style>
