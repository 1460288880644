<template>
  <div style="width:100%">
    <v-toolbar dark>
      <div class="text-xs-center">
        <v-menu>
          <v-btn slot="activator" icon>
            <v-icon>menu</v-icon>
          </v-btn>
          <v-list>
            <v-list-tile @click="clickAddGroups">
              <v-list-tile-action>
                  <v-icon slot="activator">add_box</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Add blank groups</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="clickAddGroupsFrom" :disabled="this.items.length == 0">
              <v-list-tile-action>
                  <v-icon slot="activator">library_add</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Copy groups</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="clickMoveGroups" :disabled="this.items.length < 2">
              <v-list-tile-action>
                  <v-icon slot="activator">move_down</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Move groups</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-menu>
      </div>
      <v-toolbar-title>
        Level groups
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-layout v-if="loading">
      Loading...
    </v-layout>
    <v-layout wrap v-else>
      <div v-if="items.length == 0" class="pa-4">
          You have not created any level groups yet.<br>
          Click on the menu icon in the upper left corner and select "add groups".
      </div>
      <div
      v-for="(item, index) in items"
      :key="'Block-'+item.groupId"
      class="BlockHolder"
      >
        <div
        class="betweenBlock"
        :class="{'active': selectMode=='between'}"
        @click="clickBetweenBlock(index-1)"
        ></div>
        <v-card
        @click="clickGroup(item)"
        class="GroupBlock"
        :class="{
          'disabled': selectMode=='between',
          'selector': selectMode=='block',
          'selected': isSelected(item.groupId)
        }">
          <v-card-title primary-title class="CardTitle">
            <div class="mb-0">{{item.name}}</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="CardContent">
            <div class="NumberOfLevels">
              <div v-if="item.nrOfLevels > 0">
                Levels: {{game.gameSettings.levelNumberingStartsAt + item.lowestDisplayId}}
                to {{game.gameSettings.levelNumberingStartsAt + item.highestDisplayId}}
                ({{item.nrOfLevels}}  total)
              </div>
              <div v-else>No levels</div>
              <div class="groupSummaryBar">
                <div :style="{width: resultsPct(item)}" class="results"></div>
                <div :style="{width: newResultsPct(item)}" class="new-results"></div>
                <div :style="{width: generatingPct(item)}" class="generating"></div>
                <div :style="{width: failedPct(item)}" class="failed"></div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <div class="BlockHolder">
        <div
        class="betweenBlock"
        :class="{'active': selectMode=='between'}"
        @click="clickBetweenBlock(items.length-1)"
        ></div>
      </div>
      <div class="Fabs">
        <v-btn
        color="orange"
        fab
        small
        @click="clickMoveGroups"
        :disabled="this.items.length < 2"
        >
          <v-icon>move_down</v-icon>
        </v-btn>
        <v-btn
        color="blue"
        fab
        small
        @click="clickAddGroupsFrom"
        :disabled="this.items.length == 0"
        >
          <v-icon>library_add</v-icon>
        </v-btn>
        <v-btn
        color="green"
        fab
        @click="clickAddGroups"
        >
          <v-icon>add_box</v-icon>
        </v-btn>
      </div>
    </v-layout>
    <StatusDialog
    v-if="statusDialog.message"
    :params="statusDialog"
    ></StatusDialog>
    <Loader v-if="internals.loader"></Loader>
  </div>
</template>

<script>
import bus from '../bus';
import StatusDialog from './StatusDialog.vue';
import Loader from './Loader.vue';
import {mapGetters, mapActions} from 'vuex';

export default {
  components: {
    StatusDialog,
    Loader,
  },
  data() {
    return {
      items: [],
      loading: true,
      currentGroupId: false,
      desiredLevels: 1,
      swapping: false,
      busEvents: [
        'confirmAddLevels',
        'getGroups',
        'confirmTitle',
        'doneSelecting',
      ],
      addAfterId: null,
    };
  },
  computed: {
    ...mapGetters([
      'serverData',
      'internals',
      'network',
      'constants',
    ]),
    statusDialog() {
      return this.internals.statusDialog;
    },
    selectMode() {
      const intent = this.internals.userIntent;
      if (['copy-groups-select', 'move-groups-select'].some((el) => el == intent)) {
        return 'block';
      }
      if (['add-groups-where', 'move-groups-where', 'copy-groups-where'].some((el) => el == intent)) {
        return 'between';
      }
      return '';
    },
    totalCreatedLevels() {
      if (this.items.length > 0) {
        let totalCount = 0;
        this.items.forEach((item) => {
          totalCount += item.nrOfLevels;
        });
        return totalCount;
      }
      return 0;
    },
    game() {
      return this.serverData.getGame;
    },
    maxLevels() {
      return Math.max(0, this.serverData.getAccountInfo.accountInfo.internals.maxLevels - this.totalCreatedLevels);
    },
    libraryLocked() {
      const accountLockedLib = this.serverData.getAccountInfo.accountInfo.internals.lockedGameLibrary;
      return accountLockedLib;
    },
    addLocked() {
      return this.libraryLocked || this.maxLevels == 0;
    },
  },
  created() {
    // Bus events shortcode LGS
    this.busEvents.forEach((element) => {
      bus.$on('LGS-'+element, eval('this.'+element));
    });
    this.getGroups();
  },
  methods: {
    ...mapActions({
      postDataPromise: 'postDataPromise',
      getGroup: 'getGroup',
      getGroups: 'getGroups',
      getGroupsSummary: 'getGroupsSummary',
      spawnModal: 'spawnModal',
      showMessage: 'showMessage',
      cancelStatusDialog: 'cancelStatusDialog',
      showLoader: 'showLoader',
      hideLoader: 'hideLoader',
    }),
    clickAddGroups() {
      this.cancelStatusDialog();
      if (this.items.length == 0) {
        this.spawnModal({
          component: 'ModalNewLevels',
          busevent: 'LGS-confirmAddLevels',
          minlevels: 0,
          maxlevels: this.maxLevels,
          afterId: -1,
          prompt: 'How many levels do you wish to add to this group?',
        });
      } else {
        this.internals.userIntent = 'add-groups-where';
        this.internals.statusDialog.message = 'Select where to add.';
      }
    },
    clickAddGroupsFrom() {
      this.cancelStatusDialog();
      this.internals.userIntent = 'copy-groups-select';
      this.internals.statusDialog.message = 'Select what groups you wish to copy.';
      this.internals.statusDialog.ok = 'LGS-doneSelecting';
      this.internals.statusDialog.okText = 'Done';
    },
    clickMoveGroups() {
      this.cancelStatusDialog();
      this.internals.userIntent = 'move-groups-select';
      this.internals.statusDialog.message = 'Select what groups you wish to move.';
      this.internals.statusDialog.ok = 'LGS-doneSelecting';
      this.internals.statusDialog.okText = 'Done';
    },
    doneSelecting() {
      if (this.internals.userIntent == 'copy-groups-select') {
        this.internals.userIntent = 'copy-groups-where';
        this.internals.statusDialog.message = 'Select where to copy.';
        this.internals.statusDialog.okText = '';
      }
      if (this.internals.userIntent == 'move-groups-select') {
        this.internals.userIntent = 'move-groups-where';
        this.internals.statusDialog.message = 'Select between where to move to.';
        this.internals.statusDialog.okText = '';
      }
    },
    clickBetweenBlock(index) {
      const groupId = index == -1 ? -1 : this.items[index].groupId;
      if (this.internals.userIntent == 'add-groups-where' || this.internals.userIntent == 'copy-groups-where') {
        console.log('1', this.internals.selectedItems);
        if (this.internals.selectedItems.length == 0) {
          this.spawnModal({
            component: 'ModalNewLevels',
            busevent: 'LGS-confirmAddLevels',
            minlevels: 0,
            maxlevels: this.maxLevels,
            afterId: groupId,
            prompt: 'How many levels do you wish to add to this group?',
          });
        } else {
          this.showLoader();
          this.postDataPromise({
            command: 'createGroups',
            gameId: this.game.gameId,
            afterGroupId: groupId,
            copyFromGroupIds: this.internals.selectedItems,
            copyLevels: this.internals.userIntent == 'copy-groups-where',
          }).then((response) => {
            this.serverData.getGroupsSummary = response.data;
            this.parseSummary();
            this.hideLoader();
            this.showMessage({type: 'success', text: 'Groups added.'});
            this.cancelStatusDialog();
          });
        }
      }
      if (this.internals.userIntent == 'move-groups-where') {
        this.postDataPromise({
          command: 'moveGroups',
          gameId: this.game.gameId,
          afterGroupId: groupId,
          groupIds: this.internals.selectedItems,
        }).then((response) => {
          this.serverData.getGroupsSummary = response.data;
          this.parseSummary();
          this.showMessage({type: 'success', text: 'Groups moved.'});
          this.cancelStatusDialog();
        });
      }
    },
    resultsPct(group) {
      const states = this.groupLevelStates(group);
      const numstates = states.filter((el) => el==3).length;
      const pct = Math.ceil((numstates / states.length) * 100);
      return pct+'%';
    },
    newResultsPct(group) {
      const states = this.groupLevelStates(group);
      const numstates = states.filter((el) => el==5).length;
      const pct = Math.ceil((numstates / states.length) * 100);
      return pct+'%';
    },
    generatingPct(group) {
      const states = this.groupLevelStates(group);
      const numstates = states.filter((el) => (el==1 || el==2)).length;
      const pct = Math.ceil((numstates / states.length) * 100);
      return pct+'%';
    },
    failedPct(group) {
      const states = this.groupLevelStates(group);
      const numstates = states.filter((el) => el==4).length;
      const pct = Math.ceil((numstates / states.length) * 100);
      return pct+'%';
    },
    groupLevelStates(group) {
      const from = group.lowestDisplayId-1;
      const to = group.highestDisplayId;
      return this.serverData.getGenerateStatus.generateSummary.slice(from, to);
    },
    getGroups() {
      this.getGroupsSummary().then(this.parseSummary);
    },
    parseSummary() {
      this.items = this.serverData.getGroupsSummary.groupsSummary.sort((a, b) => a.lowestDisplayId - b.lowestDisplayId);
      this.loading = false;
    },
    confirmAddLevels(res) {
      this.desiredLevels = res.amount;
      this.addAfterId = res.afterId;
      this.spawnModal({
        component: 'ModalTextInput',
        title: 'Group title',
        label: 'Title',
        text: '',
        busevent: 'LGS-confirmTitle',
      });
    },
    confirmTitle(res) {
      this.showLoader();
      this.postDataPromise({
        command: 'createGroups',
        gameId: this.game.gameId,
        amount: this.desiredLevels,
        name: res.value,
        afterGroupId: this.addAfterId,
      }).then((response) => {
        this.addAfterId = null;
        this.serverData.getGroupsSummary = response.data;
        this.parseSummary();
        this.hideLoader();
        this.showMessage({type: 'success', text: 'Group created.'});
      },
      (response) => {
        this.hideLoader();
      });
    },
    isSelected(id) {
      return this.internals.selectedItems.some((el) => el == id);
    },
    clickGroup(item) {
      if (this.selectMode == 'block') {
        const found = this.internals.selectedItems.find((el) => el == item.groupId);
        if (found) {
          this.internals.selectedItems = this.internals.selectedItems.filter((el) => el != item.groupId);
        } else {
          this.internals.selectedItems.push(item.groupId);
        }
        this.internals.statusDialog.okDisabled = this.internals.selectedItems.length == 0;
      } else {
        this.spawnModal({
          component: 'ModalGroupEditor',
          closebutton: true,
          title: 'Group editor',
          group: item,
          levelsLeft: this.maxLevels,
        });
      }
    },
  },
  destroyed() {
    this.busEvents.forEach((element) => {
      bus.$off('LGS-'+element);
    });
  },
};
</script>

<style scoped>
.BlockHolder {
  display: flex;
  flex: 0 0 300px;
  margin-top: 20px;
}
.GroupBlock {
    cursor: pointer;
    flex: 0 0 280px;
    padding: 15px 5px;
    background-color: transparent;
    box-shadow: none;
    border: solid 1px transparent;
  }
.GroupBlock:hover {
  background: var(--secondary-color);
}
.GroupBlock.selected {
    background: #4CAF50;
  }
.GroupBlock.disabled{
  opacity: .5;
  pointer-events: none;
}
.GroupBlock.selector{
  border: dotted 1px white;
}
.GroupBlock.Locked{
  pointer-events: none;
}
.CardTitle {
  background: var(--primary-color);
  font-size:18px;
}
.CardContent {
  background-color: #555555;
}
.NumberOfLevels{
  font-size: 16px;
}
.RoundedBox{
  display: inline-block;
  background-color: #666666;
  padding: 10px;
  border-radius: 3px;
  text-align: center;
}
.groupSummaryBar{
  height: 10px;
  width: 100%;
  display: flex;
  margin:10px 0;
  background-color: #666666;
}
.results{
  background-color: #00BCD4;
}
.new-results{
  background-color:#4CAF50;
}
.generating{
  background-color:#FFB427;
}
.failed{
  background-color:#F44336;
}
.betweenBlock {
  width: 20px;
  height: 153px;
  margin: 15px 0;
  border-radius: 3px;
  border: solid 1px transparent;
}
.betweenBlock.active {
  background-color: #666666;
  border: dotted 1px white;
  cursor: pointer;
}
.betweenBlock.active:hover{
  background-color:  var(--secondary-color);
}
.Fabs{
  position: fixed;
  right: 40px;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
