import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import LevelGroups from './components/LevelGroups';
import Library from './components/Library';
import Login from './components/Login';
import Generates from './components/Generates';

Vue.use(VueRouter);

// TODO: JSDoc
// eslint-disable-next-line require-jsdoc
function requireAuth(to, from, next) {
  const loggedIn = store.state.serverData.getAuthToken.authenticationToken != null;
  if (!loggedIn) {
    next({
      path: '/login',
    });
  } else {
    next();
  }
}

export default new VueRouter({
  routes: [
    {
      path: '/',
      component: Dashboard,
      beforeEnter: requireAuth,
    },
    {
      path: '/login',
      component: Login,
    },
    {
      path: '/settings',
      component: Settings,
      beforeEnter: requireAuth,
    },
    {
      path: '/library',
      component: Library,
      beforeEnter: requireAuth,
    },
    {
      path: '/levelgroups',
      component: LevelGroups,
      beforeEnter: requireAuth,
    },
    {
      path: '/generates',
      component: Generates,
      beforeEnter: requireAuth,
    },
  ],
});
