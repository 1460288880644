<template>
  <v-container grid-list-xl fluid>
    <v-layout align-center justify-center>
      <v-flex center>
        <v-card>
          <v-card-text>
            <div
            style="display:flex;flex-direction:column"
            pa-4
            >
              <div style="display:flex">
                <div style="width:400px">
                  Generates left
                  <v-progress-linear :value="generatesValue" height="22">
                    <div class='pl-2'>{{accountInfo.generatesLeft}} of {{accountInfo.maxGenerates}}</div>
                  </v-progress-linear>
                </div>
                <div style="margin-left:auto">
                  <v-btn
                  ripple
                  @click="cancelAllGameGenerates"
                  color="red"
                  :disabled="cancelDisabled">
                    <v-icon left>stop</v-icon> Cancel all generates
                  </v-btn>
                </div>
              </div>
              <div class="filter-chips">
                <v-chip @click="toggleFilter(3)" :class="{'Active': isFilterActive(3)}" color="cyan">
                  <v-icon left>check_circle</v-icon>
                  {{countGenerateSummary(3)}} levels generated
                </v-chip>
                <v-chip @click="toggleFilter(5)" :class="{'Active': isFilterActive(5)}"  color="green">
                  <v-icon left>new_releases</v-icon>
                  {{countGenerateSummary(5)}} results to check
                </v-chip>
                <v-chip @click="toggleFilter(1)" :class="{'Active': isFilterActive(1)}" color="#FFB427">
                  <v-icon left>schedule</v-icon>
                  {{countGenerateSummary(1)}} generates scheduled
                </v-chip>
                <v-chip @click="toggleFilter(2)" :class="{'Active': isFilterActive(2)}" color="orange">
                  <v-icon left>whatshot</v-icon>
                  {{countGenerateSummary(2)}} levels generating
                </v-chip>
                <v-chip @click="toggleFilter(4)" :class="{'Active': isFilterActive(4)}" color="red">
                  <v-icon left>warning</v-icon>
                  {{countGenerateSummary(4)}} failed levels
                </v-chip>
                <v-chip @click="toggleFilter(0)" :class="{'Active': isFilterActive(0)}" color="">
                  <v-icon left>edit</v-icon>
                  {{countGenerateSummary(0)}} levels to design
                </v-chip>
              </div>
              <div style="display:flex;flex-wrap:wrap">
                <v-card
                color="primary"
                v-for="(item, i) in resultList"
                :key="'generatecard-'+i"
                min-width="220"
                class="ma-2">
                  <v-card-title><b>Level {{game.gameSettings.levelNumberingStartsAt + item.levelId}}</b></v-card-title>
                  <div style="display:flex">
                    <GenerateButton
                    :info="item"
                    :key="item.levelId"
                    style="margin-left:10px"
                    ></GenerateButton>
                    <v-btn
                    ripple
                    icon
                    v-if="item.id == 1 || item.id == 2"
                    @click="cancelGenerate(item)"
                    color="red"
                    style="margin-left:auto;margin-top:0"
                    >
                      <v-icon>{{item.id == 1 ? 'delete' : 'stop'}}</v-icon>
                    </v-btn>
                  </div>
                </v-card>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import GenerateButton from './GenerateButton.vue';
import Cookies from 'js-cookie';

export default {
  components: {
    GenerateButton,
  },
  data() {
    return {
      resultList: [],
    };
  },
  computed: {
    ...mapGetters([
      'network',
      'serverData',
      'constants',
      'internals',
    ]),
    cancelDisabled() {
      return this.serverData.getGenerateStatus.generateSummary.filter((el) => el == 1 || el == 2).length == 0;
    },
    filters() {
      return this.constants.generateStates.filter((el) => el.id >= 0).map((el) => el.descr);
    },
    game() {
      return this.serverData.getGame;
    },
    accountInfo() {
      return this.serverData.getAccountInfo.accountInfo.externals;
    },
    generatesValue() {
      return Math.round((this.accountInfo.generatesLeft / this.accountInfo.maxGenerates) * 100);
    },
    filtering: {
      get() {
        return this.internals.levelFilters.generates;
      },
      set(val) {
        this.internals.levelFilters.generates = val;
      },
    },
  },
  created() {
    this.getAccountInfo();
  },
  mounted() {
    const generateStatusFilters = Cookies.get('generateStatusFilters');
    if (generateStatusFilters) {
      this.internals.levelFilters.generates = generateStatusFilters.split(',');
    }
    this.resultList = this.getResultList(this.filtering);
  },
  watch: {
    filtering(val) {
      this.resultList = this.getResultList(val);
      Cookies.set('generateStatusFilters', val.toString());
    },
    'this.serverData.getGenerateStatus.generateSummary'() {
      this.resultList = this.getResultList(this.filtering);
    },
  },
  methods: {
    ...mapActions({
      postDataPromise: 'postDataPromise',
      getAccountInfo: 'getAccountInfo',
      showMessage: 'showMessage',
    }),
    toggleFilter(statusId) {
      const descr = this.constants.generateStates.filter((el) => el.id == statusId)[0].descr;
      if (this.filtering.filter((el) => el == descr).length > 0) {
        this.filtering = this.filtering.filter((el) => el != descr);
      } else {
        this.filtering.push(descr);
      }
    },
    isFilterActive(statusId) {
      const descr = this.constants.generateStates.filter((el) => el.id == statusId)[0].descr;
      return this.filtering.filter((el) => el == descr).length > 0;
    },
    countGenerateSummary(statusId) {
      return this.serverData.getGenerateStatus.generateSummary.filter((el) => el == statusId).length;
    },
    getResultList(filters) {
      let arr = [];
      const me = this;
      this.serverData.getGenerateStatus.generateSummary.forEach((statusid, i) => {
        const statusObj = JSON.parse(JSON.stringify(me.constants.generateStates.find((el) => el.id == statusid)));
        statusObj.levelId = i+1;
        arr.push(statusObj);
      });
      if (this.filtering.length > 0) {
        arr = arr.filter((item) => this.statusFilter(item, filters));
      }
      return arr;
    },
    statusFilter(item, filters) {
      for (let i=0; i<filters.length; i++) {
        const filter = filters[i];
        if (item.descr == filter) {
          return true;
        }
      }
      return false;
    },
    cancelAllGameGenerates() {
      const me = this;
      this.postDataPromise({
        command: 'cancelAllGameGenerates',
      }).then(function() {
        me.showMessage({
          type: 'success',
          text: 'All level generates cancelled.',
        });
      });
    },
    cancelGenerate(item) {
      const me = this;
      this.postDataPromise({
        command: 'cancelGenerate',
        gameId: this.internals.currentGameId,
        groupId: this.internals.currentGroupId,
        levelId: item.levelId,
      }).then(function() {
        me.showMessage({
          type: 'success',
          text: 'Level generate cancelled.',
        });
      });
    },
  },
};
</script>

<style>
  .filter-chips .v-chip{
    border: solid 2px transparent !important;
    cursor: pointer;
  }
  .filter-chips .v-chip .v-chip__content{
    cursor: pointer;
  }
  .filter-chips .v-chip.Active{
    border: solid 2px white !important;
  }
</style>
