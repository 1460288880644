<template>
  <v-container fluid :class="['GridSizeH'+gridSizeH+' GridSizeV'+gridSizeV]">
    <v-layout align-center justify-center>
      <v-flex>
        <v-card>
          <v-toolbar dark color="secondary">
            <v-toolbar-title>{{modal.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small flat outline @click="askEditOriginal">
              <v-icon class="mr-2">highlight_off</v-icon>edit original</v-btn>
            <v-btn small flat outline @click="askEditResult">
              <v-icon class="mr-2">check_circle_outline</v-icon>edit result</v-btn>
            <v-btn icon @click="closeModal(modal.id)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
             <v-progress-linear indeterminate v-if="!loaded"></v-progress-linear>
            <v-layout row class="ModalContent">
              <v-flex pa-2>
                <v-layout row>
                  <v-card
                    class="result-col"
                    v-for="data in diffData"
                    :key="data.name">
                    <v-card-text>
                      <div class="subheading">{{data.name}}</div>
                      <hr />
                      Difficulty: {{data.difficulty}}  |  Colors: {{minMaxStr(data.minNrOfColors, data.maxNrOfColors)}}
                      <hr class='dimmed'/>
                      <div class="section-title">
                        Triggers:
                      </div>
                      <div
                        v-for="(trigger, i) in data.triggers"
                        :key="'trigger-'+i">
                        {{consequences[trigger.consequence]}} if
                        <div
                          v-for="(condition, i) in trigger.conditions"
                          :key="'condition-'+i">
                           ->{{conditionName(condition)}}
                           {{minMaxStr(condition.minSetting, condition.maxSetting)}}
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-layout>
                <v-layout column>
                  <div style="padding:10px 20px">
                    <canvas
                      id="trigger-states"
                      width="575"
                      height="240"></canvas>
                  </div>
                  <div v-if="hasStarValues">
                    <b>Star score</b><br>
                    <table border="0" cellspacing="10">
                      <tr><td>
                        <v-icon>star</v-icon>
                      </td><td>{{levelResult.starValues[0]}}</td></tr>
                      <tr v-if="levelResult.starValues[1]"><td>
                        <v-icon>star</v-icon>
                        <v-icon>star</v-icon>
                      </td><td>{{levelResult.starValues[1]}}</td></tr>
                      <tr v-if="levelResult.starValues[2]"><td>
                        <v-icon>star</v-icon>
                        <v-icon>star</v-icon>
                        <v-icon>star</v-icon>
                      </td><td>{{levelResult.starValues[2]}}</td></tr>
                      <tr v-if="levelResult.starValues[3]"><td>
                        <v-icon>star</v-icon>
                        <v-icon>star</v-icon>
                        <v-icon>star</v-icon>
                        <v-icon>star</v-icon>
                      </td><td>{{levelResult.starValues[3]}}</td></tr>
                    </table>
                  </div>
                </v-layout>
              </v-flex>
              <v-flex pa-2 style="flex: 0 0 auto; box-sizing: content-box" class="Player">
                <div class="Grid" v-if="localBoard.length > 0" id="grid">
                  <div class="Row" v-for="row in gridSizeV" :key="'grid-row-'+row">
                    <div
                      class="Block"
                      v-for="col in gridSizeH"
                      :key="'grid-row-'+row+'-col-'+col"
                    >
                      <GridItem
                        :classes="'SolidBorder'"
                        :items="gridItemContents(row, col)"
                        :key="'griditem-'+row+'-'+col"
                        :inaccessible="isInaccessible(row, col)"
                      />
                    </div>
                  </div>
                  <div class="GridOverlay">
                    <canvas id="moveArrow" width="100%" height="100%"></canvas>
                  </div>
                </div>
                <div class="Grid" v-else :style="{'width':gridSizeH*60+'px', 'height':gridSizeV*60+'px'}"></div>
                <div class="AboveSlider">
                  <div class="FrameNum">Frame: {{framesNav.value}}</div>
                  <div class="FrameNum">Score: {{framesNav.score}}</div>
                  <div class="TurnNum">Turn: {{framesNav.turn+1}}</div>
                </div>
                <v-slider
                  v-model="framesNav.value"
                  min="1"
                  :max="framesNav.max"
                ></v-slider>
                <div class="PlaybackControls">
                  <v-btn dark small color="primary" @click="toggleFPS">
                    {{framesNav.currentFPS}} fps
                  </v-btn>
                  <v-btn fab dark small color="primary" @click="navFirstFrame">
                    <v-icon dark>first_page</v-icon>
                  </v-btn>
                  <v-btn fab dark small color="primary" @click="navPrevFrame">
                    <v-icon dark>chevron_left</v-icon>
                  </v-btn>
                  <v-btn fab dark small color="primary" @click="navTogglePlay">
                    <v-icon dark v-if="framesNav.paused">play_arrow</v-icon>
                    <v-icon dark v-else>pause</v-icon>
                  </v-btn>
                  <v-btn fab dark small color="primary" @click="navNextFrame">
                    <v-icon dark>chevron_right</v-icon>
                  </v-btn>
                  <v-btn fab dark small color="primary" @click="navLastFrame">
                    <v-icon dark>last_page</v-icon>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-tabs
                  v-model="statsTab"
                  dark
                  slider-color="primary"
                >
                  <v-tab>Raw Data</v-tab>
                  <v-tab>Averages</v-tab>
                  <v-tab>Heatmaps</v-tab>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <div v-if="resultData">
                          Turn limit: {{resultData.metaData.turnLimit}}
                        </div>
                        <div class="ChartBlock"
                          v-for="(chart, index) in charts.rawData"
                          :key="'chart-raw-data-'+index">
                          <canvas
                          :id="chart.id"
                          width="330"
                          height="330"></canvas>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <div v-if="resultData">
                          Average player choices:
                          {{resultData.metaData.averages.averagePlayerChoices}}
                          <br>
                          Average shuffles:
                          {{resultData.metaData.averages.averageShuffles}}
                          <br>
                          <div
                          v-for="(trigData, index) in averageTriggerData"
                          :key="'average-trigdata-text-'+index"
                          >
                            <div
                            v-for="(acm, acmi) in trigData.averageConditionsMet"
                            :key="'trig'+index+'-acm'+acmi"
                            >
                              Average conditions met for trigger
                              '{{levelResult.requestedLevelSettings.triggers[index].conditions[acmi].name}}': {{acm}}
                            </div>
                            {{levelResult.requestedLevelSettings.triggers[index].consequence == 0 ? 'win':'lose'}}
                            conditions triggered: {{trigData.triggerTriggered}}
                          </div>
                          <br>
                        </div>
                        <div class="ChartBlock"
                          v-for="(chart, index) in charts.averages"
                          :key="'chart-averages-'+index">
                          <canvas
                          :id="chart.id"
                          width="330"
                          height="330"></canvas>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <div v-if="resultData">
                          <div class="ChartBlock"
                            v-for="(chart, index) in charts.heatmaps"
                            :key="'chart-heatmaps-'+index">
                            <canvas
                            :id="chart.id"
                            width="330"
                            height="330"></canvas>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import bus from '../bus';
import GridItem from './GridItem.vue';
import Chart from 'chart.js';
import {mapGetters, mapActions} from 'vuex';

export default {
  components: {
    GridItem,
  },
  props: {
    modal: {
      type: Object,
    },
  },
  data() {
    return {
      moveArrows: {
        from: null,
        to: null,
      },
      charts: {
        rawData: [],
        averages: [],
      },
      statsTab: null,
      diffData: [
        {
          name: 'Your settings',
          difficulty: 0,
          minNrOfColors: 0,
          maxNrOfColors: 0,
          triggers: [],
        },
        {
          name: 'Calculated settings',
          difficulty: 0,
          minNrOfColors: 0,
          maxNrOfColors: 0,
          triggers: [],
        },
      ],
      framesNav: {
        value: 1,
        max: 2,
        paused: true,
        fpsTimer: false,
        currentFPS: 1,
        turn: -1,
        score: 0,
      },
      localBoard: [],
      levelResult: null,
      resultData: null,
      loaded: false,
      gridSizeH: 0,
      gridSizeV: 0,
      busEvents: [
        'doEditResult',
        'doEditOriginal',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'serverData',
      'internals',
      'constants',
    ]),
    consequences() {
      return this.constants.consequences;
    },
    conditiontypes() {
      return this.constants.conditiontypes;
    },
    hasStarValues() {
      if (this.levelResult) {
        if (this.levelResult.starValues) {
          return true;
        }
      }
      return false;
    },
    library() {
      return this.serverData.getGame.gameLibrary;
    },
    averageTriggerData() {
      return this.resultData.metaData.averages.averageTriggerData;
    },
  },
  created() {
    // Bus events shortcode MLE
    this.busEvents.forEach((element) => {
      bus.$on('MLR-'+element, eval('this.'+element));
    });
    const me = this;
    this.serverData.getLevel = null;
    this.getLevel({levelId: this.modal.levelId}).then(function() {
      me.diffData[0].difficulty = me.serverData.getLevel.grid.targetDifficulty;
      me.getResultDataWhenReady();
    });
    this.getResult({levelId: this.modal.levelId}).then(function() {
      const data = me.serverData.getResult;
      if ('result' in data) {
        me.levelResult = data.result;
        me.diffData[0].minNrOfColors = me.levelResult.requestedLevelSettings.minNrOfColors;
        me.diffData[0].maxNrOfColors = me.levelResult.requestedLevelSettings.maxNrOfColors;
        me.diffData[0].triggers = me.levelResult.requestedLevelSettings.triggers;
        me.diffData[1].difficulty = me.levelResult.difficulty;
        me.diffData[1].minNrOfColors = me.levelResult.levelSettings.minNrOfColors;
        me.diffData[1].maxNrOfColors = me.levelResult.levelSettings.maxNrOfColors;
        me.diffData[1].triggers = me.levelResult.levelSettings.triggers;
        me.gridSizeH = me.levelResult.levelSettings.minHorSize;
        me.gridSizeV = me.levelResult.levelSettings.minVertSize;
        me.getResultDataWhenReady();
      }
    });
  },
  watch: {
    statsTab(newValue) {
      this.renderCharts(newValue);
    },
    'framesNav.paused': function(newValue) {
      if (newValue) {
        clearInterval(this.framesNav.fpsTimer);
      } else {
        const msec = (1 / this.framesNav.currentFPS) * 1000;
        this.framesNav.fpsTimer = setInterval(this.navNextFrame, msec);
      }
    },
    'framesNav.value': function() {
      this.localBoard = this.parseBoardFrame();
      this.renderOverlayItems();
    },
  },
  methods: {
    ...mapActions({
      postDataPromise: 'postDataPromise',
      spawnModal: 'spawnModal',
      closeModal: 'closeModal',
      getLevel: 'getLevel',
      getResult: 'getResult',
      getResultData: 'getResultData',
      editResult: 'editResult',
      editOriginal: 'editOriginal',
      getGenerateStatus: 'getGenerateStatus',
    }),
    modalExists(component) {
      return this.internals.modals.findIndex((el) => el.component === component) > -1;
    },
    askEditOriginal(id) {
      this.spawnModal({
        component: 'ModalConfirm',
        title: 'Edit original.',
        text: 'Are you sure you want to reject this result and load in the original level?',
        subject: id,
        busevent: 'MLR-doEditOriginal',
        closebutton: false,
      });
    },
    doEditOriginal() {
      const me = this;
      this.editOriginal({levelId: this.modal.levelId}).then(() => {
        me.spawnModal({
          component: 'ModalLevelEditor',
          closebutton: true,
          title: 'Level editor',
          level: {
            levelId: me.modal.levelId,
            displayId: me.modal.displayId,
            hasResult: false,
            groupId: me.internals.currentGroupId,
          },
          limitHeight: true,
        });
        me.closeModal(me.modal.id);
      });
    },
    askEditResult(id) {
      this.spawnModal({
        component: 'ModalConfirm',
        title: 'Edit result.',
        text: 'Are you sure you want to load in this result and overwrite your original level?',
        subject: id,
        busevent: 'MLR-doEditResult',
        closebutton: false,
      });
    },
    doEditResult() {
      const me = this;
      this.editResult({levelId: this.modal.levelId}).then(() => {
        me.spawnModal({
          component: 'ModalLevelEditor',
          closebutton: true,
          title: 'Level editor',
          level: {
            levelId: me.modal.levelId,
            displayId: me.modal.displayId,
            hasResult: false,
            groupId: me.internals.currentGroupId,
          },
          limitHeight: true,
        });
        me.closeModal(me.modal.id);
      });
    },
    getResultDataWhenReady() {
      if (this.serverData.getLevel && this.levelResult && !this.resultData) {
        this.getResultData({levelId: this.modal.levelId}).then(this.onGetResultData);
      }
    },
    conditionName(item) {
      if (item.hasOwnProperty('name')) {
        return item.name;
      } else {
        return 'cannot find name';
      }
    },
    toggleFPS() {
      const fpslist = [1, 2, 3, 5, 10, 15];
      const currfpsindex = fpslist.indexOf(this.framesNav.currentFPS);
      const nextfpsindex = (currfpsindex + 1) % fpslist.length;
      this.framesNav.currentFPS = fpslist[nextfpsindex];
      if (!this.framesNav.paused) {
        clearInterval(this.framesNav.fpsTimer);
        const msec = (1 / this.framesNav.currentFPS) * 1000;
        this.framesNav.fpsTimer = setInterval(this.navNextFrame, msec);
      }
    },
    renderHeatMap(id, data, label) {
      const ctx = document.getElementById(id).getContext('2d');
      // set variables
      const padding = 17;
      const gridSize = 330 - (padding*2);
      const cols = data.data[0].length;
      const rows = data.data.length;
      const numBlocks = Math.max(rows, cols);
      const blockSize = Math.round(gridSize / numBlocks);
      const hOffset = (gridSize - (cols*blockSize)) /2;
      ctx.strokeStyle = '#555555';
      // draw heatmap title
      ctx.font = 'bold 12px Arial';
      ctx.fillStyle = '#FFFFFF';
      ctx.textAlign = 'center';
      ctx.fillText(label, (gridSize+(padding*2))/2, 12);
      // draw ranges
      ctx.font = '11px Arial';
      let ranges = 'average:'+data.ranges.avg;
      ranges += ', bottom:'+data.ranges.bot;
      ranges += ', top:'+data.ranges.top;
      const textX = (gridSize+(padding*2))/2;
      const textY = gridSize+(padding*2)-2;
      ctx.fillText(ranges, textX, textY);
      // draw block content
      ctx.font = 'bold 12px Arial';
      ctx.textAlign = 'center';
      for (let y=0; y<rows; y++) {
        for (let x=0; x<data.data[y].length; x++) {
          // colors
          ctx.beginPath();
          const rectX = (x * blockSize) + padding + hOffset;
          const rectY = (y * blockSize) + padding;
          ctx.rect(rectX, rectY, blockSize, blockSize);
          ctx.fillStyle = 'rgb('+data.hue.r[y][x]+','+data.hue.b[y][x]+', 0)';
          ctx.fill();
          // numbers
          ctx.fillStyle = '#dddddd';
          const text = String(data.data[y][x]).substr(0, 4);
          const textX = rectX + (blockSize/2);
          const textY = rectY + (blockSize/2) + 4;
          ctx.fillText(text, textX, textY);
        }
      }
      // draw grid rows
      for (let i=0; i<=rows; i++) {
        const fromX = padding + hOffset;
        const toX = (cols*blockSize)+padding+hOffset;
        const y = (i*blockSize)+padding;
        ctx.moveTo(fromX, y);
        ctx.lineTo(toX, y);
        ctx.stroke();
      }
      // draw grid cols
      for (let i=0; i<=cols; i++) {
        const x = (i*blockSize)+padding;
        ctx.moveTo(x + hOffset, padding);
        ctx.lineTo(x + hOffset, padding+gridSize+5);
        ctx.stroke();
      }
    },
    renderChart(id, data, label) {
      const ctx = document.getElementById(id).getContext('2d');
      const config = this.prepConfig(data, label);
      new Chart(ctx, config);
    },
    prepConfig(data, label) {
      if (!data) {
        return;
      }
      return {
        type: 'line',
        data: {
          labels: Object.keys(data),
          datasets: [
            {
              fill: false,
              borderColor: vueapp.$vuetify.theme.chartline,
              data: Object.values(data),
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          responsive: false,
          title: {
            display: true,
            text: label,
            fontColor: '#FFF',
          },
          tooltips: {
            enabled: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: 'rgba(255,255,255,0.1)',
                },
                scaleLabel: {
                  display: false,
                  labelString: '',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: 'rgba(255,255,255,0.1)',
                  zeroLineColor: 'rgba(255,255,255,0.1)',
                },
                scaleLabel: {
                  display: false,
                  labelString: '',
                },
              },
            ],
          },
        },
      };
    },
    onGetResultData() {
      this.loaded = true;
      this.resultData = this.serverData.getResultData;
      // prepare raw data
      this.charts.rawData = [];
      let chartsData = this.resultData.metaData.rawData;
      for (let i=0; i<chartsData.backgroundLayersExplodedPerTurn.length; i++) {
        const libItemId = chartsData.backgroundLayersExplodedPerTurn[i].id;
        const libItem = this.library.backgrounds.find((el) => {
          return el.id == libItemId;
        });
        this.charts.rawData.push(
            {
              id: 'chart-blept-'+i,
              data: chartsData.backgroundLayersExplodedPerTurn[i].data,
              label: `'${libItem.name}' exploded per turn`,
            },
        );
      }
      for (let i=0; i<chartsData.foregroundsExplodedPerTurn.length; i++) {
        const libItemId = chartsData.foregroundsExplodedPerTurn[i].id;
        const libItem = this.library.foregrounds.find((el) => {
          return el.id == libItemId;
        });
        this.charts.rawData.push(
            {
              id: 'chart-fept-'+i,
              data: chartsData.foregroundsExplodedPerTurn[i].data,
              label: `'${libItem.name}' exploded per turn`,
            },
        );
      }
      this.charts.rawData.push(
          {
            id: 'chart-player-choices',
            data: chartsData.playerChoices,
            label: 'Player choices',
          },
      );
      this.charts.rawData.push(
          {
            id: 'chart-runs-per-turn',
            data: chartsData.runsPerTurn,
            label: 'Runs per turn',
          },
      );
      this.charts.rawData.push(
          {
            id: 'chart-score-per-turn',
            data: chartsData.scorePerTurn,
            label: 'Score per turn',
          },
      );
      this.charts.rawData.push(
          {
            id: 'chart-shuffles',
            data: chartsData.shuffles,
            label: 'Shuffles',
          },
      );
      for (let trig=0; trig<chartsData.triggerData.length; trig++) {
        let arr = chartsData.triggerData[trig].conditionStateVars;
        for (let i=0; i<arr.length; i++) {
          const trigName = this.levelResult.requestedLevelSettings.triggers[trig].conditions[i].name;
          this.charts.rawData.push(
              {
                id: 'chart-t'+trig+'-csv-'+i,
                data: arr[i],
                label: `'${trigName}' condition state vars`,
              },
          );
        }
        arr = chartsData.triggerData[trig].conditionsMetPerTurn;
        for (let i=0; i<arr.length; i++) {
          const trigName = this.levelResult.requestedLevelSettings.triggers[trig].conditions[i].name;
          this.charts.rawData.push(
              {
                id: 'chart-t'+trig+'-cmpt-'+i,
                data: arr[i],
                label: `'${trigName}' condition met per turn`,
              },
          );
        }
        arr = chartsData.triggerData[trig].triggersTriggeredPerTurn;
        for (let i=0; i<arr.length; i++) {
          const trigName = this.levelResult.requestedLevelSettings.triggers[trig].conditions[i].name;
          this.charts.rawData.push(
              {
                id: 'chart-t'+trig+'-ttpt-'+i,
                data: arr[i],
                label: `'${trigName}' triggered per turn`,
              },
          );
        }
      }
      // prepare averages
      chartsData = this.resultData.metaData.averages;
      let arr = chartsData.averageBackgroundLayersExplodedHeatMap;
      this.charts.averages = [];
      this.charts.averages.push(
          {
            id: 'heatmap-ablehm',
            data: arr,
            label: 'Average Background Layers Exploded',
          },
      );
      arr = chartsData.averageForegroundsExplodedHeatMap;
      this.charts.averages.push(
          {
            id: 'heatmap-afehm',
            data: arr,
            label: 'Average Foregrounds Exploded',
          },
      );
      arr = chartsData.backgroundLayersExplodedPerTurn;
      this.charts.averages.push(
          {
            id: 'chart-blept',
            data: arr,
            label: 'Background Layers Exploded Per Turn',
          },
      );
      arr = chartsData.foregroundsExplodedPerTurn;
      this.charts.averages.push(
          {
            id: 'chart-fept',
            data: arr,
            label: 'Foregounds Exploded Per Turn',
          },
      );
      arr = chartsData.dropsPerTurn;
      this.charts.averages.push(
          {
            id: 'chart-dpt',
            data: arr,
            label: 'Drops Per Turn',
          },
      );
      // prepare heatmaps
      this.charts.heatmaps = [];
      chartsData = this.resultData.metaData.heatMaps;
      let hms = chartsData.backgroundLayersExplodedHeatMaps;
      if (hms) {
        for (let i=0; i<hms.length; i++) {
          const arr = hms[i];
          const itemName = this.library.backgrounds.find((el) => el.id == arr.id).name;
          this.charts.heatmaps.push(
              {
                id: 'heatmap-blehm-'+i,
                data: arr,
                label: `${itemName} layers exploded`,
              },
          );
        }
      }
      hms = chartsData.foregroundsExplodedHeatMaps;
      if (hms) {
        for (let i=0; i<hms.length; i++) {
          const arr = hms[i];
          const itemName = this.library.foregrounds.find((el) => el.id == arr.id).name;
          this.charts.heatmaps.push(
              {
                id: 'heatmap-fehm-'+i,
                data: arr,
                label: `${itemName} exploded`,
              },
          );
        }
      }
      hms = chartsData.playerMoveTapHeatMaps;
      if (hms) {
        for (let i=0; i<hms.length; i++) {
          const arr = hms[i];
          const itemName = this.library.foregrounds.find((el) => el.id == arr.id).name;
          this.charts.heatmaps.push(
              {
                id: 'heatmap-pmthm-'+i,
                data: arr,
                label: `${itemName}`,
              },
          );
        }
      }
      hms = chartsData.playerMoveMatchTypesHeatMaps;
      if (hms) {
        const matchTypeNames = [
          'T 5x3',
          'T 5x2',
          'Horizontal 5',
          'Vertical 5',
          'L 3x3',
          'T 3x3',
          'Horizontal 4',
          'Vertical 4',
          'Parallel 4x2',
          'Parallel 3x2',
          'Parallel 2x2',
          'Horizontal 3',
          'Vertical 3',
        ];
        for (let i=0; i<hms.length; i++) {
          const arr = hms[i];
          const itemName = matchTypeNames[arr.id];
          this.charts.heatmaps.push(
              {
                id: 'heatmap-pmmthm-'+i,
                data: arr,
                label: `${itemName}`,
              },
          );
        }
      }
      this.renderCharts(0);
      // runs data
      this.framesNav.max = this.resultData.run.frames;
      this.localBoard = this.parseBoardFrame();
      this.renderOverlayItems();
      // triggers
      const dataset = [];
      this.resultData.run.game.forEach((frame, frameIndex) => {
        frame.triggerStates.forEach((trigger, triggerIndex) => {
          trigger.forEach((condition, conditionIndex) => {
            if (!dataset[triggerIndex]) {
              dataset[triggerIndex] = [];
            }
            if (!dataset[triggerIndex][conditionIndex]) {
              dataset[triggerIndex][conditionIndex] = [[], []];
            }
            dataset[triggerIndex][conditionIndex][0].push(condition[0]);
            dataset[triggerIndex][conditionIndex][1].push(condition[1]);
          });
        });
      });
      const chartConfig = this.prepConfig(dataset[0][0][0], 'Trigger states');
      chartConfig.options.legend.display = true;
      chartConfig.options.legend.labels = {
        boxWidth: 10,
        filter: function(item, data) {
          return item.text != '';
        },
      };
      chartConfig.options.scales.xAxes[0].scaleLabel.display = true;
      chartConfig.options.scales.xAxes[0].scaleLabel.labelString = 'frame';
      chartConfig.options.scales.yAxes[0].scaleLabel.display = true;
      chartConfig.options.scales.yAxes[0].scaleLabel.labelString = 'state';
      chartConfig.data.datasets = [];
      const me = this;
      dataset.forEach(function(trigger, triggerIndex) {
        trigger.forEach(function(condition) {
          const consequence = me.levelResult.requestedLevelSettings.triggers[triggerIndex].consequence;
          const color = consequence === 1 ? '#FF0000' : '#00FF00';
          me.addTriggerDataSet({
            config: chartConfig,
            label: me.consequences[consequence],
            dataset: condition[0],
            color: color,
            dashed: false,
          });
          me.addTriggerDataSet({
            config: chartConfig,
            label: '',
            dataset: condition[1],
            color: color,
            dashed: true,
          });
        });
      });
      const ctx = document.getElementById('trigger-states').getContext('2d');
      new Chart(ctx, chartConfig);
    },
    addTriggerDataSet(obj) {
      const conf = {
        fill: false,
        label: obj.label,
        borderWidth: 1,
        borderColor: obj.color,
        pointRadius: 0,
        data: Object.values(obj.dataset),
      };
      if (obj.dashed) {
        conf.borderDash = [5, 5];
      }
      obj.config.data.datasets.push(conf);
    },
    parseBoardFrame() {
      const board = [];
      const currFrame = this.framesNav.value-1;
      const frameData = this.resultData.run.game[currFrame];
      const serverBoard = frameData.boardState;
      this.framesNav.turn = frameData.turn;
      this.framesNav.score = frameData.score;
      // render frame
      for (let row = 0; row < serverBoard.length; row++) {
        for (let col = 0; col < serverBoard[row].length; col++) {
          const data = serverBoard[row][col];
          const y = row+1;
          const x = col+1;
          const id = y+'-'+x;
          // Convert data to actual local items
          let items = [];
          // Check if id is already used
          let isNewId = true;
          const existingData = board.filter((x) => x.id === id);
          if (existingData.length > 0) {
            // Save data in existing item array
            items = existingData[0].items;
            isNewId = false;
          }
          // Background
          if ('b' in data) {
            const item = {};
            const libraryItem = this.library.backgrounds.find((el) => el.id == data.b);
            item['type'] = 'background';
            item['layer'] = data.l;
            item['icon'] = libraryItem.icon;
            item['id'] = data.b;
            if ('i' in data) {
              item['indicator'] = data.i;
            }
            items.push(item);
          }
          if ('f' in data) {
            // Foreground
            const item = {};
            const libraryItem = this.library.foregrounds.find((el) => el.id == data.f);
            item['type'] = 'foreground';
            item['color'] = data.c;
            item['icon'] = libraryItem.icon;
            item['id'] = data.f;
            if ('t' in data) {
              item['hasTimer'] = true;
              item['minTimer'] = data.t;
              item['maxTimer'] = data.t;
            }
            if ('d' in data) {
              this.moveArrows.from = id;
            }
            if ('m' in data) {
              this.moveArrows.to = id;
            }
            items.push(item);
          }
          if ('s' in data) {
            // SpawnPoint
            const item = {};
            // Currently only support
            // for a static type of spawnPoint
            item['type'] = 'spawnpoint';
            item['icon'] = 0;
            items.push(item);
          }
          if ('e' in data) {
            // Exit
            const item = {};
            item['type'] = 'exit';
            item['icon'] = 2;
            items.push(item);
          }
          if (isNewId) {
            board.push({id, 'items': items});
          }
        }
      }
      // Flip all items to match original order
      board.forEach((values) => values.items.reverse());
      return board;
    },
    renderOverlayItems() {
      if (this.moveArrows.from && this.moveArrows.to) {
        this.$nextTick(() => {
          // Render overlay items
          const canvas = document.getElementById('moveArrow');
          canvas.width = document.getElementById('grid').offsetWidth;
          canvas.height = document.getElementById('grid').offsetHeight;
          const fromParts = this.moveArrows.from.split('-');
          const toParts = this.moveArrows.to.split('-');
          const fromX = (fromParts[0]*60)-30;
          const fromY = (fromParts[1]*60)-30;
          const toX = (toParts[0]*60)-30;
          const toY = (toParts[1]*60)-30;
          if (canvas) {
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.strokeStyle = '#ffff00';
            context.beginPath();
            this.canvas_arrow(context, fromX, fromY, toX, toY);
            context.stroke();
          }
        });
      }
    },
    canvas_arrow(context, fromx, fromy, tox, toy) {
      const headlen = 10; // length of head in pixels
      const dx = tox - fromx;
      const dy = toy - fromy;
      const angle = Math.atan2(dy, dx);
      context.moveTo(fromx, fromy);
      context.lineTo(tox, toy);
      context.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
      context.moveTo(tox, toy);
      context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
    },
    renderCharts(tabIndex) {
      // render raw data stats
      if (tabIndex == 0) {
        for (let i=0; i<this.charts.rawData.length; i++) {
          const chart = this.charts.rawData[i];
          this.$nextTick(() => {
            this.renderChart(chart.id, chart.data, chart.label);
          });
        };
      }
      // render average stats
      if (tabIndex == 1) {
        for (let i=0; i<this.charts.averages.length; i++) {
          const chart = this.charts.averages[i];
          if (chart.id.indexOf('heatmap') > -1) {
            this.renderHeatMap(chart.id, chart.data, chart.label);
          } else {
            this.renderChart(chart.id, chart.data, chart.label);
          }
        }
      }
      // render heatmaps
      if (tabIndex == 2) {
        for (let i=0; i<this.charts.heatmaps.length; i++) {
          const chart = this.charts.heatmaps[i];
          if (chart.id.indexOf('heatmap') > -1) {
            this.renderHeatMap(chart.id, chart.data, chart.label);
          } else {
            this.renderChart(chart.id, chart.data, chart.label);
          }
        }
      }
    },
    gridItemContents(row, col) {
      if (this.localBoard.length > 0) {
        const levelgrid = this.localBoard;
        const itemIndex = levelgrid.findIndex((elm) => {
          return elm.id === row + '-' + col;
        });
        if (itemIndex > -1) {
          return levelgrid[itemIndex].items;
        }
        return [];
      }
      return [];
    },
    isInaccessible(row, col) {
      if (this.localBoard.length > 0) {
        const levelgrid = this.localBoard;
        const itemIndex = levelgrid.findIndex((elm) => {
          return elm.id === row + '-' + col;
        });
        if (itemIndex > -1) {
          return levelgrid[itemIndex].items.some((el) => el.type == 'background' && el.id == 1);
        }
      }
      return false;
    },
    foregroundName(id) {
      return this.library.foregrounds.find((el) => el.id == id).name;
    },
    backgroundName(id) {
      return this.library.backgrounds.find((el) => el.id == id).name;
    },
    navFirstFrame() {
      this.framesNav.value = 1;
    },
    navNextFrame() {
      this.framesNav.value++;
      if (this.framesNav.value >= this.framesNav.max) {
        clearInterval(this.framesNav.fpsTimer);
        this.framesNav.paused = true;
      }
    },
    navPrevFrame() {
      this.framesNav.value--;
    },
    navLastFrame() {
      this.framesNav.value = this.resultData.run.frames;
    },
    navTogglePlay() {
      this.framesNav.paused = !this.framesNav.paused;
      if (!this.framesNav.paused && this.framesNav.value == this.framesNav.max) {
        this.framesNav.value = 1;
      }
    },
    minMaxStr(min, max) {
      if (min == max) {
        return min;
      }
      if (!min) {
        return max;
      }
      return min+' - '+max;
    },
  },
  destroyed() {
    this.busEvents.forEach((element) => {
      bus.$off('MLR-'+element);
    });
    this.getGenerateStatus();
  },
};
</script>

<style scoped>
.Row {
  display: flex;
}

.Block {
  flex: 0 0 60px;
  width: 60px;
  height: 60px;
}

.Grid {
  border: solid 1px gray;
  position: relative;
}

.GridItems {
  display: flex;
  flex-wrap: wrap;
}

.GridItems > * {
  border: solid 1px gray;
  width: 60px;
  height: 60px;
  margin: 3px;
  cursor: pointer;
}
.result-col{
  background:#4B4B4B;
  flex:1 1 50%;
  margin-right:10px;
  line-height:1.6em;
}
hr{
  margin:1em 0;
}
.dimmed{
  border:0;
  border-top: solid 1px #757575;
}
.dashed{
  border-top-style: dashed;
}
.section-title{
  margin-bottom:.8em;
}
.ChartBlock{
  display: inline-block;
}
.PlaybackControls{
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
}
.AboveSlider{
  display: flex;
  width:100%;
  font-size: 14px;
  margin-top:20px;
}
.FrameNum{
  flex: 1 1 50%;
}
.TurnNum{
  flex: 1 1 50%;
  text-align: right;
}
.GridSizeH7{
  max-width:1144px;
}
@media only screen and (max-width: 1144px) {
  .GridSizeH7 .layout.row.ModalContent{
    flex-direction: column;
    align-items: center;
  }
  .GridSizeH7 .Player{
    width: 424px;
  }
}
.GridSizeH8{
  max-width:1204px;
}
@media only screen and (max-width: 1204px) {
  .GridSizeH8 .layout.row.ModalContent{
    flex-direction: column;
    align-items: center;
  }
  .GridSizeH8 .Player{
    width: 484px;
  }
}
.GridSizeH9{
  max-width:1264px;
}
@media only screen and (max-width: 1264px) {
  .GridSizeH9 .layout.row.ModalContent{
    flex-direction: column;
    align-items: center;
  }
  .GridSizeH9 .Player{
    width: 544px;
  }
}
.GridSizeH10{
  max-width:1324px;
}
@media only screen and (max-width: 1324px) {
  .GridSizeH10 .layout.row.ModalContent{
    flex-direction: column;
    align-items: center;
  }
  .GridSizeH10 .Player{
    width: 604px;
  }
}
.GridSizeH11{
  max-width:1384px;
}
@media only screen and (max-width: 1384px) {
  .GridSizeH11 .layout.row.ModalContent{
    flex-direction: column;
    align-items: center;
  }
  .GridSizeH11 .Player{
    width: 664px;
  }
}
.GridSizeH12{
  max-width:1444px;
}
@media only screen and (max-width: 1444px) {
  .GridSizeH12 .layout.row.ModalContent{
    flex-direction: column;
    align-items: center;
  }
  .GridSizeH12 .Player{
    width: 724px;
  }
}
.GridOverlay{
  position:absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
}

#moveArrow{
  position:absolute;
  top:0;
  left:0;
}
</style>
