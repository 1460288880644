<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-card>
        <v-toolbar dark color="warn">
          <v-toolbar-title>{{modal.title}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="ma-4">{{modal.text}}</v-card-text>
        <v-card-actions>
          <v-btn flat @click="close">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirm" color="warn">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex';
import bus from '../bus';

export default {
  props: {
    modal: {
      type: Object,
    },
  },
  methods: {
    ...mapActions(
        {closeModal: 'closeModal'},
    ),
    close() {
      this.closeModal(this.modal.id);
    },
    confirm() {
      bus.$emit(this.modal.busevent, this.modal.subject);
      this.close();
    },
  },
};
</script>
