<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex style="flex:0 0 390px">
        <v-card>
          <v-toolbar dark color="secondary">
            <v-toolbar-title>{{modal.title}}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-tabs
              v-model="active"
              dark
            >
              <v-tab ripple>Templates</v-tab>
              <v-tab ripple>Games</v-tab>
              <v-tab-item>
                <v-list two-line>
                  <template v-for="(item, index) in items">
                    <v-list-tile :key="'template-'+item.id">
                      <v-list-tile-action>
                        <v-icon>{{item.icon}}</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          <span class="itemtitle" @click="selectGame(item)">
                            {{item.title}}</span>
                        </v-list-tile-title>
                        <v-list-tile-sub-title>{{item.subtitle}}
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider
                        v-if="index + 1 < items.length" :key="`divider-${item.id}`">
                    </v-divider>
                  </template>
                </v-list>
              </v-tab-item>
              <v-tab-item>
                <v-list two-line>
                  <template v-for="(item, index) in gamesList">
                    <v-list-tile :key="'game-'+item.id">
                      <v-list-tile-action>
                        <v-icon>{{item.icon}}</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          <span class="itemtitle" @click="selectGame(item)">
                            {{item.title}}</span>
                        </v-list-tile-title>
                        <v-list-tile-sub-title>{{item.subtitle}}
                        </v-list-tile-sub-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-divider
                        v-if="index + 1 < items.length" :key="`divider-${item.id}`">
                    </v-divider>
                  </template>
                </v-list>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-card-actions>
            <v-btn flat @click="closeModal(modal.id)" v-if="modal.closebutton">
              Cancel</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import bus from '../bus';
import {mapActions, mapGetters} from 'vuex';

export default {
  props: {
    modal: {
      type: Object,
    },
  },
  data() {
    return {
      active: 0,
      chosenItem: false,
      gamesList: [],
    };
  },
  computed: {
    ...mapGetters([
      'network',
      'internals',
      'serverData',
    ]),
    items() {
      return [
        {
          id: 'gameLibraryEmpty',
          icon: 'insert_drive_file',
          title: 'Blank',
          subtitle: 'Default blank template',
        },
        {
          id: 'gameLibraryBasic',
          icon: 'description',
          title: 'Basic',
          subtitle: 'A basic template',
        },
        {
          id: 'gameLibraryExtended',
          icon: 'description',
          title: 'Extended',
          subtitle: 'An extended template',
        },
      ];
    },
  },
  created() {
    bus.$on('MNG-saveNewGameTitle', this.saveNewGameTitle);
    const me = this;
    this.getGamesSummary().then(function() {
      me.gamesList = [];
      const gamesSummary = me.serverData.getGamesSummary.gamesSummary;
      if (gamesSummary.length > 0) {
        for (let i = 0; i < gamesSummary.length; i++) {
          const game = gamesSummary[i];
          const obj = {};
          obj.id = game.gameId;
          obj.title = game.name;
          obj.subtitle = game.lastModified;
          obj.icon = 'description';
          me.gamesList.push(obj);
        }
      }
    });
  },
  methods: {
    ...mapActions({
      postDataPromise: 'postDataPromise',
      closeAllModals: 'closeAllModals',
      spawnModal: 'spawnModal',
      closeModal: 'closeModal',
      saveData: 'saveData',
      getGamesSummary: 'getGamesSummary',
      getGenerateStatus: 'getGenerateStatus',
    }),
    selectGame(item) {
      this.chosenItem = item;
      let component = 'ModalTextInput';
      if (!isNaN(item.id)) {
        component = 'ModalCopyGame';
      };
      this.spawnModal({
        component: component,
        title: 'Game title',
        label: 'Title',
        text: '',
        busevent: 'MNG-saveNewGameTitle',
      });
    },
    saveNewGameTitle(res) {
      const id = this.chosenItem.id;
      const payload = {
        command: 'createGame',
        name: res.value,
      };
      if (res.copyLevels) {
        payload.copyLevels = res.copyLevels;
      }
      if (
        id == 'gameLibraryExtended' ||
        id == 'gameLibraryBasic' ||
        id == 'gameLibraryEmpty'
      ) {
        payload.gameLibraryPresetName = id;
      } else {
        payload.copyFromGameId = id;
      }
      const me = this;
      this.postDataPromise(payload).then(function(response) {
        me.serverData.getGame = response.data;
        me.internals.currentGameId = response.data.gameId;
        me.closeAllModals();
        me.$router.push('/settings');
        me.getGenerateStatus();
      });
    },
    loadGame(id) {
      if (typeof id === 'number') {
        const obj = {
          command: 'getGame',
          gameId: id,
        };
        this.internals.currentGameId = id;
        this.postDataPromise(obj).then(this.afterGetGame);
      }
    },
    afterGetGame(response) {
      this.serverData.getGame = response.data;
      this.closeAllModals();
      this.$router.push('/settings');
      this.getGenerateStatus();
    },
  },
  destroyed() {
    bus.$off('MNG-saveNewGameTitle');
  },
};
</script>

<style scoped>
.v-list > div:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.v-list > div:hover .Delete {
  display: inline;
}
.itemtitle:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
