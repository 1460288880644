<template>
  <div
  class="Item"
  :style="style"
  @click="onClick"
  :class="classes"
  >
    <canvas :id="id" :width="size" :height="size"></canvas>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  props: {
    items: {
      type: Array,
      default: function() {
        return [];
      },
    },
    classes: {
      type: String,
      default: function() {
        return '';
      },
    },
    size: {
      type: Number,
      default: function() {
        return 60;
      },
    },
    inaccessible: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      id: null,
    };
  },
  computed: {
    ...mapGetters([
      'constants',
    ]),
    timerValues() {
      const item = this.items.find((el) => el.type == 'foreground');
      if (item) {
        if (item['minTimer']) {
          return [item.minTimer, item.maxTimer];
        }
      }
      return false;
    },
    hasTimer() {
      const item = this.items.find((el) => el.type == 'foreground');
      if (item) {
        if (item['hasTimer']) {
          return item.hasTimer;
        }
      }
      return false;
    },
    maxLayers() {
      const item = this.items.find((el) => el.type == 'background');
      if (item) {
        if ('maxLayers' in item) {
          return String(item.maxLayers);
        }
      }
      return false;
    },
    backgroundLayers() {
      let item = this.items.find((el) => el.type == 'background');
      if (item) {
        if ('layers' in item) {
          if (Number(item.layers > 0)) {
            return String(item.layers);
          }
        }
        if ('layer' in item) {
          if (Number(item.layer > 0)) {
            return String(item.layer);
          }
        }
      }
      item = this.items.find((el) => el.type == 'foreground');
      if (item) {
        if ('amount' in item) {
          if (Number(item.amount > 0)) {
            return String(item.amount);
          }
        }
      }
      return false;
    },
    color() {
      const item = this.items.find((el) => el.type == 'foreground');
      if (item) {
        if (item['color'] != undefined) {
          if (item.color == 0) {
            return false;
          }
          if (item.color == -1) {
            return 'random';
          }
          return this.constants.fgColors[item.color];
        }
      }
      return false;
    },
    indicator() {
      const item = this.items.find((el) => el.type == 'background');
      if (item) {
        if (item['indicator'] != undefined) {
          if (item.indicator == 0) {
            return false;
          }
          if (item.indicator == -1) {
            return 'random';
          }
          return this.constants.fgColors[item.indicator];
        }
      }
      return false;
    },
    style() {
      return {'width': this.size+'px', 'height': this.size+'px', 'flex': '0 0 '+this.size+'px'};
    },
  },
  created() {
    this.id = this.makeid(16);
  },
  mounted() {
    this.renderGriditem();
  },
  watch: {
    items() {
      this.renderGriditem();
    },
  },
  methods: {
    makeid(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    renderGriditem() {
      const canvas = document.getElementById(this.id);
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      if (this.inaccessible) {
        context.fillStyle = 'rgb(33,33,33)';
        context.fillRect(0, 0, this.size, this.size);
        return;
      }
      // foreground colors
      if (this.color) {
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = canvas.width * .37;
        context.beginPath();
        context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
        if (this.color == 'random') {
          const gradient = context.createLinearGradient(0, 0, canvas.width, canvas.height);
          gradient.addColorStop(0, 'red');
          gradient.addColorStop(3 / 10, 'orange');
          gradient.addColorStop(4 / 10, 'yellow');
          gradient.addColorStop(5 / 10, 'green');
          gradient.addColorStop(6 / 10, 'blue');
          gradient.addColorStop(7 / 10, 'indigo');
          gradient.addColorStop(1, 'violet');
          context.fillStyle = gradient;
        } else {
          context.fillStyle = this.color;
        }
        context.fill();
      }
      // item layers
      const me = this;
      const sprite = document.getElementById('sprite');
      this.items.forEach((el) => {
        const pos = me.backgroundPosition(el);
        context.drawImage(sprite, pos[0], pos[1], 60, 60, 0, 0, this.size, this.size);
      });
      // background layers
      if (this.backgroundLayers) {
        const numberBackColor = 'rgba(0, 0, 0, '+(this.maxLayers==1?0.3:1)+')';
        context.fillStyle = numberBackColor;
        context.fillRect(this.size-14, this.size-14, 12, 12);
        context.font = '9px Arial';
        context.textAlign = 'center';
        const numberColor = 'rgba(255, 255, 255, '+(this.maxLayers==1?0.3:1)+')';
        context.fillStyle = numberColor;
        context.fillText(this.backgroundLayers, this.size-8, this.size-5);
      }
      // foreground timer
      if (this.hasTimer) {
        const singleNumber = this.timerValues[0] == this.timerValues[1];
        const timerBackColor = 'rgba(100, 0, 0, 1)';
        context.fillStyle = timerBackColor;
        if (singleNumber) {
          context.fillRect((this.size/2)-5, (this.size/2)-9, 10, 11);
        } else {
          context.fillRect((this.size/2)-11, (this.size/2)-9, 23, 11);
        }
        context.font = '9px Arial';
        context.textAlign = 'center';
        const timerColor = 'rgba(255, 255, 255, 1)';
        context.fillStyle = timerColor;
        if (singleNumber) {
          context.fillText(this.timerValues[0], this.size/2, this.size/2);
        } else {
          context.fillText(this.timerValues[0]+'-'+this.timerValues[1], this.size/2, this.size/2);
        }
      }
      // indicator
      if (this.indicator) {
        context.beginPath();
        context.arc(this.size-11, 11, 8, 0, 2 * Math.PI, false);
        if (this.indicator == 'random') {
          const gradient = context.createLinearGradient(0, 0, 0, 20);
          gradient.addColorStop(0, 'red');
          gradient.addColorStop(3 / 10, 'orange');
          gradient.addColorStop(4 / 10, 'yellow');
          gradient.addColorStop(5 / 10, 'green');
          gradient.addColorStop(6 / 10, 'blue');
          gradient.addColorStop(7 / 10, 'indigo');
          gradient.addColorStop(1, 'violet');
          context.fillStyle = gradient;
        } else {
          context.fillStyle = this.indicator;
        }
        context.fill();
      }
    },
    backgroundPosition(item) {
      if (item.icon !== null) {
        let x = 2 + (item.icon % 10) * 60;
        if (item.type === 'background') {
          x += 600;
        }
        if (['spawnpoint', 'eraser', 'exit'].indexOf(item.type) > -1) {
          x += 1200;
        }
        const y = 62 + Math.floor(item.icon / 10) * 60;
        return [x, y];
      }
      return false;
    },
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.Item {
  position: relative;
}
.SolidBorder {
  border: solid 1px rgb(128, 128, 128);
}
</style>
