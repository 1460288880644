<template>
  <v-container grid-list-md fluid>
    <v-layout v-if="loading"> Loading... </v-layout>
    <v-layout row wrap v-else>
      <v-flex class="ItemCard" @click="addItem" :class="{ Locked: lockedIn }">
        <v-card class="EmptyCard">
          <v-icon dark large v-if="!lockedIn"> add_circle</v-icon>
          <div v-else style="max-width: 80%; margin: auto; text-align: center">
            upgrade your account to create and modify the workings of each game
            element
          </div>
        </v-card>
      </v-flex>
      <ForegroundSheet
        v-for="item in foregrounds"
        :key="'foreground' + item.id"
        class="ItemCard"
        :itemParams="item"
        @onUpdate="updateItem"
      >
      </ForegroundSheet>
    </v-layout>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import bus from '../bus';
import ForegroundSheet from './ForegroundSheet.vue';

export default {
  components: {
    ForegroundSheet,
  },
  data() {
    return {
      loading: true,
      foregrounds: [],
      busEvents: [
        'saveTitle',
        'saveIcon',
        'removeForeground',
      ],
    };
  },
  computed: {
    ...mapGetters(['serverData', 'constants']),
    nextID() {
      let i = 0;
      this.library.foregrounds.forEach((element) => {
        if (element.id > i) {
          i = element.id;
        }
      });
      return i + 1;
    },
    library() {
      return this.serverData.getGame.gameLibrary;
    },
    lockedIn() {
      const accountLockedLib = this.serverData.getAccountInfo.accountInfo.internals.lockedGameLibrary;
      return accountLockedLib;
    },
  },
  created() {
    // Bus events shortcode FGS
    this.busEvents.forEach((element) => {
      bus.$on('FGS-' + element, eval('this.' + element));
    });
    this.getLibrary();
  },
  mounted() {
    const me = this;
    setTimeout(function() {
      me.foregrounds = me.library.foregrounds.filter((el) => el.locked < 3);
      me.foregrounds.map((el) => {
        if (!el.hasOwnProperty('morphIntoIdWhenStruckBySame')) {
          el.morphIntoIdWhenStruckBySame = -1;
        }
      });
      me.loading = false;
    }, 400);
  },
  methods: {
    ...mapActions({
      spawnModal: 'spawnModal',
      saveData: 'saveData',
      showMessage: 'showMessage',
      getLibrary: 'getLibrary',
    }),
    saveIcon(modal) {
      modal.subject.icon = modal.value;
      this.updateItem(modal.subject);
    },
    updateItem(item) {
      for (let i = 0; i < this.foregrounds.length; i++) {
        if (this.foregrounds[i].id == item.id) {
          this.foregrounds[i] = this.globalJSONCopy(item);
        }
      };
      this.library.foregrounds = this.globalJSONCopy(this.foregrounds);
      this.saveData({type: 'game-library'});
    },
    saveTitle(modal) {
      modal.subject.name = modal.value;
      this.updateItem(modal.subject);
    },
    removeForeground(item) {
      this.foregrounds = this.foregrounds.filter((el) => el.id != item.id);
      this.library.foregrounds = JSON.parse(JSON.stringify(this.foregrounds));
      this.saveData({type: 'game-library'});
    },
    addItem() {
      this.foregrounds.unshift({
        id: this.nextID,
        name: 'Foreground ' + this.nextID,
        icon: this.nextID,
        hasColor: false,
        swappable: false,
        triggerExplodes: false,
        triggerReplaceUnit: 0,
        replaceId: 0,
        explosion: 0,
        locked: 0,
        elemLocks: [],
        colorSwitch: 0,
        morphIntoIdWhenStruckBySame: -1,
      });
      this.library.foregrounds = this.globalJSONCopy(this.foregrounds);
      this.saveData({type: 'game-library'});
      this.showMessage({type: 'success', text: 'New foreground created.'});
    },
  },
  destroyed() {
    this.busEvents.forEach((element) => {
      bus.$off('FGS-' + element);
    });
  },
};
</script>

<style scoped>
  .ItemCard {
    flex: 0 0 310px;
  }
  .ItemCard.Locked {
    pointer-events: none;
  }
</style>
