<template>
    <div style="display:flex; position: fixed; bottom: 20px; width: 100%; left:0">
        <v-card
        class="Dialog"
        light
        >
          <v-card-text>
            {{params.message}}
          </v-card-text>
          <v-card-actions>
            <v-btn
            @click="clickCancel"
            flat
            >Cancel</v-btn>
            <v-btn
            v-if="params.okText"
            @click="clickOk"
            :disabled="params.okDisabled"
            :light="params.okDisabled"
            :dark="!params.okDisabled"
            >{{params.okText}}</v-btn>
          </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import bus from '../bus';
import {mapActions} from 'vuex';

export default {
  props: {
    params: Object,
  },
  methods: {
    ...mapActions({
      cancelStatusDialog: 'cancelStatusDialog',
    }),
    clickOk() {
      bus.$emit(this.params.ok);
    },
    clickCancel() {
      this.cancelStatusDialog();
    },
  },
};
</script>

<style>
.Dialog{
  display: flex;
  margin: auto;
}
</style>
