<template>
  <v-card>
    <v-card-title primary-title>
      <div class="Header">
        <img src="/images/v.png" alt class="Logo">
        <div class="headline">Login</div>
      </div>
    </v-card-title>
    <v-container>
      <v-form ref="form" v-model="valid">
        <v-text-field v-model="email" :rules="emailRules"
                      label="E-mail" required></v-text-field>
        <v-text-field
          v-model="password"
          label="Password"
          :rules="passRules"
          :type="'password'"
          @keypress.enter="submit"
          required
        ></v-text-field>
      </v-form>
    </v-container>
    <v-card-actions>
      <!-- <v-btn flat small>forgot password</v-btn> -->
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="submit" :disabled="!valid"
             :loading="loading">Login</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      valid: false,
      email: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'E-mail must be valid',
      ],
      password: '',
      passRules: [(v) => !!v || 'Password is required'],
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      'serverData',
    ]),
  },
  mounted() {
    const authToken = Cookies.get('authenticationToken');
    if (authToken) {
      this.serverData.getAuthToken.authenticationToken = authToken;
      this.afterGetAuthToken();
    }
  },
  methods: {
    ...mapActions({
      postDataPromise: 'postDataPromise',
      spawnModal: 'spawnModal',
      getAuthToken: 'getAuthToken',
    }),
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.getAuthToken({
          userName: this.email,
          password: this.password,
        }).then(this.afterGetAuthToken).catch((response) => {
          this.loading = false;
        });
      }
    },
    afterGetAuthToken() {
      this.loading = false;
      const response = this.serverData.getAuthToken;
      Cookies.set('authenticationToken', response.authenticationToken, {expires: 1});
      this.spawnModal({
        component: 'ModalGamesBrowse',
        title: 'Select game to load',
        closebutton: true,
      });
      this.$router.push({path: '/'});
    },
  },
};
</script>

<style scoped>
.Header {
  width: 100%;
}
.Logo {
  display: block;
  margin: 0 auto 50px;
}
.v-card {
  width: 400px;
}
</style>
