<template>
    <v-flex>
        <v-card color="card">
            <v-card-title class="title">
            <div
                :class="{
                Editable: item.locked < 2,
                Locked: item.locked >= 2 || lockedIn,
                }"
                @click="editTitle"
            >
                {{ item.name }}
            </div>
            </v-card-title>
            <v-card-text>
            <div
                class="IconHolder"
                :class="{ Locked: item.locked >= 2 || lockedIn }"
                @click="browseIcon"
            >
                <GridItem
                :items="gridItemsArray"
                :inaccessible="item.id == 1"
                />
            </div>
            <div>
                <v-switch
                label="Background swappable"
                v-model="item.backgroundSwappable"
                @change="updateItem"
                :disabled="editDisabled"
                ></v-switch>
            </div>
            <div>
                <v-switch
                label="Has foreground"
                v-model="item.hasForeground"
                @change="updateItem"
                :disabled="editDisabled || item.elemLocks.length > 0"
                ></v-switch>
            </div>
            <div>
              <v-switch
                label="Foreground swappable"
                v-model="item.foregroundSwappable"
                @change="updateItem"
                :disabled="editDisabled"
              ></v-switch>
            </div>
            <div>
              <v-switch
                label="Foreground matchable"
                v-model="item.foregroundMatchable"
                @change="updateItem"
                :disabled="editDisabled"
              ></v-switch>
            </div>
            <div>
              <v-switch
                label="Obscure foreground"
                v-model="item.obscureFg"
                @change="updateItem"
                :disabled="editDisabled"
              ></v-switch>
            </div>
            <div>
                <v-switch
                label="Only background explodes"
                v-model="item.onlyBackgroundExplodes"
                @change="updateItem"
                :disabled="editDisabled"
                ></v-switch>
            </div>
            <div>
                <v-switch
                label="Regrow"
                v-model="item.regrow"
                @change="updateItem"
                :disabled="editDisabled"
                ></v-switch>
            </div>
            <div>
                <v-switch
                label="Regrow target "
                v-model="item.regrowTarget"
                @change="updateItem"
                :disabled="editDisabled"
                ></v-switch>
            </div>
            <div>
                <v-switch
                label="Carpet"
                v-model="item.carpet"
                @change="updateItem"
                :disabled="editDisabled"
                ></v-switch>
            </div>
            <div>
                <v-switch
                label="Reset layers"
                v-model="item.resetLayers"
                @change="updateItem"
                :disabled="editDisabled"
                ></v-switch>
            </div>
            <div>
                <v-switch
                label="Respawn"
                v-model="item.respawn"
                @change="updateItem"
                :disabled="editDisabled"
                ></v-switch>
            </div>
            <v-combobox
                v-model="hitType"
                :items="constants.hitTypes"
                label="Hit type"
                multiple
                chips
                @change="updateItem"
                :disabled="editDisabled"
            ></v-combobox>
            <v-select
              :items="constants.hitTypeColors"
              item-text="text"
              item-value="value"
              label="by color"
              v-model="item.hitByColor"
              @change="updateItem"
              :disabled="editDisabled"
            ></v-select>
            <v-select
              :items="explosions"
              item-text="text"
              item-value="value"
              label="Explosion"
              v-model="item.explosion"
              @change="updateItem"
              :disabled="editDisabled"
            ></v-select>
            <v-slider
                :label="`Max layers: ${item.maxLayers}`"
                v-model="item.maxLayers"
                color="white"
                :min="minLayers"
                :max="5"
                @change="updateItem"
                :disabled="editDisabled || layersDisabled"
            ></v-slider>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                flat
                @click="showElemLocks"
                v-if="item.elemLocks.length > 0"
                icon
            >
                <v-icon small color="accent">warning</v-icon>
            </v-btn>
            <v-btn
                flat
                @click="askRemoveBackground"
                :disabled="deleteDisabled"
            >
                <v-icon small class="mr-2">delete</v-icon>DELETE
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-flex>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import GridItem from './GridItem.vue';

export default {
  props: {
    itemParams: Object,
  },
  components: {
    GridItem,
  },
  data() {
    return {
      item: this.globalJSONCopy(this.itemParams),
      hitType: [],
    };
  },
  computed: {
    ...mapGetters([
      'serverData',
      'constants',
    ]),
    explosions() {
      return this.constants.explosions;
    },
    hitTypeArray() {
      const res = [];
      for (let i=0; i<this.constants.hitTypes.length; i++) {
        const found = this.hitType.find((el) => el.value == this.constants.hitTypes[i].value);
        res.push(found ? 1: 0);
      }
      return res;
    },
    explodeMethods() {
      return this.constants.explodeMethods;
    },
    editDisabled() {
      return this.item.locked > 1 || this.lockedIn;
    },
    gridItemsArray() {
      return [{icon: this.item.icon, type: 'background', classes: 'LibraryItem'}];
    },
    deleteDisabled() {
      if (this.item.locked > 0 || this.lockedIn) {
        return true;
      }
      if (this.item.elemLocks.length > 0) {
        return true;
      }
      return false;
    },
    layersDisabled() {
      const hasElemLocks = this.item.elemLocks.length > 0;
      return hasElemLocks && this.item.minLayers == -1;
    },
    minLayers() {
      return this.item.minLayers > 0 ? this.item.minLayers : 1;
    },
    lockedIn() {
      return this.serverData.getAccountInfo.accountInfo.internals.lockedGameLibrary;
    },
  },
  created() {
    if (this.item.hasOwnProperty('hitType')) {
      for (let i=0; i<this.item.hitType.length; i++) {
        if (this.item.hitType[i] == 1) {
          this.hitType.push(this.constants.hitTypes[i]);
        }
      }
    }
  },
  methods: {
    ...mapActions({
      spawnModal: 'spawnModal',
      showMessage: 'showMessage',
    }),
    updateItem() {
      const itemCopy = this.globalJSONCopy(this.item);
      itemCopy.hitType = this.hitTypeArray;
      this.$emit('onUpdate', itemCopy);
    },
    browseIcon() {
      if (this.item.locked < 2) {
        this.spawnModal({
          component: 'ModalIconSelector',
          title: 'Select background icon',
          busevent: 'BKG-saveIcon',
          subject: {item: this.item, type: 'background'},
        });
      }
    },
    backgroundPosition(id) {
      const x = -602 - ((id * 60) % 600);
      const y = -660 - Math.floor(id / 10) * 60;
      return x + 'px ' + y + 'px';
    },
    editTitle() {
      this.spawnModal({
        component: 'ModalTextInput',
        title: 'Background title',
        label: 'Title',
        text: this.item.name,
        busevent: 'BKG-saveTitle',
        subject: this.item,
      });
    },
    askRemoveBackground() {
      this.spawnModal({
        component: 'ModalConfirm',
        title: 'Delete background',
        busevent: 'BKG-removeBackground',
        text: 'Are you sure you want to delete '+this.item.name+'?',
        subject: this.item,
      });
    },
    showElemLocks() {
      this.spawnModal({
        component: 'ModalElemLocks',
        title: 'Element used in following levels',
        subject: {item: this.item},
      });
    },
  },
};
</script>

<style scoped>
.IconHolder {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
  padding: 10px;
}
</style>
