<template>
  <v-container grid-list-md fluid>
    <v-layout v-if="loading">
      Loading...
    </v-layout>
    <v-layout row wrap v-else>
      <v-flex v-for="(item, index) in matchTypesList" :key="'matchType+'+index"
              class="ItemCard">
        <v-card color="card">
          <v-card-title>
            <div><v-switch :label="item.name" v-model="item.active"
            @change="updateItem(item)" :disabled="editdisabled(item)"></v-switch></div>
          </v-card-title>
          <v-card-text>
            <div><v-select
            :items="matchTypeRewards"
            label="Reward"
            v-model="item.reward"
            @change="updateItem(item)"
            :disabled="!item.active || lockedIn"
            ></v-select></div>
            <div class="Shape">
              <div
                class="Block"
                v-for="(block, index) in item.setup.split('')"
                :key="'block'+index"
              >
                <div class="Fill" :class="{'Inactive':!item.active}"
                     v-if="block == 1"></div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data() {
    return {
      loading: true,
      matchTypesList: [],
    };
  },
  computed: {
    ...mapGetters([
      'serverData',
      'constants',
    ]),
    lockedIn() {
      const accountLockedLib = this.serverData.getAccountInfo.accountInfo.internals.lockedGameLibrary;
      return accountLockedLib;
    },
    library() {
      return this.serverData.getGame.gameLibrary;
    },
    matchTypeRewards() {
      const list = [
        {
          value: 0,
          text: 'None',
        },
      ];
      if (this.library) {
        for (let i = 0; i < this.library.foregrounds.length; i++) {
          const el = this.library.foregrounds[i];
          list.push({
            value: el.id,
            text: el.name,
          });
        }
      }
      return list;
    },
  },
  mounted() {
    this.getLibrary();
    const me = this;
    setTimeout(function() {
      for (let i = 0; i < me.constants.matchTypes.length; i++) {
        const el = me.constants.matchTypes[i];
        el.active = me.matchTypeProps(el).active;
        el.reward = me.matchTypeProps(el).reward;
        me.matchTypesList.push(el);
      };
      me.loading = false;
    }, 400);
  },
  methods: {
    ...mapActions({
      saveData: 'saveData',
      getLibrary: 'getLibrary',
    }),
    editdisabled(item) {
      return item.locked > 1 || this.lockedIn;
    },
    updateItem(item) {
      const obj = {
        fixedMatchTypeIndex: item.id,
        reward: item.reward,
      };
      const index = this.library.matchTypes.findIndex((elm) => {
        return elm.fixedMatchTypeIndex == obj.fixedMatchTypeIndex;
      });
      if (item.active) {
        // matchtype is used
        if (index > -1) {
          // matchtype exists
          this.library.matchTypes[index] = obj;
        } else {
          // matctype does not exist
          this.library.matchTypes.unshift(obj);
        }
      } else {
        // matchtype not used, so remove
        const newList = this.library.matchTypes.filter((el) => el.fixedMatchTypeIndex != item.id);
        this.library.matchTypes = newList;
        this.saveData({type: 'game-library'});
      }
      this.saveData({type: 'game-library'});
    },
    matchTypeProps(item) {
      const obj = {active: false, reward: 0};
      const index = this.library.matchTypes.findIndex((elm) => {
        return elm.fixedMatchTypeIndex == item.id;
      });
      const found = index > -1;
      obj.active = found;
      if (found) {
        obj.reward = this.library.matchTypes[index].reward;
      }
      return obj;
    },
  },
};
</script>

<style scoped>
.Shape {
  line-height: 0;
  width: 175px;
}
.Block {
  border: dotted 1px rgba(0, 0, 0, 0.2);
  width: 35px;
  height: 35px;
  display: inline-block;
  border-right: none;
  border-bottom: none;
}
.Block:nth-child(5n) {
  border-right: dotted 1px rgba(0, 0, 0, 0.2);
}
.Block:nth-child(n + 20) {
  border-bottom: dotted 1px rgba(0, 0, 0, 0.2);
}
.Block .Fill {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
}
.Block .Fill.Inactive {
  background-color: #bdbdbd;
}
.ItemCard {
  flex: 0 0 232px;
}
</style>
