<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs4>
        <v-card width="100%">
          <v-toolbar color="primary">
            <v-toolbar-title>{{ modal.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12>
                     <v-text-field
                        :value="elementName"
                        label="Name"
                        :error="alerts.name.show"
                        :error-messages="alerts.name.message"
                        disabled
                      ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <div><v-select :items="types" label="Type" v-model="elementType"></v-select></div>
                  </v-flex>
                  <v-flex xs12 v-if="elementType">
                    Element
                    <div
                      class="GridItems"
                    >
                      <div
                        v-for="(item, index) in items"
                        :key="'griditem-' + index + '-' + refresh"
                        class="SpriteGridBlock"
                        :class="{Selected: isElementActive(item)}"
                      >
                        <GridItem
                          :items="gridItemData(item)"
                          @click="setElementActive(item)"
                        />
                        <div class="ToolTip">{{item.name}}</div>
                      </div>
                      <div v-if="items.length == 0">No elements found in library.</div>
                    </div>
                    <v-alert type="error" :value="alerts.items.show" outline>{{alerts.items.message}}</v-alert>
                  </v-flex>
                  <v-flex xs12 v-if="elementType == 'foreground'">
                    Color
                    <div class="GridItems Colorz" :class="{'Enabled':colorEnabled}">
                      <div
                        v-for="(color, i) in availableColors"
                        :key="'color-'+i"
                        class="ConditionColor"
                        @click="setColorActive(color)"
                        :class="{Selected: isColorActive(color)}"
                        >
                          <GridItem
                            :items="[{color: colorToId(color), icon: 63, type: 'foreground'}]"
                          />
                          <div class="ToolTip">{{color}}</div>
                        </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat @click="closeModal(modal.id)">Cancel</v-btn>
            <v-btn color="primary" @click="save" :disabled="isSaveDisabled">
              Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import bus from '../bus';
import GridItem from './GridItem.vue';
import {mapGetters, mapActions} from 'vuex';

export default {
  components: {
    GridItem,
  },
  props: {
    modal: {
      type: Object,
    },
  },
  data() {
    return {
      elementData: this.globalJSONCopy(this.modal.elementData),
      elementType: '',
      maxSetting: 0,
      refresh: 0,
      alerts: {
        name: {
          show: false,
          message: '',
        },
        items: {
          show: false,
          message: '',
        },
      },
    };
  },
  created() {
    if (this.elementData.element != null) {
      if (this.elementData.element.hasOwnProperty('background')) {
        this.elementType = 'background';
      }
      if (this.elementData.element.hasOwnProperty('foreground')) {
        this.elementType = 'foreground';
      }
    } else {
      this.elementData.element = {};
    }
  },
  watch: {
    elementType(val) {
      if (val == 'foreground') {
        if (this.elementData.element.hasOwnProperty('foreground')) {
          this.elementData.element.foreground = this.elementData.element.foreground;
        } else {
          this.elementData.element = {foreground: 1, color: -1};
        }
      }
      if (val == 'background') {
        if (this.elementData.element.hasOwnProperty('background')) {
          this.elementData.element.background = this.elementData.element.background;
        } else {
          this.elementData.element = {background: 0};
        }
      }
      this.clearAlerts();
    },
  },
  computed: {
    ...mapGetters([
      'constants',
      'serverData',
    ]),
    isSaveDisabled() {
      const hasFG = this.elementData.element.hasOwnProperty('foreground');
      const hasBG = this.elementData.element.hasOwnProperty('background') && this.elementData.element.background > 1;
      const hasEl = hasFG || hasBG;
      return !hasEl;
    },
    types() {
      return ['foreground', 'background'];
    },
    library() {
      return this.serverData.getGame.gameLibrary;
    },
    availableColors() {
      const myColors = this.constants.fgColors.slice(0, 5);
      myColors.shift();
      myColors.unshift('random');
      return myColors;
    },
    colorEnabled() {
      const element = this.elementData.element;
      if ('foreground' in element) {
        return this.items.find((el) => el.id === element.foreground).hasColor;
      }
      return false;
    },
    elementName() {
      let elementName = '';
      const element = this.elementData.element;
      if (this.elementData.element == null) {
        return '';
      }
      if ('foreground' in element) {
        elementName = ' '+this.library.foregrounds.find((el) => el.id === element.foreground).name;
      }
      if ('background' in element) {
        elementName = ' '+this.library.backgrounds.find((el) => el.id === element.background).name;
      }
      return elementName;
    },
    items() {
      let payload = [];
      if (this.elementType == 'foreground') {
        payload = this.globalJSONCopy(this.library.foregrounds);
        payload.map((el) => el.type = 'foreground');
      }
      if (this.elementType == 'background') {
        payload = this.globalJSONCopy(this.library.backgrounds);
        payload.map((el) => el.type = 'background');
      }
      if (payload) {
        payload = payload.filter((el) => {
          const isEmpty = el.id == 0;
          const isInaccesible = el.type == 'background' && el.id == 1;
          return !isEmpty && !isInaccesible;
        });
      }
      return payload;
    },
  },
  methods: {
    ...mapActions({
      closeModal: 'closeModal',
      spawnModal: 'spawnModal',
    }),
    clearAlerts() {
      this.alerts.name.show = false;
      this.alerts.name.message = '';
      this.alerts.items.show = false;
      this.alerts.items.message = '';
    },
    save() {
      // clear alerts
      this.clearAlerts();
      if (!this.alerts.name.show && !this.alerts.items.show) {
        // all checks passed
        const elementData = this.globalJSONCopy(this.elementData);
        bus.$emit(this.modal.saveEvent, {
          index: this.modal.index,
          data: elementData,
        });
        this.closeModal(this.modal.id);
      }
    },
    setElementActive(item) {
      const data = {};
      if (this.elementType == 'foreground') {
        data.foreground = item.id;
        data.color = 0;
      }
      if (this.elementType == 'background') {
        data.background = item.id;
      }
      this.elementData.element = data;
      this.refresh++;
    },
    isElementActive(item) {
      const el = this.elementData.element;
      if ('foreground' in el) {
        return el.foreground == item.id;
      }
      if ('background' in el) {
        return el.background == item.id;
      }
    },
    setColorActive(color) {
      const conditionElement = this.elementData.element;
      if ('foreground' in conditionElement) {
        conditionElement.color = this.constants.fgColors.findIndex((el) => el === color);
        if (conditionElement.color == 0) {
          delete conditionElement.color;
        }
      }
      this.refresh++;
    },
    colorToId(color) {
      if (color == 'random') {
        return -1;
      }
      return this.constants.fgColors.findIndex((el) => el === color);
    },
    isColorActive(color) {
      const colorIndex = this.constants.fgColors.findIndex((el) => el === color);
      if (this.elementData.element.color == undefined) {
        if (color == 'none') {
          return true;
        }
        return false;
      }
      return this.elementData.element.color == colorIndex;
    },
    gridItemData(item) {
      return [
        {
          icon: item.icon,
          type: item.type,
          id: item.id,
        },
      ];
    },
  },
};
</script>

<style scoped>
.v-card {
  width: 640px;
}
.GridItems {
  display: flex;
  flex-wrap: wrap;
}
.SpriteGridBlock,
.ConditionColor {
  border: solid 1px gray;
  width: 60px;
  height: 60px;
  margin: 3px;
  cursor: pointer;
  flex: 0 0 60px;
  position: relative;
}
.SpriteGridBlock:hover,
.ConditionColor:hover {
  background-color: var(--secondary-color);
}
.SpriteGridBlock.Selected,
.ConditionColor.Selected {
  background-color: var(--primary-color);
}
.ToolTip {
  position: absolute;
  top: -27px;
  left: -1px;
  white-space: nowrap;
  background-color: #393939;
  padding: 2px 5px;
  border: solid 1px rgba(255,255,255,.3);
  display: none;
}
.SpriteGridBlock:hover .ToolTip,
.ConditionColor:hover .ToolTip
{
  display: block;
}
.ConditionColor .Color{
  width:50px;
  height:50px;
  border-radius: 50%;
  margin:5px;
  border:solid 2px white;
}
.Colorz{
  opacity:.3;
  pointer-events: none;
}
.Colorz.Enabled{
  opacity:1;
  pointer-events:unset;
}
</style>
