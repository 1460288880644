<template>
  <v-container style="width:100%;max-width:100%">
    <v-layout align-center justify-center>
      <v-flex>
        <v-card>
          <v-toolbar dark color="secondary">
            <div class="text-xs-center">
              <v-menu offset-y>
                <v-btn slot="activator" icon>
                  <v-icon>menu</v-icon>
                </v-btn>
                <v-list>
                  <v-list-tile @click="clickAddLevels">
                    <v-list-tile-action>
                        <v-icon slot="activator">add_box</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Add blank levels</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile @click="clickAddLevelsFrom" :disabled="this.levelsSummaryFiltered.length == 0">
                    <v-list-tile-action>
                        <v-icon slot="activator">library_add</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Copy levels</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile @click="clickMoveLevels" :disabled="this.levelsSummaryFiltered.length == 0">
                    <v-list-tile-action>
                        <v-icon slot="activator">move_down</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Move levels</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-list-tile @click="askDeleteGroup">
                    <v-list-tile-action>
                        <v-icon slot="activator">delete</v-icon>
                    </v-list-tile-action>
                    <v-list-tile-content>
                      <v-list-tile-title>Delete group</v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </div>
            <v-toolbar-title @click="editGroupName" class="groupName">
              {{levelSettings.name}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#BF5B16"
              @click="forwardtest"
              :disabled="generateLocked ||
              groupGenerateInfo.descr == 'generating' ||
              canGenerateCount == 0">Forward Test</v-btn>
            <GenerateButton
            :info="groupGenerateInfo"
            class="mr-5"
            :generateText="generateText"
            :canGenerate="true"
            @generate="generate"
            :disabled="canGenerateCount == 0"
            ></GenerateButton>
            <v-btn icon @click="closeModal(modal.id)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text v-if="loaded">
            <v-layout row>
              <v-flex pa-2 style="flex:0 0 320px;max-width:320px">
                <div class="CardShadow">
                  <div class="panelBox">
                    <div>Grid size horizontal:
                      {{horSize[0]}} - {{horSize[1]}}</div>
                    <v-range-slider v-model="horSize" color="white" min="7" max="12"
                    @change="saveGroup" :disabled="lockedIn">
                    </v-range-slider>
                    <div>Grid size vertical:
                      {{vertSize[0]}} - {{vertSize[1]}}</div>
                    <v-range-slider v-model="vertSize" color="white" min="7" max="12"
                    @change="saveGroup" :disabled="lockedIn">
                    </v-range-slider>
                    <div>Colors: {{colors[0]}} - {{colors[1]}}</div>
                    <v-range-slider v-model="colors" color="white" min="4" max="9" @change="saveGroup"
                    :disabled="lockedIn"></v-range-slider>
                  </div>
                  <div
                  class="SectionName"
                  :class="{'closed':cookies.loseCondition}"
                  @click="toggleClosed('loseCondition')">Lose condition</div>
                  <div class="panelBox" :class="{'closed':cookies.loseCondition}">
                    <div>Turns: {{loseTurns[0]}} - {{loseTurns[1]}}</div>
                    <v-range-slider v-model="loseTurns" color="white" min="5" max="40" @change="saveGroup">
                    </v-range-slider>
                  </div>
                  <div
                  class="SectionName"
                  :class="{'closed':cookies.winConditions}"
                  @click="toggleClosed('winConditions')">Win conditions</div>
                    <div class="panelBox" :class="{'closed':cookies.winConditions}">
                    <div>Win conditions: {{winConditions[0]}} - {{winConditions[1]}}</div>
                    <v-range-slider v-model="winConditions" color="white" min="1" max="4" @change="saveGroup">
                    </v-range-slider>
                    <v-card
                    @click="addWinCondition"
                    class="EmptyCard AddTrigger"
                    v-if="triggerItems.length < 20">
                      <v-icon>add_circle</v-icon>
                    </v-card>
                    <v-alert value="true" type="warning" v-else>Maximum win conditions reached</v-alert>
                    <div v-for="(item, index) in triggerItems"
                    :key="'trigger-item-row'+index"
                    class="TriggerButton v-card"
                    @click="editTriggerModal(item, index)">
                      <GridItem
                      v-if="triggerIcon(item)"
                      :items="triggerIcon(item)"
                      :key="'tic-'+index"
                      :size="30"/>
                      <div class="Name">{{item.name}}</div>
                      <div class="AlwaysUse">
                        <v-icon v-if="item.alwaysUse">task_alt</v-icon>
                      </div>
                    </div>
                  </div>
                  <div class="SectionName"
                  :class="{'closed':cookies.blockingElements}"
                  @click="toggleClosed('blockingElements')">Blocking elements</div>
                  <div class="panelBox" :class="{'closed':cookies.blockingElements}">
                    <div>Blocking elements: {{blockingElements[0]}} - {{blockingElements[1]}}</div>
                    <v-range-slider v-model="blockingElements" color="white" min="0" max="4" @change="saveGroup">
                    </v-range-slider>
                    <v-card
                    @click="addBlockingElement"
                    class="EmptyCard AddTrigger"
                    v-if="blockingItems.length < 20">
                      <v-icon>add_circle</v-icon>
                    </v-card>
                    <v-alert value="true" type="warning" v-else>Maximum blocking elements reached</v-alert>
                    <div v-for="(item, index) in blockingItems"
                    :key="'trigger-item-row'+index"
                    class="TriggerButton v-card"
                    @click="editBlockingElementModal(item, index)">
                      <GridItem
                      v-if="blockerIcon(item)"
                      :items="blockerIcon(item)"
                      :key="'tic-'+index"
                      :size="30"/>
                      <div class="Name">{{blockerName(item)}}</div>
                      <div class="AlwaysUse">
                        <v-icon v-if="item.alwaysUse">task_alt</v-icon>
                      </div>
                    </div>
                  </div>
                  <div class="SectionName"
                  :class="{'closed':cookies.gameFlowBuilder}"
                  @click="toggleClosed('gameFlowBuilder')">Gameflow builder</div>
                  <div class="panelBox" :class="{'closed':cookies.gameFlowBuilder}">
                    <div>Starting point</div>
                    <div class="d-flex">
                      <v-slider
                      v-model="gameFlowBuilder.startingPoint"
                      class="ma-0 pa-0"
                      color="white"
                      :min="0"
                      :max="maxStartingPoint"
                      thumb-color="orange"
                      :disabled="lockedIn"
                      @change="saveGroup"
                      ></v-slider>
                      <div class="ml-2 mt-1 subheading">{{gameFlowBuilder.startingPoint}}</div>
                    </div>
                    <div>Onboarding levels</div>
                    <div class="d-flex">
                      <v-slider
                      v-model="gameFlowBuilder.onboardingLevels"
                      class="ma-0 pa-0"
                      color="white"
                      :min="0"
                      :max="200"
                      thumb-color="primary"
                      :disabled="lockedIn"
                      @change="saveGroup"
                      ></v-slider>
                      <div class="ml-2 mt-1 subheading">{{gameFlowBuilder.onboardingLevels}}</div>
                    </div>
                    <div>Turn increase</div>
                    <div class="d-flex">
                      <v-slider
                      v-model="gameFlowBuilder.turnIncrease"
                      class="ma-0 pa-0"
                      color="white"
                      :min="0"
                      :max="50"
                      thumb-color="primary"
                      :disabled="lockedIn"
                      @change="saveGroup"
                      ></v-slider>
                      <div class="ml-2 mt-1 subheading">{{gameFlowBuilder.turnIncrease}}</div>
                    </div>
                    <div>Starting difficulty</div>
                    <div class="d-flex">
                      <v-slider
                      v-model="gameFlowBuilder.startingDifficulty"
                      class="ma-0 pa-0"
                      color="white"
                      :min="10"
                      :max="90"
                      thumb-color="primary"
                      :disabled="lockedIn"
                      @change="saveGroup"
                      ></v-slider>
                      <div class="ml-2 mt-1 subheading">{{gameFlowBuilder.startingDifficulty}}</div>
                    </div>
                    <div>Boss difficulty increase</div>
                    <div class="d-flex">
                      <v-slider
                      v-model="gameFlowBuilder.bossDifficultyIncrease"
                      class="ma-0 pa-0"
                      color="white"
                      :min="10"
                      :max="90"
                      thumb-color="primary"
                      :disabled="lockedIn"
                      @change="saveGroup"
                      ></v-slider>
                      <div class="ml-2 mt-1 subheading">{{gameFlowBuilder.bossDifficultyIncrease}}</div>
                    </div>
                    <div>Boss frequency</div>
                    <div class="d-flex">
                      <v-slider
                      v-model="gameFlowBuilder.bossFrequency"
                      class="ma-0 pa-0"
                      color="white"
                      :min="2"
                      :max="25"
                      thumb-color="primary"
                      :disabled="lockedIn"
                      @change="saveGroup"
                      ></v-slider>
                      <div class="ml-2 mt-1 subheading">{{gameFlowBuilder.bossFrequency}}</div>
                    </div>
                    <div>Each boss increase</div>
                    <div class="d-flex">
                      <v-slider
                      v-model="gameFlowBuilder.eachBossIncrease"
                      class="ma-0 pa-0"
                      color="white"
                      :min="-80"
                      :max="90"
                      thumb-color="primary"
                      :disabled="lockedIn"
                      @change="saveGroup"
                      ></v-slider>
                      <div class="ml-2 mt-1 subheading">{{gameFlowBuilder.eachBossIncrease}}</div>
                    </div>
                    <div>Super boss difficulty increase</div>
                    <div class="d-flex">
                      <v-slider
                      v-model="gameFlowBuilder.superBossDifficultyIncrease"
                      class="ma-0 pa-0"
                      color="white"
                      :min="0"
                      :max="90"
                      thumb-color="primary"
                      :disabled="lockedIn"
                      @change="saveGroup"
                      ></v-slider>
                      <div class="ml-2 mt-1 subheading">{{gameFlowBuilder.superBossDifficultyIncrease}}</div>
                    </div>
                    <div>Super boss frequency</div>
                    <div class="d-flex">
                      <v-slider
                      v-model="gameFlowBuilder.superBossFrequency"
                      class="ma-0 pa-0"
                      color="white"
                      :min="2"
                      :max="25"
                      thumb-color="primary"
                      :disabled="lockedIn"
                      @change="saveGroup"
                      ></v-slider>
                      <div class="ml-2 mt-1 subheading">{{gameFlowBuilder.superBossFrequency}}</div>
                    </div>
                  </div>
                </div>
              </v-flex>
              <v-layout wrap align-content-start>
                  <div v-if="levelsSummaryFiltered.length == 0" style="flex: 1 1 100%" class="pa-4">
                    You have not created any levels yet.<br>
                    Click on the menu icon on the upper left and select "Add blank levels".
                 </div>
                  <div style="display:flex; padding: 20px" v-if="levelsSummaryFiltered.length > 0">
                    <div class="LegendBlock">
                      <div style="background-color: #AE4592" class="LegendColor"></div>
                      <div>Target difficulty</div>
                    </div>
                    <div class="LegendBlock">
                      <div style="background-color: #4CAF50" class="LegendColor"></div>
                      <div>Calculated difficulty</div>
                    </div>
                    <div class="LegendBlock">
                      <div style="background-color: orange" class="LegendColor"></div>
                      <div>Turn increase per level</div>
                    </div>
                  </div>
                  <div class="pa-4" style="overflow-x:auto;width:100%;" v-if="levelsSummaryFiltered.length > 0">
                    <b>Gameflow difficulty</b>
                    <div :style="{'position':'relative','width':chartWidth+'px','height':'350px'}">
                      <canvas id="groupChart" :width="chartWidth" height="350"></canvas>
                    </div>
                  </div>
                  <div style="width:100%;padding-left:10px" v-if="levelsSummaryFiltered.length > 0">
                    <v-combobox
                      v-model="filtering"
                      :items="filters"
                      label="Filter levels"
                      multiple
                      chips
                      style="max-width:300px"
                    ></v-combobox>
                  </div>
                  <div
                  v-for="(item, index) in levelsSummaryFiltered"
                  class="BlockHolder"
                  :key="'level-'+item.displayId"
                  >
                    <div
                    class="betweenBlock"
                    :class="{'active': selectMode=='between'}"
                    :key="'betweenBlock-'+item.levelId"
                    @click="clickBetweenBlock(index-1)"
                    ></div>
                    <v-card
                    @click="clickLevel(item)"
                    class="LevelBlock"
                    :class="{
                      'disabled': selectMode=='between',
                      'selector': selectMode=='block',
                      'selected': isSelected(item.levelId)
                    }"
                    >
                      <v-card-title primary-title class="CardTitle" style="padding:10px">
                        <div style="margin:10px 5px">
                          Level: {{game.gameSettings.levelNumberingStartsAt + item.displayId}}
                          </div>
                        <v-spacer></v-spacer>
                        <GenerateButton
                        :info="itemGenerateInfo(item)"
                        :key="item.levelId"
                        ></GenerateButton>
                      </v-card-title>
                      <v-card-text class="CardContent">
                        Last modified: {{item.lastModified}}
                        <div class="pa-1"></div>
                        <div style="display:flex">
                          <div class="RoundedBox" style="width:100%">
                              Target diff:<br>
                            <v-progress-circular
                            :rotate="90"
                            :size="50"
                            :width="5"
                            :value="item.targetDifficulty"
                            color="#AE4592"
                            class="mt-2"
                            >
                            {{ item.targetDifficulty }}
                            </v-progress-circular>
                          </div>
                          <div class="RoundedBox" style="width:100%">
                            Calculated diff:<br>
                            <v-progress-circular
                            :rotate="90"
                            :size="50"
                            :width="5"
                            :value="item.calculatedDifficulty"
                            color="#4CAF50"
                            class="mt-2"
                            >
                            {{ item.calculatedDifficulty }}
                            </v-progress-circular>
                          </div>
                        </div>
                        <!-- <img
                        v-if="internals.levelImages[item.levelId] != 'undefined'"
                        :src="'https://cdn.basisam.nl/images/pyzomath/'+internals.levelImages[item.levelId]"
                        alt=""
                        style="width:100%"> -->
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="BlockHolder">
                    <div
                    class="betweenBlock"
                    :class="{'active': selectMode=='between'}"
                    @click="clickBetweenBlock(levelsSummaryFiltered.length-1)"
                    ></div>
                  </div>
              </v-layout>
            </v-layout>
          </v-card-text>
          <v-card-text v-else>
            <v-progress-linear indeterminate></v-progress-linear>
          </v-card-text>
          <div class="Fabs">
            <v-btn
            color="orange"
            fab
            small
            @click="clickMoveLevels"
            :disabled="this.levelsSummaryFiltered.length == 0"
            >
              <v-icon>move_down</v-icon>
            </v-btn>
            <v-btn
            color="blue"
            fab
            small
            @click="clickAddLevelsFrom"
            :disabled="this.levelsSummaryFiltered.length == 0"
            >
              <v-icon>library_add</v-icon>
            </v-btn>
            <v-btn
            color="green"
            fab
            @click="clickAddLevels"
            >
              <v-icon>add_box</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <StatusDialog
    v-if="statusDialog.message"
    :params="statusDialog"
    ></StatusDialog>
    <Loader v-if="internals.loader"></Loader>
  </v-container>
</template>

<script>
import bus from '../bus';
import {mapGetters, mapActions} from 'vuex';
import GenerateButton from './GenerateButton.vue';
import StatusDialog from './StatusDialog.vue';
import Loader from './Loader.vue';
import GridItem from './GridItem.vue';
import Cookies from 'js-cookie';
import Chart from 'chart.js';

export default {
  components: {
    GridItem,
    GenerateButton,
    StatusDialog,
    Loader,
  },
  props: {
    modal: {
      type: Object,
    },
  },
  data() {
    return {
      chartFlow: null,
      loaded: false,
      levelSettings: false,
      levelsSummary: [],
      levelsSummaryFiltered: [],
      triggerItems: [],
      blockingItems: [],
      intervalCheck: null,
      lockedIn: false,
      generateLocked: false,
      request: '',
      cookies: {
        'loseCondition': false,
        'winConditions': false,
        'blockingElements': false,
        'gameFlowBuilder': false,
      },
      busEvents: [
        'saveGroupName',
        'saveTrigger',
        'saveBlockingElement',
        'deleteTrigger',
        'deleteBlockingElement',
        'deleteGroup',
        'addLevels',
        'updateGroupData',
        'cancelStatusDialog',
        'doneSelecting',
      ],
    };
  },
  created() {
    // Bus events shortcode MGE
    this.busEvents.forEach((element) => {
      bus.$on('MGE-'+element, eval('this.'+element));
    });
    this.updateGroupData();
    this.getLibrary();
    this.updateGeneratesLeft();
    this.cookies.loseCondition = Cookies.get('loseCondition') == 'true' ? true : false;
    this.cookies.winConditions = Cookies.get('winConditions') == 'true' ? true : false;
    this.cookies.blockingElements = Cookies.get('blockingElements') == 'true' ? true : false;
    this.cookies.gameFlowBuilder = Cookies.get('gameFlowBuilder') == 'true' ? true : false;
    this.internals.currentGroupId = this.modal.group.groupId;
  },
  computed: {
    ...mapGetters([
      'internals',
      'constants',
      'serverData',
    ]),
    statusDialog() {
      return this.internals.statusDialog;
    },
    selectMode() {
      const intent = this.internals.userIntent;
      if (['copy-levels-select', 'move-levels-select'].some((el) => el == intent)) {
        return 'block';
      }
      if (['add-levels-where', 'copy-levels-where', 'move-levels-where'].some((el) => el == intent)) {
        return 'between';
      }
      return '';
    },
    maxStartingPoint() {
      return this.serverData.getAccountInfo.accountInfo.internals.maxLevels - this.levelsSummary.length;
    },
    gameFlowBuilder() {
      return this.levelSettings.gameFlowBuilder;
    },
    canGenerateCount() {
      let levelCount = 0;
      const me = this;
      this.levelsSummary.forEach((el) => {
        if (me.itemGenerateInfo(el).id == 0) {
          levelCount++;
        }
      });
      return levelCount;
    },
    generateText() {
      const s = this.canGenerateCount > 1 ? 's' : '';
      if (this.canGenerateCount == 0) {
        return 'no levels to generate';
      }
      return 'generate '+this.canGenerateCount+' level'+s;
    },
    filtering: {
      get() {
        return this.internals.levelFilters.groups;
      },
      set(val) {
        this.internals.levelFilters.groups = val;
        this.updateFilteredList();
      },
    },
    filters() {
      return this.constants.generateStates.filter((el) => el.id >= 0).map((el) => el.descr);
    },
    game() {
      return this.serverData.getGame;
    },
    library() {
      return this.serverData.getGame.gameLibrary;
    },
    groupsSummary() {
      return this.serverData.getGroupsSummary.groupsSummary;
    },
    totalCreatedLevels() {
      if (this.groupsSummary.length > 0) {
        let totalCount = 0;
        this.groupsSummary.forEach((item) => {
          totalCount += item.nrOfLevels;
        });
        return totalCount;
      }
      return 0;
    },
    maxLevels() {
      return Math.max(0, this.serverData.getAccountInfo.accountInfo.internals.maxLevels - this.totalCreatedLevels);
    },
    groupGenerateInfo() {
      // // check if out of generates
      // if (this.levelsSummary.length > this.serverData.getAccountInfo.accountInfo.externals.generatesLeft) {
      //   return this.constants.generateStates.find((el) => el.id == -1);
      // }
      // //  check if locked
      // if (this.generateLocked) {
      //   return this.constants.generateStates.find((el) => el.id == 1);
      // }
      // // check if new results
      // if (this.groupLevelStates.some((el) => el == 5)) {
      //   return this.constants.generateStates.find((el) => el.id == 5);
      // };
      // // check if generating
      // if (this.groupLevelStates.some((el) => el == 2)) {
      //   return this.constants.generateStates.find((el) => el.id == 2);
      // };
      // // check if pending
      // if (this.groupLevelStates.some((el) => el == 1)) {
      //   return this.constants.generateStates.find((el) => el.id == 1);
      // };
      return this.constants.generateStates.find((el) => el.id == 0);
    },
    horSize: {
      get() {
        return [this.levelSettings.minHorSize, this.levelSettings.maxHorSize];
      },
      set(newValue) {
        this.levelSettings.minHorSize = newValue[0];
        this.levelSettings.maxHorSize = newValue[1];
      },
    },
    vertSize: {
      get() {
        return [this.levelSettings.minVertSize, this.levelSettings.maxVertSize];
      },
      set(newValue) {
        this.levelSettings.minVertSize = newValue[0];
        this.levelSettings.maxVertSize = newValue[1];
      },
    },
    colors: {
      get() {
        return [this.levelSettings.minNrOfColors, this.levelSettings.maxNrOfColors];
      },
      set(newValue) {
        this.levelSettings.minNrOfColors = newValue[0];
        this.levelSettings.maxNrOfColors = newValue[1];
      },
    },
    loseTurns: {
      get() {
        const loseCondition = this.levelSettings.triggers.find((el) => el.consequence === 1);
        return [loseCondition.conditions[0].minSetting, loseCondition.conditions[0].maxSetting];
      },
      set(newValue) {
        const loseCondition = this.levelSettings.triggers.find((el) => el.consequence === 1);
        loseCondition.conditions[0].minSetting = newValue[0];
        loseCondition.conditions[0].maxSetting = newValue[1];
      },
    },
    winConditions: {
      get() {
        const winTrigger = this.levelSettings.triggers.find((el) => el.consequence == 0);
        const minWinCons = this.globalGetProp(winTrigger, 'minWinCons', 1);
        const maxWinCons = this.globalGetProp(winTrigger, 'maxWinCons', 3);
        return [minWinCons, maxWinCons];
      },
      set(newValue) {
        const winTrigger = this.levelSettings.triggers.find((el) => el.consequence == 0);
        winTrigger.minWinCons = newValue[0];
        winTrigger.maxWinCons = newValue[1];
        this.$forceUpdate();
      },
    },
    blockingElements: {
      get() {
        const minWinCons = this.levelSettings.minBlockingElements;
        const maxWinCons = this.levelSettings.maxBlockingElements;
        return [minWinCons, maxWinCons];
      },
      set(newValue) {
        this.levelSettings.minBlockingElements = newValue[0];
        this.levelSettings.maxBlockingElements = newValue[1];
        this.$forceUpdate();
      },
    },
    groupLevelStates() {
      const from = this.modal.group.lowestDisplayId-1;
      const to = this.modal.group.highestDisplayId;
      return this.serverData.getGenerateStatus.generateSummary.slice(from, to);
    },
    chartWidth() {
      return 100 + (this.levelSettings.gameFlowBuilder.gameFlowLevels.length*20);
    },
  },
  methods: {
    ...mapActions({
      postDataPromise: 'postDataPromise',
      getLibrary: 'getLibrary',
      getAccountInfo: 'getAccountInfo',
      spawnModal: 'spawnModal',
      closeModal: 'closeModal',
      getGroup: 'getGroup',
      getGenerateStatus: 'getGenerateStatus',
      showMessage: 'showMessage',
      cancelStatusDialog: 'cancelStatusDialog',
      showLoader: 'showLoader',
      hideLoader: 'hideLoader',
    }),
    toggleClosed(s) {
      this.cookies[s] = !this.cookies[s];
      Cookies.set(s, this.cookies[s]);
    },
    itemGenerateInfo(item) {
      const statusCode = this.serverData.getGenerateStatus.generateSummary[item.displayId-1];
      return this.constants.generateStates.find((el) => el.id == statusCode);
    },
    triggerIcon(item) {
      const obj = {};
      if ('elements' in item) {
        if (item.elements[0].hasOwnProperty('foreground')) {
          obj.type = 'foreground';
          obj.color = this.globalGetProp(item.elements[0], 'color', 0);
          const id = item.elements[0].foreground;
          const libItem = this.library.foregrounds.find((el) => el.id == id);
          obj.icon = libItem.icon;
          obj.hasColor = libItem.hasColor;
        }
        if (item.elements[0].hasOwnProperty('background')) {
          obj.type = 'background';
          const id = item.elements[0].background;
          const libItem = this.library.backgrounds.find((el) => el.id == id);
          obj.icon = libItem.icon;
        }
        return [obj];
      }
      return false;
    },
    blockerIcon(item) {
      const obj = {};
      if (item.element.hasOwnProperty('foreground')) {
        obj.type = 'foreground';
        obj.color = this.globalGetProp(item.element, 'color', 0);
        const id = item.element.foreground;
        const libItem = this.library.foregrounds.find((el) => el.id == id);
        obj.icon = libItem.icon;
        obj.hasColor = libItem.hasColor;
      }
      if (item.element.hasOwnProperty('background')) {
        obj.type = 'background';
        const id = item.element.background;
        const libItem = this.library.backgrounds.find((el) => el.id == id);
        obj.icon = libItem.icon;
      }
      return [obj];
    },
    blockerName(item) {
      if (item.element.hasOwnProperty('foreground')) {
        const id = item.element.foreground;
        const libItem = this.library.foregrounds.find((el) => el.id == id);
        return libItem.name;
      }
      if (item.element.hasOwnProperty('background')) {
        const id = item.element.background;
        const libItem = this.library.backgrounds.find((el) => el.id == id);
        return libItem.name;
      }
    },
    generate() {
      this.generateLocked = true;
      this.request = 'generate';
      const me = this;
      setTimeout(() => {
        me.generateLocked = false;
      }, 5000);
      this.updateGeneratesLeft();
    },
    forwardtest() {
      this.generateLocked = true;
      this.request = 'generate';
      const me = this;
      setTimeout(() => {
        me.generateLocked = false;
      }, 5000);
      this.updateGeneratesLeft('test');
    },
    updateGeneratesLeft(arg) {
      const me = this;
      this.getAccountInfo().then(function(result) {
        if (me.levelsSummary.length <= me.serverData.getAccountInfo.accountInfo.externals.generatesLeft) {
          if (me.request == 'generate') {
            me.request = '';
            const obj = {
              command: 'generateGroup',
              groupId: me.modal.group.groupId,
            };
            if (arg == 'test') {
              obj.forwardTest = true;
            }
            me.postDataPromise(obj);
          }
        }
      });
    },
    openResult(item) {
      this.spawnModal({
        component: 'ModalLevelResult',
        title: 'Result for Level ' + (this.game.gameSettings.levelNumberingStartsAt + item.displayId),
        closebutton: true,
        groupId: this.modal.group.groupId,
        levelId: item.levelId,
        displayId: item.displayId,
      });
    },
    levelResultChip(hasResult) {
      return hasResult ? 'Result available':'No result';
    },
    updateGroupData() {
      const me = this;
      this.getGroup({groupId: this.modal.group.groupId}).then(function() {
        const data = me.serverData.getGroup;
        me.loaded = true;
        me.parseData(data);
      });
    },
    parseData(data) {
      this.levelSettings = data.levelSettings;
      this.levelsSummary = data.levelsSummary;
      this.updateFilteredList();
      const winTriggers = data.levelSettings.triggers.find((el) => el.consequence === 0);
      this.triggerItems = winTriggers.conditions;
      this.blockingItems = data.levelSettings.blockingElements;
      this.lockedIn = this.levelSettings.lockedIn;
      const existing = this.internals.intervals.find((el) => el.command == 'getGenerateStatus');
      if (existing) {
        clearTimeout(this.intervalCheck);
        this.intervalCheck = setTimeout(this.updateGroupData, existing.interval);
      }
      this.$nextTick(() => this.loadChart());
    },
    updateFilteredList() {
      if (this.filtering.length > 0) {
        const me = this;
        this.levelsSummaryFiltered = this.levelsSummary.filter((item) => this.statusFilter(item, me.filtering));
      } else {
        this.levelsSummaryFiltered = this.levelsSummary;
      }
    },
    statusFilter(item, filters) {
      for (let i=0; i<filters.length; i++) {
        const filter = filters[i];
        const info = this.itemGenerateInfo(item);
        if (info.descr == filter) {
          return true;
        }
      }
      return false;
    },
    editGroupName() {
      this.spawnModal({
        component: 'ModalTextInput',
        title: 'Enter group name',
        label: 'Name',
        text: this.levelSettings.name,
        busevent: 'MGE-saveGroupName',
        subject: null,
      });
    },
    saveGroupName(data) {
      this.levelSettings.name = data.value;
      this.postDataPromise({
        command: 'renameGroup',
        groupId: this.modal.group.groupId,
        name: this.levelSettings.name,
      }).then(() => {
        me.showMessage({type: 'success', text: 'Group renamed.'});
        bus.$emit('LGS-getGroups');
      });
    },
    saveGroup() {
      const levelSettings = this.globalJSONCopy(this.levelSettings);
      // remove empty keys
      if (levelSettings.blockingElements.length == 0) {
        delete levelSettings.blockingElements;
      }
      this.postDataPromise({
        command: 'setGroup',
        groupId: this.modal.group.groupId,
        levelSettings: levelSettings,
        amount: 0,
      }).then((response) => {
        this.parseData(response.data);
      });
    },
    addWinCondition() {
      this.editTriggerModal({}, -1);
    },
    addBlockingElement() {
      this.editBlockingElementModal({}, -1);
    },
    editTriggerModal(trigger, index) {
      if (!this.lockedIn) {
        const modalParams = {
          component: 'ModalTriggerConditions',
          title: 'Edit win condition',
          index: index,
          conditiondata: trigger,
          saveEvent: 'MGE-saveTrigger',
          deleteEvent: 'MGE-deleteTrigger',
        };
        if (index == -1) {
          modalParams.title = 'New trigger';
          modalParams.deleteDisabled = true;
          modalParams.conditiondata = {
            alwaysUse: false,
            conditionType: 0,
            name: 'New trigger',
            elements: [],
          };
        }
        if (this.triggerItems.length == 1) {
          modalParams.deleteDisabled = true;
        }
        this.spawnModal(modalParams);
      }
    },
    editBlockingElementModal(blockingElement, index) {
      if (!this.lockedIn) {
        const modalParams = {
          component: 'ModalBlockingElements',
          title: 'Edit blocking element',
          index: index,
          elementData: blockingElement,
          saveEvent: 'MGE-saveBlockingElement',
          deleteEvent: 'MGE-deleteBlockingElement',
        };
        if (index == -1) {
          modalParams.title = 'New Blocking Element';
          modalParams.elementData = {
            alwaysUse: false,
            element: [],
          };
        }
        this.spawnModal(modalParams);
      }
    },
    saveTrigger(obj) {
      if (obj.index > -1) {
        this.triggerItems[obj.index] = obj.data;
      } else {
        this.triggerItems.unshift(obj.data);
      }
      this.saveGroup();
      this.$forceUpdate();
    },
    saveBlockingElement(obj) {
      if (obj.index > -1) {
        this.blockingItems[obj.index] = obj.data;
      } else {
        this.blockingItems.unshift(obj.data);
      }
      this.saveGroup();
      this.$forceUpdate();
    },
    deleteTrigger(obj) {
      this.triggerItems.splice(obj.index, 1);
      this.saveGroup();
      this.$forceUpdate();
    },
    deleteBlockingElement(obj) {
      this.blockingItems.splice(obj.index, 1);
      this.saveGroup();
      this.$forceUpdate();
    },
    askDeleteGroup() {
      this.spawnModal({
        component: 'ModalConfirm',
        title: 'Delete group',
        text: 'Deleting this group will also delete all it\'s levels. Proceed?',
        busevent: 'MGE-deleteGroup',
      });
    },
    deleteGroup() {
      const me = this;
      this.postDataPromise({
        command: 'removeGroup',
        groupId: this.modal.group.groupId,
      }).then(() => {
        bus.$emit('LGS-getGroups');
        me.showMessage({type: 'success', text: 'Group deleted.'});
        me.closeModal(this.modal.id);
      });
    },
    clickAddLevels() {
      this.cancelStatusDialog();
      this.internals.copyFromGroupId = this.modal.group.groupId;
      if (this.levelsSummaryFiltered.length == 0) {
        this.spawnModal({
          component: 'ModalNewLevels',
          busevent: 'MGE-addLevels',
          minlevels: 1,
          maxlevels: this.maxLevels,
          afterId: -1,
          prompt: 'How many levels do you wish to add to this group?',
        });
      } else {
        this.internals.userIntent = 'add-levels-where';
        this.internals.statusDialog.message = 'Select where to add.';
        this.internals.statusDialog.okText = '';
      }
    },
    clickAddLevelsFrom() {
      this.cancelStatusDialog();
      this.internals.userIntent = 'copy-levels-select';
      this.internals.statusDialog.message = 'Select what levels you wish to copy.';
      this.internals.statusDialog.ok = 'MGE-doneSelecting';
      this.internals.statusDialog.okText = 'Done';
      this.internals.statusDialog.okDisabled = true;
    },
    clickMoveLevels() {
      this.cancelStatusDialog();
      this.internals.userIntent = 'move-levels-select';
      this.internals.statusDialog.message = 'Select what levels you wish to move.';
      this.internals.statusDialog.ok = 'MGE-doneSelecting';
      this.internals.statusDialog.okText = 'Done';
      this.internals.statusDialog.okDisabled = true;
    },
    doneSelecting() {
      this.internals.copyFromGroupId = this.modal.group.groupId;
      if (this.internals.userIntent == 'copy-levels-select') {
        if (this.levelsSummaryFiltered.length == 0) {
          this.spawnModal({
            component: 'ModalNewLevels',
            busevent: 'MGE-addLevels',
            minlevels: 1,
            maxlevels: 10,
            afterId: -1,
            prompt: 'How many copies of the selected levels you wish to make?',
          });
        } else {
          this.internals.userIntent = 'copy-levels-where';
          this.internals.statusDialog.message = 'Select where to copy.';
          this.internals.statusDialog.okText = '';
        }
      }
      if (this.internals.userIntent == 'move-levels-select') {
        this.internals.userIntent = 'move-levels-where';
        this.internals.statusDialog.message = 'Select where to move to.';
        this.internals.statusDialog.okText = '';
      }
    },
    clickBetweenBlock(index) {
      const levelId = index == -1 ? -1 : this.levelsSummaryFiltered[index].levelId;
      const modalConfig = {
        component: 'ModalNewLevels',
        busevent: 'MGE-addLevels',
        minlevels: 1,
        maxlevels: this.maxLevels,
        afterId: levelId,
        prompt: 'How many levels do you wish to add to this group?',
      };
      if (this.internals.userIntent == 'add-levels-where') {
        this.spawnModal(modalConfig);
      }
      if (this.internals.userIntent == 'copy-levels-where') {
        modalConfig.maxlevels = 10;
        modalConfig.prompt = 'How many copies of the selected levels do you wish to make?',
        this.spawnModal(modalConfig);
      }
      if (this.internals.userIntent == 'move-levels-where') {
        const payload = {
          command: 'moveLevels',
          gameId: this.internals.currentGameId,
          groupId: this.internals.copyFromGroupId,
          toGroupId: this.modal.group.groupId,
          afterLevelId: levelId,
          levelIds: this.internals.selectedItems,
        };
        const me = this;
        this.postDataPromise(payload).then(function(data) {
          me.updateGroupData();
          me.serverData.getGroup.levelsSummary = data.levelsSummary;
          me.getGenerateStatus();
          me.cancelStatusDialog();
          me.showMessage({type: 'success', text: 'Levels moved.'});
        });
      }
    },
    addLevels(obj) {
      const me = this;
      const payload = {
        command: 'createLevels',
        gameId: this.internals.currentGameId,
        groupId: this.internals.copyFromGroupId,
        toGroupId: this.modal.group.groupId,
        afterLevelId: obj.afterId,
        amount: obj.amount,
      };
      if (this.internals.selectedItems.length > 0) {
        payload.copyFromLevelIds = this.internals.selectedItems;
      }
      this.showLoader();
      this.postDataPromise(payload).then((data) => {
        me.updateGroupData();
        me.serverData.getGroup.levelsSummary = data.levelsSummary;
        me.getGenerateStatus();
        me.hideLoader();
        me.showMessage({type: 'success', text: 'Levels created.'});
      },
      (data) => {
        me.hideLoader();
      });
    },
    isSelected(id) {
      return this.internals.selectedItems.some((el) => el == id);
    },
    clickLevel(item) {
      if (this.selectMode == 'block') {
        const found = this.internals.selectedItems.find((el) => el == item.levelId);
        if (found) {
          this.internals.selectedItems = this.internals.selectedItems.filter((el) => el != item.levelId);
        } else {
          this.internals.selectedItems.push(item.levelId);
        }
        this.internals.statusDialog.okDisabled = this.internals.selectedItems.length == 0;
      } else {
        if (item.hasResult) {
          this.openResult(item);
        } else {
          this.spawnModal({
            component: 'ModalLevelEditor',
            closebutton: true,
            title: 'Level editor',
            level: item,
            limitHeight: true,
          });
        }
      }
    },
    loadChart() {
      if (!this.chartFlow && this.levelsSummaryFiltered.length > 0) {
        const ctx = document.getElementById('groupChart').getContext('2d');
        const config = {
          type: 'line',
          data: {
            labels: [],
            datasets: [],
          },
          options: {
            legend: {
              display: false,
            },
            responsive: false,
            title: {
              display: false,
              text: '',
            },
            tooltips: {
              enabled: true,
            },
            hover: {
              mode: 'nearest',
              intersect: true,
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    display: true,
                    color: 'rgba(255,255,255,0.1)',
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Level',
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    display: true,
                    color: 'rgba(255,255,255,0.1)',
                    zeroLineColor: 'rgba(255,255,255,0.1)',
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Difficulty',
                  },
                  ticks: {
                    max: 100,
                    min: 0,
                  },
                },
              ],
            },
          },
        };
        this.chartFlow = new Chart(ctx, config);
      }
      this.updateChart();
    },
    updateChart() {
      if (this.levelsSummaryFiltered.length > 0) {
        this.chartFlow.data.labels = this.levelSettings.gameFlowBuilder.gameFlowLevels,
        this.chartFlow.data.datasets = [
          {
            label: 'Difficulty',
            borderColor: '#AE4592',
            data: this.levelSettings.gameFlowBuilder.gameFlow,
            fill: false,
          },
          {
            label: 'Calculated',
            borderColor: '#4CAF50',
            data: this.levelSettings.gameFlowBuilder.resultFlow,
            fill: false,
          },
          {
            label: 'Turn increase',
            borderColor: 'orange',
            data: this.levelSettings.gameFlowBuilder.turnIncreasePerLevel,
            fill: false,
          },
        ];
        this.chartFlow.update(false);
      }
    },
  },
  destroyed() {
    this.busEvents.forEach((element) => {
      bus.$off('MGE-'+element);
    });
    clearTimeout(this.intervalCheck);
    bus.$emit('LGS-getGroups');
    const intent = this.internals.userIntent;
    const found = ['copy-levels-where', 'move-levels-where'].some((el) => el == intent);
    if (!found) {
      this.cancelStatusDialog();
    }
  },
};
</script>

<style scoped>
  .BlockHolder {
    display: flex;
    flex: 0 0 300px;
    margin-top: 20px;
  }
  .LevelBlock {
    cursor: pointer;
    flex: 0 0 280px;
    padding: 15px 5px;
    box-shadow:none;
    border: solid 1px transparent;
  }
  .LevelBlock:hover {
    background: var(--secondary-color);
  }
  .LevelBlock.selected {
    background: #4CAF50;
  }
  .LevelBlock.disabled{
    opacity: .5;
    pointer-events: none;
  }
  .LevelBlock.selector{
    border: dotted 1px white;
  }
  .CardTitle {
    background: var(--primary-color);
  }
  .CardContent {
    background-color: #555555;
  }
  .TriggerItems {
    display: flex;
    background: #555555;
    flex-direction: column;
    flex:1 1 auto;
  }
  .SectionName{
    padding: 4px 10px;
    background: #c1b8b8;
    color: black;
    font-weight: bold;
    cursor: pointer;
  }

  .SectionName::before{
    content: '- ';
  }

  .SectionName.closed::before{
    content: '+ ';
  }

  .RoundedBox{
    display: inline-block;
    background-color: #424242;
    padding: 5px;
    margin: 3px;
    border-radius: 3px;
    text-align: center;
}

  .blink{
    animation:blink normal 1.5s infinite ease-in-out;
    transition:all 0.5s ease-in-out;
  }
  .v-input--slider{
    margin-top:0;
    padding:0 10px;
    height:40px;
  }

  .groupName:hover{
    text-decoration: underline;
    cursor:pointer;
  }
  @keyframes blink {
    0% {color: rgba(255,255,255,1)}
    50% {color: rgba(255,255,255,.5)}
    100% {color: rgba(255,255,255,1)}
  }
  .Spacer{
    margin:0 auto;
  }
  .Message{
    flex: 1 1 auto;
    background-color: darkred;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    border: solid 2px red;
    position: absolute;
    top: 63px;
    right: 67px;
  }
  hr{
    margin: 20px 0;
    opacity: .3;
  }
  .panelBox{
    padding:10px;
    background: var(--card-color);
  }
  .panelBox.closed{
    display: none;
  }
  .HasResult{
    background-color:green;
  }
  .LevelBlock.Locked{
    pointer-events: none;
  }
  .TriggerButton{
    display: flex;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    background-color: rgb(0,0,0,0.4);
    line-height: 2.2em;
    cursor: pointer;
  }
  .TriggerButton .Name{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width:224px;
  }
  .TriggerButton .Item{
    margin-right:10px;
  }
  .AddTrigger{
    background-color: rgb(0,0,0,0.4);
    padding: 10px !important;
  }
  .ct-grid {
    stroke: rgba(255, 255, 255, 0.2);
  }
  .ct-label {
    color: rgba(255, 255, 255, 0.4);
    fill: rgba(255, 255, 255, 0.4);
  }
  .LegendBlock{
    display: flex;
    margin-right: 20px;
  }
  .LegendColor{
    width: 30px;
    height: 20px;
    margin-right: 10px;
  }
  .betweenBlock {
    width: 20px;
    height: 219px;
    margin: 15px 0;
    border-radius: 3px;
    border: solid 1px transparent;
  }
  .betweenBlock.active {
    background-color: #666666;
    border: dotted 1px white;
    cursor: pointer;
  }
  .betweenBlock.active:hover{
    background-color:  var(--secondary-color);
  }
  .Fabs{
    position: fixed;
    right: 40px;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
